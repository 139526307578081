import React from 'react'
import { useTranslation } from 'cng-web-lib'
import { Box, Card, Divider, Grid, Typography } from '@material-ui/core'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngSubSection from '../../../components/cngcomponents/CngSubSection'
import CngField from '../../../components/cngcomponents/CngField'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import _ from 'lodash'
import moment from 'moment'

function LineItemViewContent(props) {
  const { data, getLookupValue } = props

  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const {
    hts9802Repair,
    usPacking,
    goodsreturned,
    laceyAct,
    fdaTab,
    fccTab,
    produce
  } = data

  const hasDeclaration =
    hts9802Repair ||
    usPacking ||
    goodsreturned ||
    !_.isEmpty(laceyAct) ||
    fdaTab ||
    fccTab ||
    produce

  function makeTranslatedTextsObject() {
    const lineItem = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.TITLE
    )
    const partNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.PART_NO
    )
    const partDesc = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.PART_DESC
    )
    const partType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.PART_TYPE
    )
    const htsCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.HTS_CODE
    )
    const qty = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.LineItem.QTY)
    const qtyUom = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.QTY_UOM
    )
    const unitValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.UNIT_VALUE
    )
    const uom1Factor1 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.UOM1_FACTOR1
    )
    const grossWeight = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.GROSS_WT
    )
    const country = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.COUNTRY
    )
    const partRefNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.PART_REF_NO
    )
    const spclProgInd = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.SPCL_PROG_IND
    )
    const purchaseOrderNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.PURCHASE_ORDER_NO
    )
    const bindRulingIdType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.BIND_RULING_ID_TYPE
    )
    const bindRulingId = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.BIND_RULING_ID
    )
    const uom2Factor1 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.UOM2_FACTOR1
    )
    const netWeight = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.NET_WT
    )
    const additionalDescription = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.ADDITIONAL_DESCRIPTION
    )
    const lineitemOgaCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineitemOgaCode.TITLE
    )
    const ogaCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineitemOgaCode.OGA_CODE
    )
    const ogaDocNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineitemOgaCode.OGA_DOC_NO
    )
    const lineitemAddiInfo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineitemAddiInfo.TITLE
    )
    const additionalInfoType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineitemAddiInfo.ADDITIONAL_INFO_TYPE
    )
    const additionalInfoNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineitemAddiInfo.ADDITIONAL_INFO_NO
    )
    const hts9802Repair = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.TITLE
    )
    const titleRepairPackingContainerInfo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.TITLE_REPAIR_PACKING_CONTAINER_INFO
    )
    const htsHeader9802Info = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.TITLE_HTS_HEADER9802_INFO
    )
    const invoiceNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.INVOICE_NO
    )
    const hts9802RepairHtsCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.HTS_CODE
    )
    const hts9802RepairUnitValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.UNIT_VALUE
    )
    const hts9802RepairQty = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.HTS9802_REPAIR_QTY
    )
    const hts9802RepairQtyUom = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.HTS9802_REPAIR_QTY_UOM
    )
    const uom1Factor2 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.UOM1_FACTOR2
    )
    const uom2Factor2 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.UOM2_FACTOR2
    )
    const specialHtsCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.SPECIAL_HTS_CODE
    )
    const categoryNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.CATEGORY_NO
    )
    const certOfRegNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.CERT_OF_REG_NO
    )
    const repairQty = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.REPAIR_QTY
    )
    const repairQtyUom = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.REPAIR_QTY_UOM
    )
    const reworkDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.REWORK_DATE
    )
    const noOfContainers1 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.NO_OF_CONTAINERS1
    )
    const containerType1 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.CONTAINER_TYPE1
    )
    const noOfContainers2 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.NO_OF_CONTAINERS2
    )
    const containerType2 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.CONTAINER_TYPE2
    )
    const usPacking = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.TITLE
    )
    const usPackingHtsCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.HTS_CODE
    )
    const packingValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.PACKING_VALUE
    )
    const usPackingQty = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.QTY
    )
    const usPackingQtyUom = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.QTY_UOM
    )
    const uom1Factor3 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.UOM1_FACTOR3
    )
    const uom2Factor3 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.UOM2_FACTOR3
    )
    const assemblyPrice = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.ASSEMBLY_PRICE
    )
    const goodsReturned = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Goodsreturned.TITLE
    )
    const goodsReturnedHtsCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Goodsreturned.HTS_CODE
    )
    const dutiableUnitValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Goodsreturned.DUTIABLE_UNIT_VALUE
    )
    const nonDutiableUnitValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Goodsreturned.NON_DUTIABLE_UNIT_VALUE
    )
    const packingUnitValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Goodsreturned.PACKING_UNIT_VALUE
    )
    const goodsreturnedUnitValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Goodsreturned.GOODSRETURNED_UNIT_VALUE
    )
    const goodsreturnedLineValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Goodsreturned.GOODSRETURNED_LINE_VALUE
    )
    const laceyAct = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.TITLE
    )
    const pga1 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.PGA_1
    )
    const pga2 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.PGA_2
    )
    const pga3 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.PGA_3
    )
    const pga4 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.PGA_4
    )
    const pga5 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.PGA_5
    )
    const pga6 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.PGA_6
    )
    const percentIngredient = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.PERCENT_INGREDIENT
    )
    const ingredientName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.INGREDIENT_NAME
    )
    const plantScientificName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.PLANT_SCIENTIFIC_NAME
    )
    const scientificSpeciesName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.SCIENTIFIC_SPECIES_NAME
    )
    const intendedUseDesc = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.INTENDED_USE_DESC
    )
    const intendedUseCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.INTENDED_USE_CODE
    )
    const sourceCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.SOURCE_CODE
    )
    const countryCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.COUNTRY_CODE
    )
    const qtyOfMaterial = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.QTY_OF_MATERIAL
    )
    const qtyOfMaterialUom = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.QTY_OF_MATERIAL_UOM
    )
    const fdaTab = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaTab.TITLE
    )
    const manufacturerId = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaTab.MANUFACTURER_ID
    )
    const storageStatus = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaTab.STORAGE_STATUS
    )
    const marker = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaTab.MARKER
    )
    const fdaTabCountry = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaTab.COUNTRY
    )
    const shipperId = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaTab.SHIPPER_ID
    )
    const productCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaTab.PRODUCT_CODE
    )
    const fdaAffirmation = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaAffirmation.TITLE
    )
    const fdaQtyUom = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaQtyUom.TITLE
    )
    const affirmationCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaAffirmation.AFFIRMATION_CODE
    )
    const affirmationQualifier = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaAffirmation.AFFIRMATION_QUALIFIER
    )
    const fdaQtyUomQuantity = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaQtyUom.QUANTITY
    )
    const fdaQtyUomUom = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaQtyUom.UOM
    )
    const fccTab = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FccTab.TITLE
    )
    const importConditionNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FccTab.IMPORT_CONDITION_NO
    )
    const fccIdentifier = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FccTab.FCC_IDENTIFIER
    )
    const importCondition = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FccTab.IMPORT_CONDITION
    )
    const fccRequirement = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FccTab.FCC_REQUIREMENT
    )
    const tradeName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FccTab.TRADE_NAME
    )
    const withholdInspection = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FccTab.WITHHOLD_INSPECTION
    )
    const produce = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Produce.TITLE
    )
    const scientificName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Produce.SCIENTIFIC_NAME
    )
    const produceDescription = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Produce.DESCRIPTION
    )
    const produceSize = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Produce.PRODUCE_SIZE
    )
    const produceProductCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Produce.PRODUCT_CODE
    )

    return {
      lineItem,
      partNo,
      partDesc,
      partType,
      htsCode,
      qty,
      qtyUom,
      unitValue,
      uom1Factor1,
      grossWeight,
      country,
      partRefNo,
      spclProgInd,
      purchaseOrderNo,
      bindRulingIdType,
      bindRulingId,
      uom2Factor1,
      netWeight,
      additionalDescription,
      lineitemOgaCode,
      ogaCode,
      ogaDocNo,
      lineitemAddiInfo,
      additionalInfoType,
      additionalInfoNo,
      hts9802Repair,
      titleRepairPackingContainerInfo,
      htsHeader9802Info,
      invoiceNo,
      hts9802RepairHtsCode,
      hts9802RepairUnitValue,
      hts9802RepairQty,
      hts9802RepairQtyUom,
      uom1Factor2,
      uom2Factor2,
      specialHtsCode,
      categoryNo,
      certOfRegNo,
      repairQty,
      repairQtyUom,
      reworkDate,
      noOfContainers1,
      containerType1,
      noOfContainers2,
      containerType2,
      usPacking,
      usPackingHtsCode,
      packingValue,
      usPackingQty,
      usPackingQtyUom,
      uom1Factor3,
      uom2Factor3,
      assemblyPrice,
      goodsReturned,
      goodsReturnedHtsCode,
      dutiableUnitValue,
      nonDutiableUnitValue,
      packingUnitValue,
      goodsreturnedUnitValue,
      goodsreturnedLineValue,
      laceyAct,
      pga1,
      pga2,
      pga3,
      pga4,
      pga5,
      pga6,
      percentIngredient,
      ingredientName,
      plantScientificName,
      scientificSpeciesName,
      intendedUseDesc,
      intendedUseCode,
      sourceCode,
      countryCode,
      qtyOfMaterial,
      qtyOfMaterialUom,
      fdaTab,
      manufacturerId,
      storageStatus,
      marker,
      fdaTabCountry,
      shipperId,
      productCode,
      fdaAffirmation,
      fdaQtyUom,
      affirmationCode,
      affirmationQualifier,
      fdaQtyUomQuantity,
      fdaQtyUomUom,
      fccTab,
      importConditionNo,
      fccIdentifier,
      importCondition,
      fccRequirement,
      tradeName,
      withholdInspection,
      produce,
      scientificName,
      produceDescription,
      produceSize,
      produceProductCode
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.lineItem}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.partNo}>
                    {data.partNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={8} lg={9}>
                  <CngField label={translatedTextsObject.partDesc}>
                    {data.partDesc}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.partType}>
                    {data.partType}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.htsCode}>
                    {data.htsCode}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.qtyUom}>
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('uom', data.qtyUom)}
                      <TinyChip label={data.qtyUom} variant='outlined' />
                    </Typography>
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.unitValue}>
                    {data.unitValue}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.uom1Factor1}>
                    {data.uom1Factor1}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.grossWeight}>
                    {data.grossWt}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.country}>
                    {getLookupValue('country', data.country)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.partRefNo}>
                    {data.partRefNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.spclProgInd}>
                    {getLookupValue('spclProgInd', data.spclProgInd)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.purchaseOrderNo}>
                    {data.purchaseOrderNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.bindRulingIdType}>
                    {getLookupValue('bindRulingIdType', data.bindRulingIdType)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.bindRulingId}>
                    {data.bindRulingId}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.uom2Factor1}>
                    {data.uom2Factor1}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.netWeight}>
                    {data.netWt}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CngField label={translatedTextsObject.additionalDescription}>
                    {data.additionalDescription}
                  </CngField>
                </Grid>
              </Grid>
            </Grid>
            {!_.isEmpty(data.lineitemOgaCode) && (
              <Grid item xs={12}>
                <CngSubSection title={translatedTextsObject.lineitemOgaCode}>
                  <Grid container spacing={1}>
                    {data.lineitemOgaCode.map((code, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.ogaCode}>
                                {getLookupValue('ogaCode', code.ogaCode)}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.ogaDocNo}>
                                {code.ogaDocNo}
                              </CngField>
                            </Grid>
                          </Grid>
                        </Grid>
                        {index + 1 < data.lineitemOgaCode.length && (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                </CngSubSection>
              </Grid>
            )}
            {!_.isEmpty(data.lineitemAddiInfo) && (
              <Grid item xs={12}>
                <CngSubSection title={translatedTextsObject.lineitemAddiInfo}>
                  <Grid container spacing={1}>
                    {data.lineitemAddiInfo.map((info, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <CngField
                                label={translatedTextsObject.additionalInfoType}
                              >
                                {getLookupValue(
                                  'additionalInfoType',
                                  info.additionalInfoType
                                )}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField
                                label={translatedTextsObject.additionalInfoNo}
                              >
                                {info.additionalInfoNo}
                              </CngField>
                            </Grid>
                          </Grid>
                        </Grid>
                        {index + 1 < data.lineitemAddiInfo.length && (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                </CngSubSection>
              </Grid>
            )}
          </Grid>
        </CngSection>
      </Grid>
      {hasDeclaration && (
        <Grid item xs={12}>
          <CngSection title='Declarations'>
            <Card variant='outlined'>
              <Grid container>
                {hts9802Repair && (
                  <Grid item xs={12}>
                    <Box bgcolor='grey.100' px={2} py={1}>
                      <Typography
                        color='textSecondary'
                        style={{ fontWeight: 600 }}
                      >
                        {translatedTextsObject.hts9802Repair}
                      </Typography>
                    </Box>
                    <Box p={2}>
                      <Box
                        display='flex'
                        alignItems='center'
                        mb={2}
                        style={{ gap: 16 }}
                      >
                        <Typography variant='body2' style={{ fontWeight: 600 }}>
                          {translatedTextsObject.htsHeader9802Info}
                        </Typography>
                        <Divider style={{ flexGrow: 1 }} />
                      </Box>
                      <Box mb={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField
                              label={translatedTextsObject.hts9802RepairHtsCode}
                            >
                              {hts9802Repair.htsCode}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField
                              label={translatedTextsObject.specialHtsCode}
                            >
                              {hts9802Repair.specialHtsCode}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField
                              label={translatedTextsObject.hts9802RepairUnitValue}
                            >
                              {hts9802Repair.unitValue}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField label={translatedTextsObject.uom1Factor2}>
                              {hts9802Repair.uom1Factor2}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField label={translatedTextsObject.uom2Factor2}>
                              {hts9802Repair.uom2Factor2}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField label={translatedTextsObject.categoryNo}>
                              {hts9802Repair.categoryNo}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField
                              label={translatedTextsObject.hts9802RepairQty}
                            >
                              {hts9802Repair.hts9802RepairQty}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField
                              label={translatedTextsObject.hts9802RepairQtyUom}
                            >
                              <Typography component='div' variant='inherit'>
                                {getLookupValue(
                                  'uom',
                                  hts9802Repair.hts9802RepairQtyUom
                                )}
                                <TinyChip
                                  label={hts9802Repair.hts9802RepairQtyUom}
                                  variant='outlined'
                                />
                              </Typography>
                            </CngField>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        display='flex'
                        alignItems='center'
                        mb={2}
                        style={{ gap: 16 }}
                      >
                        <Typography variant='body2' style={{ fontWeight: 600 }}>
                          {translatedTextsObject.titleRepairPackingContainerInfo}
                        </Typography>
                        <Divider style={{ flexGrow: 1 }} />
                      </Box>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField label={translatedTextsObject.invoiceNo}>
                              {hts9802Repair.invoiceNo}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField label={translatedTextsObject.certOfRegNo}>
                              {hts9802Repair.certOfRegNo}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField label={translatedTextsObject.reworkDate}>
                              {hts9802Repair.reworkDate &&
                                moment(hts9802Repair.reworkDate).format(
                                  'DD MMM YYYY'
                                )}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField label={translatedTextsObject.repairQty}>
                              {hts9802Repair.repairQty}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField label={translatedTextsObject.repairQtyUom}>
                              <Typography component='div' variant='inherit'>
                                {getLookupValue(
                                  'uom',
                                  hts9802Repair.repairQtyUom
                                )}
                                <TinyChip
                                  label={hts9802Repair.repairQtyUom}
                                  variant='outlined'
                                />
                              </Typography>
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField
                              label={translatedTextsObject.noOfContainers1}
                            >
                              {hts9802Repair.noOfContainers1}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField
                              label={translatedTextsObject.containerType1}
                            >
                              <Typography component='div' variant='inherit'>
                                {getLookupValue(
                                  'uom',
                                  hts9802Repair.containerType1
                                )}
                                <TinyChip
                                  label={hts9802Repair.containerType1}
                                  variant='outlined'
                                />
                              </Typography>
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField
                              label={translatedTextsObject.noOfContainers2}
                            >
                              {hts9802Repair.noOfContainers2}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CngField
                              label={translatedTextsObject.containerType2}
                            >
                              <Typography component='div' variant='inherit'>
                                {getLookupValue(
                                  'uom',
                                  hts9802Repair.containerType2
                                )}
                                <TinyChip
                                  label={hts9802Repair.containerType2}
                                  variant='outlined'
                                />
                              </Typography>
                            </CngField>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <Divider />
                  </Grid>
                )}
                {usPacking && (
                  <Grid item xs={12}>
                    <Box bgcolor='grey.100' px={2} py={1}>
                      <Typography
                        color='textSecondary'
                        style={{ fontWeight: 600 }}
                      >
                        {translatedTextsObject.usPacking}
                      </Typography>
                    </Box>
                    <Box p={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <CngField
                            label={translatedTextsObject.usPackingHtsCode}
                          >
                            {usPacking.htsCode}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <CngField label={translatedTextsObject.uom1Factor3}>
                            {usPacking.uom1Factor3}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <CngField label={translatedTextsObject.uom2Factor3}>
                            {usPacking.uom2Factor3}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <CngField label={translatedTextsObject.usPackingQty}>
                            {usPacking.qty}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <CngField label={translatedTextsObject.usPackingQtyUom}>
                            <Typography component='div' variant='inherit'>
                              {getLookupValue('uom', usPacking.qtyUom)}
                              <TinyChip
                                label={usPacking.qtyUom}
                                variant='outlined'
                              />
                            </Typography>
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <CngField label={translatedTextsObject.packingValue}>
                            {usPacking.packingValue}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <CngField label={translatedTextsObject.assemblyPrice}>
                            {usPacking.assemblyPrice}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider />
                  </Grid>
                )}
                {goodsreturned && (
                  <Grid item xs={12}>
                    <Box bgcolor='grey.100' px={2} py={1}>
                      <Typography
                        color='textSecondary'
                        style={{ fontWeight: 600 }}
                      >
                        {translatedTextsObject.goodsReturned}
                      </Typography>
                    </Box>
                    <Box p={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField
                            label={translatedTextsObject.goodsReturnedHtsCode}
                          >
                            {goodsreturned.htsCode}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField
                            label={translatedTextsObject.packingUnitValue}
                          >
                            {goodsreturned.packingUnitValue}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField
                            label={translatedTextsObject.dutiableUnitValue}
                          >
                            {goodsreturned.dutiableUnitValue}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField
                            label={translatedTextsObject.nonDutiableUnitValue}
                          >
                            {goodsreturned.nonDutiableUnitValue}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField
                            label={translatedTextsObject.goodsreturnedUnitValue}
                          >
                            {goodsreturned.goodsreturnedUnitValue}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <CngField
                            label={translatedTextsObject.goodsreturnedLineValue}
                          >
                            {goodsreturned.goodsreturnedLineValue}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider />
                  </Grid>
                )}
                {!_.isEmpty(laceyAct) && (
                  <Grid item xs={12}>
                    <Box bgcolor='grey.100' px={2} py={1}>
                      <Typography
                        color='textSecondary'
                        style={{ fontWeight: 600 }}
                      >
                        {translatedTextsObject.laceyAct}
                      </Typography>
                    </Box>
                    <Box p={2}>
                      <Grid container spacing={2}>
                        {laceyAct.map((act, index) => {
                          const pgaCodes = act.pgaCodes.split('|')

                          return (
                            <React.Fragment key={index}>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  {[...Array(6)].map((_, index) => (
                                    <Grid key={index} item xs={12} sm={4} lg={2}>
                                      <CngField
                                        label={
                                          translatedTextsObject[`pga${index + 1}`]
                                        }
                                      >
                                        {pgaCodes[index]}
                                      </CngField>
                                    </Grid>
                                  ))}
                                  <Grid item xs={12} sm={4} md={3}>
                                    <CngField
                                      label={
                                        translatedTextsObject.percentIngredient
                                      }
                                    >
                                      {act.percentIngredient}
                                    </CngField>
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={3}>
                                    <CngField
                                      label={translatedTextsObject.ingredientName}
                                    >
                                      {act.ingredientName}
                                    </CngField>
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={3}>
                                    <CngField
                                      label={
                                        translatedTextsObject.plantScientificName
                                      }
                                    >
                                      {act.plantScientificName}
                                    </CngField>
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={3}>
                                    <CngField
                                      label={
                                        translatedTextsObject.scientificSpeciesName
                                      }
                                    >
                                      {act.scientificSpeciesName}
                                    </CngField>
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={3}>
                                    <CngField
                                      label={
                                        translatedTextsObject.intendedUseDesc
                                      }
                                    >
                                      {act.intendedUseDesc}
                                    </CngField>
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={3}>
                                    <CngField
                                      label={
                                        translatedTextsObject.intendedUseCode
                                      }
                                    >
                                      {act.intendedUseCode}
                                    </CngField>
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={3}>
                                    <CngField
                                      label={translatedTextsObject.sourceCode}
                                    >
                                      {act.sourceCode}
                                    </CngField>
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={3}>
                                    <CngField
                                      label={translatedTextsObject.countryCode}
                                    >
                                      {getLookupValue('country', act.countryCode)}
                                    </CngField>
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={3}>
                                    <CngField
                                      label={translatedTextsObject.qtyOfMaterial}
                                    >
                                      {act.qtyOfMaterial}
                                    </CngField>
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={3}>
                                    <CngField
                                      label={
                                        translatedTextsObject.qtyOfMaterialUom
                                      }
                                    >
                                      {act.qtyOfMaterialUom}
                                    </CngField>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {index + 1 < laceyAct.length && (
                                <Grid item xs={12}>
                                  <Divider />
                                </Grid>
                              )}
                            </React.Fragment>
                          )
                        })}
                      </Grid>
                    </Box>
                    <Divider />
                  </Grid>
                )}
                {fdaTab && (
                  <Grid item xs={12}>
                    <Box bgcolor='grey.100' px={2} py={1}>
                      <Typography
                        color='textSecondary'
                        style={{ fontWeight: 600 }}
                      >
                        {translatedTextsObject.fdaTab}
                      </Typography>
                    </Box>
                    <Box p={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3}>
                              <CngField
                                label={translatedTextsObject.manufacturerId}
                              >
                                {fdaTab.manufacturerId}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <CngField
                                label={translatedTextsObject.storageStatus}
                              >
                                {fdaTab.storageStatus}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <CngField label={translatedTextsObject.marker}>
                                {fdaTab.marker}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <CngField
                                label={translatedTextsObject.fdaTabCountry}
                              >
                                {getLookupValue('country', fdaTab.country)}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.shipperId}>
                                {fdaTab.shipperId}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.productCode}>
                                {fdaTab.productCode}
                              </CngField>
                            </Grid>
                          </Grid>
                        </Grid>
                        {!_.isEmpty(fdaTab.fdaAffirmation) && (
                          <Grid item xs={12}>
                            <Card variant='outlined'>
                              <Box bgcolor='grey.100' px={2} py={1}>
                                <Typography
                                  color='textSecondary'
                                  style={{ fontWeight: 600 }}
                                >
                                  {translatedTextsObject.fdaAffirmation}
                                </Typography>
                              </Box>
                              <Box p={2}>
                                <Grid container spacing={2}>
                                  {fdaTab.fdaAffirmation.map(
                                    (affirmation, index) => (
                                      <React.Fragment key={index}>
                                        <Grid item xs={12}>
                                          <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                              <CngField
                                                label={
                                                  translatedTextsObject.affirmationCode
                                                }
                                              >
                                                {getLookupValue(
                                                  'affirmationCode',
                                                  affirmation.affirmationCode
                                                )}
                                              </CngField>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                              <CngField
                                                label={
                                                  translatedTextsObject.affirmationQualifier
                                                }
                                              >
                                                {affirmation.affirmationQualifier}
                                              </CngField>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        {index + 1 <
                                          fdaTab.fdaAffirmation.length && (
                                          <Grid item xs={12}>
                                            <Divider />
                                          </Grid>
                                        )}
                                      </React.Fragment>
                                    )
                                  )}
                                </Grid>
                              </Box>
                            </Card>
                          </Grid>
                        )}
                        {!_.isEmpty(fdaTab.fdaQtyUom) && (
                          <Grid item xs={12}>
                            <Card variant='outlined'>
                              <Box bgcolor='grey.100' px={2} py={1}>
                                <Typography
                                  color='textSecondary'
                                  style={{ fontWeight: 600 }}
                                >
                                  {translatedTextsObject.fdaQtyUom}
                                </Typography>
                              </Box>
                              <Box p={2}>
                                <Grid container spacing={2}>
                                  {fdaTab.fdaQtyUom.map((qtyUom, index) => (
                                    <React.Fragment key={index}>
                                      <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} sm={6}>
                                            <CngField
                                              label={
                                                translatedTextsObject.fdaQtyUomQuantity
                                              }
                                            >
                                              {qtyUom.quantity}
                                            </CngField>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <CngField
                                              label={
                                                translatedTextsObject.fdaQtyUomUom
                                              }
                                            >
                                              <Typography
                                                component='div'
                                                variant='inherit'
                                              >
                                                {getLookupValue(
                                                  'uom',
                                                  qtyUom.uom
                                                )}
                                                <TinyChip
                                                  label={qtyUom.uom}
                                                  variant='outlined'
                                                />
                                              </Typography>
                                            </CngField>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      {index + 1 < fdaTab.fdaQtyUom.length && (
                                        <Grid item xs={12}>
                                          <Divider />
                                        </Grid>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </Grid>
                              </Box>
                            </Card>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                    <Divider />
                  </Grid>
                )}
                {fccTab && (
                  <Grid item xs={12}>
                    <Box bgcolor='grey.100' px={2} py={1}>
                      <Typography
                        color='textSecondary'
                        style={{ fontWeight: 600 }}
                      >
                        {translatedTextsObject.fccTab}
                      </Typography>
                    </Box>
                    <Box p={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField
                            label={translatedTextsObject.importConditionNo}
                          >
                            {fccTab.importConditionNo}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.fccRequirement}>
                            {fccTab.fccRequirement}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.fccIdentifier}>
                            {fccTab.fccIdentifier}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.tradeName}>
                            {fccTab.tradeName}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CngField
                            label={translatedTextsObject.withholdInspection}
                          >
                            {fccTab.withholdInspection === 'Y' ? 'Yes' : 'No'}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CngField label={translatedTextsObject.importCondition}>
                            {fccTab.importCondition === 'Y' ? 'Yes' : 'No'}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider />
                  </Grid>
                )}
                {produce && (
                  <Grid item xs={12}>
                    <Box bgcolor='grey.100' px={2} py={1}>
                      <Typography
                        color='textSecondary'
                        style={{ fontWeight: 600 }}
                      >
                        {translatedTextsObject.produce}
                      </Typography>
                    </Box>
                    <Box p={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField label={translatedTextsObject.scientificName}>
                            {produce.scientificName}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField label={translatedTextsObject.produceSize}>
                            {produce.produceSize}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField
                            label={translatedTextsObject.produceProductCode}
                          >
                            {produce.productCode}
                          </CngField>
                        </Grid>
                        <Grid item xs={12}>
                          <CngField
                            label={translatedTextsObject.produceDescription}
                          >
                            {produce.description}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Card>
          </CngSection>
        </Grid>
      )}
    </Grid>
  )
}

export default LineItemViewContent
