import makeValidationSchema from './UsPackingMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'
import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: {
      CngTextField,
      CngUomAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  htsCode: "",
  packingValue: "",
  qty: "",
  qtyUom: "",
  uom1Factor3: "",
  uom2Factor3: "",
  assemblyPrice: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue, trigger } = useFormContext()

  function makeTranslatedTextsObject() {
    let usPacking = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.TITLE
    )
    let htsCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.HTS_CODE
    )
    let packingValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.PACKING_VALUE
    )
    let qty = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.QTY
    )
    let qtyUom = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.QTY_UOM
    )
    let uom1Factor3 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.UOM1_FACTOR3
    )
    let uom2Factor3 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.UOM2_FACTOR3
    )
    let assemblyPrice = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UsPacking.ASSEMBLY_PRICE
    )

    return {
      usPacking,
      htsCode,
      packingValue,
      qty,
      qtyUom,
      uom1Factor3,
      uom2Factor3,
      assemblyPrice
    }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={4} shouldHideMap={shouldHideMap?.htsCode}>
        <CngTextField
          name='htsCode'
          label={translatedTextsObject.htsCode}
          disabled={disabled}
          onChange={(event) => setValue('htsCode', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={4} shouldHideMap={shouldHideMap?.uom1Factor3}>
        <CngTextField
          name='uom1Factor3'
          label={translatedTextsObject.uom1Factor3}
          disabled={disabled}
          onChange={(event) => setValue('uom1Factor3', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={4} shouldHideMap={shouldHideMap?.uom2Factor3}>
        <CngTextField
          name='uom2Factor3'
          label={translatedTextsObject.uom2Factor3}
          disabled={disabled}
          onChange={(event) => setValue('uom2Factor3', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.qty}>
        <CngTextField
          name='qty'
          label={translatedTextsObject.qty}
          disabled={disabled}
          size='small'
          onChange={(event) => trigger('qty')}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.qtyUom}>
        <CngUomAutocompleteField
          name='qtyUom'
          label={translatedTextsObject.qtyUom}
          disabled={disabled}
          lookupProps={{
            label: (record) => {
              return `${record.code};${record.descriptionEn}`
            },
            filters: [
              {
                field: 'uomType',
                operator: 'equal',
                value: 'usb'
              }
            ]
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.packingValue}>
        <CngTextField
          name='packingValue'
          label={translatedTextsObject.packingValue}
          disabled={disabled}
          size='small'
          onChange={(event) => trigger('packingValue')}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.assemblyPrice}>
        <CngTextField
          name='assemblyPrice'
          label={translatedTextsObject.assemblyPrice}
          disabled={disabled}
          size='small'
          onChange={(event) => trigger('assemblyPrice')}
        />
      </CngGridItem>
    </Grid>
	)
}

const UsPackingFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default UsPackingFormProperties
