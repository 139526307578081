import makeValidationSchema from './CargoSummaryMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SbciInvoiceKeys from 'src/constants/locale/key/SbciInvoice'
import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  reference: "",
  referenceNumber: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  index,
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue, getValues } = useFormContext()

  function makeTranslatedTextsObject() {
    let reference = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CargoSummary.REFERENCE
    )
    let referenceNumber = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CargoSummary.REFERENCE_NUMBER
    )

    return {
      reference,
      referenceNumber
    }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.reference}>
        <CngCodeMasterAutocompleteField
          name={typeof index === 'number' ? `ciForm.cargoSummary.${index}.reference` : 'reference'}
          label={translatedTextsObject.reference}
          disabled={disabled}
          codeType='SBCI_REFERENCE_TYPE'
          size='small'
          key={getValues(`ciForm.cargoSummary.${index}.reference`)}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.referenceNumber}>
        <CngTextField
          name={typeof index === 'number' ? `ciForm.cargoSummary.${index}.referenceNumber` : 'referenceNumber'}
          label={translatedTextsObject.referenceNumber}
          disabled={disabled}
          size='small'
          onChange={(event) => setValue(typeof index === 'number' ? `ciForm.cargoSummary.${index}.referenceNumber` : 'referenceNumber', event.target.value.toUpperCase(), {shouldValidate:true})}
        />
      </CngGridItem>
    </Grid>
	)
}

const CargoSummaryFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default CargoSummaryFormProperties
