import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  const errMsgMaxLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  const errMsgAlpha = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_ALPHA
  )
  const errMsgAlphaNumeric = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC
  )
  const errMsgDecimal1 = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_DECIMAL1
  )
  const errMsgDecimal2 = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_DECIMAL2
  )
  const errMsgDecimal3 = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_DECIMAL3
  )
  const errMsgNumeric = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_NUMERIC
  )

  const regexAlpha = '^[a-zA-Z]*$'
  const regexNumeric = '^[0-9]*$'
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]*$'

  return Yup.object({
    pga1: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(2, errMsgMaxLength + ' 2'),
    pga2: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(2, errMsgMaxLength + ' 2'),
    pga3: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(2, errMsgMaxLength + ' 2'),
    pga4: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(2, errMsgMaxLength + ' 2'),
    pga5: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(2, errMsgMaxLength + ' 2'),
    pga6: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(2, errMsgMaxLength + ' 2'),
    intendedUseCode: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(3, errMsgMaxLength + ' 3'),
    intendedUseDesc: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(20, errMsgMaxLength + ' 20'),
    ingredientName: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(52, errMsgMaxLength + ' 52'),
    qtyOfMaterial: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().required(requiredMessage)
        : Yup.string()
            .required(requiredMessage)
            .matches(
              /^\d{0,10}(\.\d{0,2})?$/,
              errMsgDecimal1 + ' 10 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
            )
            .max(13, errMsgMaxLength + ' 13')
    ),
    qtyOfMaterialUom: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(3, errMsgMaxLength + ' 3'),
    percentIngredient: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().required(requiredMessage)
        : Yup.string()
            .required(requiredMessage)
            .matches(regexNumeric, errMsgNumeric)
            .max(3, errMsgMaxLength + ' 3')
    ),
    plantScientificName: Yup.string()
      .required(requiredMessage)
      .matches(regexAlpha, errMsgAlpha)
      .max(22, errMsgMaxLength + ' 22'),
    scientificSpeciesName: Yup.string()
      .required(requiredMessage)
      .matches(regexAlpha, errMsgAlpha)
      .max(22, errMsgMaxLength + ' 22'),
    sourceCode: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(3, errMsgMaxLength + ' 3'),
    countryCode: Yup.string().required(requiredMessage)
  })
}

export default makeValidationSchema
