import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import { FormProvider, useForm } from 'react-hook-form'
import FormProperties from './IdentificationInfoFormProperties'
import useCustomYupValidationResolver from '../../../components/aciacehighway/hooks/useCustomYupValidationResolver'
import Grid from '@material-ui/core/Grid'

const {
  button: { CngButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function IdentificationInfoFieldsPlaceholder(props) {
  const { onAdd } = props

  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const methods = useForm({
    defaultValues: initialValues,
    resolver: useCustomYupValidationResolver(makeValidationSchema(translate))
  })

  const {
    formState: { isDirty }
  } = methods

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12} md>
          <FormProperties.Fields />
        </Grid>
        {isDirty && (
          <Grid item xs={12} md='auto'>
            <CngButton onClick={methods.handleSubmit(onAdd)} size='small'>
              Add
            </CngButton>
          </Grid>
        )}
      </Grid>
    </FormProvider>
  )
}

export default IdentificationInfoFieldsPlaceholder
