import React, { useState } from 'react'
import { components, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import SbciInvoiceApiUrls from '../../../apiUrls/SbciInvoiceApiUrls'
import CngSection from '../../../components/cngcomponents/CngSection'
import BolFormSection from './BolFormSection'
import TscaFormSection from './TscaFormSection'
import PackingListFormSection from './PackingListFormSection'
import NaftaFormSection from './NaftaFormSection'
import LaceyActFormSection from './LaceyActFormSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, DialogContent, Divider, Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { format } from 'date-fns'
import QuickExportButton from './QuickExportButton'

const {
  button: { CngButton },
  CngDialog
} = components

function AdditionalFormInfoSection(props) {
  const { showNotification } = props

  const [dialog, setDialog] = useState(false)
  const [download, setDownload] = useState({
    form: null,
    loading: false
  })
  const { securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { getValues, setValue, watch } = useFormContext()

  const bolForm = watch('bolForm')
  const tscaForm = watch('tscaForm')
  const packingListForm = watch('packingListForm')
  const naftaForm = watch('naftaForm')
  const laceyActForm = watch('laceyActForm')

  function makeTranslatedTextsObject() {
    let bolForm = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.BolForm.TITLE
    )
    let tscaForm = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TscaForm.TITLE
    )
    let naftaForm = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NaftaForm.TITLE
    )
    let packingListForm = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PackingListForm.TITLE
    )
    let laceyActForm = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActForm.TITLE
    )

    return {
      bolForm,
      tscaForm,
      naftaForm,
      packingListForm,
      laceyActForm
    }
  }

  function handleSave(propertyName, data) {
    setValue(propertyName, data, { shouldValidate: true })
  }

  async function handleDownload({ formKey, url, data, prefix }) {
    try {
      setDownload({ form: formKey, loading: true })
      const config = { responseType: 'blob' } 
      
      await securedSendRequest.execute(
        'POST',
        url,
        data,
        (response) => {
          const blob = new Blob([response.data])
          const curDate = format(new Date(), 'yyyyMMddHHmmss')
          const a = document.createElement('a')

          a.href = window.URL.createObjectURL(blob)
          a.download = `${prefix}_${curDate}.pdf`
          a.click()
        },
        (error) => {
          console.error(error)
          showNotification('error', 'Download failed')
        },
        undefined,
        config,
        null
      )
    } finally {
      setDownload({ form: null, loading: false })
    }
  }

  function isEmpty(value){

    if(value==null || value==undefined || value == ""){
      return true;
    }
    return false;
  }

  return (
    <>
      <CngSection
        action={
          <CngButton
            color='secondary'
            onClick={() => setDialog(true)}
            size='medium'
            startIcon={<FontAwesomeIcon icon={['fal', 'pen']} size='xs' />}
          >
            Manage
          </CngButton>
        }
        title={`${getValues("id")!=null && getValues("id")!=undefined && getValues("id")!="" ? 'Additional Form Information' :'Additional Form Information(Print not available on Invoice Creation)' }`}
      >
      {getValues("id")!=null && getValues("id")!=undefined && getValues("id")!="" &&
      <>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <QuickExportButton
              disabled={download.loading || isEmpty(getValues("bolForm.id")) || 
                (
                  isEmpty(getValues("bolForm.containerNo")) &&
                  isEmpty(getValues("bolForm.freightCharges")) &&
                  isEmpty(getValues("bolForm.placeOfLading")) &&
                  isEmpty(getValues("bolForm.deliveryInstructions")) &&
                  isEmpty(getValues("bolForm.codAmount")) &&
                  isEmpty(getValues("bolForm.currency")) &&
                  isEmpty(getValues("bolForm.shipmentDeclareValue")) &&
                  isEmpty(getValues("bolForm.releaseValue")) &&
                  isEmpty(getValues("bolForm.chargeTo")) &&
                  isEmpty(getValues("bolForm.totShpmntCubicFt")) &&
                  isEmpty(getValues("bolForm.freightClass"))
                )
              
              }
              loading={download.loading && download.form === 'bol'}
              label={translatedTextsObject.bolForm}
              onClick={() =>
                handleDownload({
                  formKey: 'bol',
                  url: SbciInvoiceApiUrls.PRINT_BOL,
                  data: getValues(),
                  prefix: 'SBCI_BOL'
                })
              }
            />
          </Grid>
          <Grid item xs='auto'>
            <QuickExportButton
              disabled={download.loading || isEmpty(getValues("tscaForm.id")) ||
                (
                  isEmpty(getValues("tscaForm.tscaDate")) &&
                  isEmpty(getValues("tscaForm.tscaTitle")) &&
                  isEmpty(getValues("tscaForm.phone")) &&
                  isEmpty(getValues("tscaForm.companyName")) &&
                  isEmpty(getValues("tscaForm.address1")) &&
                  isEmpty(getValues("tscaForm.address2")) &&
                  isEmpty(getValues("tscaForm.citystatezip")) &&
                  isEmpty(getValues("tscaForm.tscaCertChoice")) && 
                  isEmpty(getValues("tscaForm.researchCert")) && 
                  (getValues("tscaForm.tscaDetails")==null || (getValues("tscaForm.tscaDetails")!=null && getValues("tscaForm.tscaDetails").length==0))
                )
              }
              loading={download.loading && download.form === 'tsca'}
              label={translatedTextsObject.tscaForm}
              onClick={() =>
                handleDownload({
                  formKey: 'tsca',
                  url: SbciInvoiceApiUrls.PRINT_TSCA,
                  data: getValues(),
                  prefix: 'SBCI_TSCA'
                })
              }
            />
          </Grid>
          <Grid item xs='auto'>
            <QuickExportButton
              disabled={download.loading || isEmpty(getValues("naftaForm.id")) ||
                (
                  isEmpty(getValues("naftaForm.dateFrom")) &&
                  isEmpty(getValues("naftaForm.dateTo")) &&
                  isEmpty(getValues("naftaForm.company")) &&
                  isEmpty(getValues("naftaForm.naftaTitle")) &&
                  isEmpty(getValues("naftaForm.naftaDate")) &&
                  isEmpty(getValues("naftaForm.partyName")) &&
                  isEmpty(getValues("naftaForm.teleNo")) &&
                  isEmpty(getValues("naftaForm.contactFax")) &&
                  isEmpty(getValues("naftaForm.email")) &&
                  isEmpty(getValues("naftaForm.certifierType"))
                )
              }
              loading={download.loading && download.form === 'naftaForm'}
              label={translatedTextsObject.naftaForm}
              onClick={() =>
                handleDownload({
                  formKey: 'nafta',
                  url: SbciInvoiceApiUrls.PRINT_USMCA_CUSMA,
                  data: getValues(),
                  prefix: 'SBCI_USMCA'
                })
              }
            />
          </Grid>
          <Grid item xs='auto'>
            <QuickExportButton
              disabled={download.loading || isEmpty(getValues("packingListForm.id")) ||
                (
                  isEmpty(getValues("packingListForm.otherRefNo")) &&
                  isEmpty(getValues("packingListForm.responsibleForCustoms")) &&
                  isEmpty(getValues("packingListForm.instructionsToBroker")) &&
                  isEmpty(getValues("packingListForm.containerized")) &&
                  isEmpty(getValues("packingListForm.flightDetails")) &&
                  isEmpty(getValues("packingListForm.specialInstructions")) &&
                  isEmpty(getValues("packingListForm.placeOfDelivery")) &&
                  isEmpty(getValues("packingListForm.portOfLoading")) &&
                  isEmpty(getValues("packingListForm.billChargesTo")) &&
                  isEmpty(getValues("packingListForm.dateShipped"))
                )
              }
              loading={download.loading && download.form === 'packingList'}
              label={translatedTextsObject.packingListForm}
              onClick={() =>
                handleDownload({
                  formKey: 'packingList',
                  url: SbciInvoiceApiUrls.PRINT_PL,
                  data: getValues(),
                  prefix: 'SBCI_PL_'
                })
              }
            />
          </Grid>
          <Grid item xs='auto'>
            <QuickExportButton
              disabled={download.loading || isEmpty(getValues("laceyActForm.id")) ||
               (
                isEmpty(getValues("laceyActForm.containerNo")) &&
                isEmpty(getValues("laceyActForm.printName")) &&
                isEmpty(getValues("laceyActForm.descOfMerchandise")) &&
                isEmpty(getValues("laceyActForm.laceyDate")) &&
                isEmpty(getValues("laceyActForm.estimatedArrivalDate")) &&
                (getValues("laceyActForm.laceyActDetails")==null || (getValues("laceyActForm.laceyActDetails")!=null && getValues("laceyActForm.laceyActDetails").length==0))
               )
              }
              loading={download.loading && download.form === 'laceyAct'}
              label={translatedTextsObject.laceyActForm}
              onClick={() =>
                handleDownload({
                  formKey: 'laceyAct',
                  url: SbciInvoiceApiUrls.PRINT_LACEYACT,
                  data: getValues(),
                  prefix: 'SBCI_LACEY_ACT'
                })
              }
            />
          </Grid>
        </Grid>
        </>}
      </CngSection>
      <CngDialog
        customDialogContent={
          <DialogContent style={{ padding: 8 }}>
            <Card variant='outlined'>
              <BolFormSection
                bolForm={bolForm}
                onSave={(data) => handleSave('bolForm', data)}
                title={translatedTextsObject.bolForm}
              />
              <Divider />
              <TscaFormSection
                onSave={(data) => handleSave('tscaForm', data)}
                title={translatedTextsObject.tscaForm}
                tscaForm={tscaForm}
              />
              <Divider />
              <NaftaFormSection
                onSave={(data) => handleSave('naftaForm', data)}
                naftaForm={naftaForm}
                title={translatedTextsObject.naftaForm}
              />
              <Divider />
              <PackingListFormSection
                onSave={(data) => handleSave('packingListForm', data)}
                packingListForm={packingListForm}
                title={translatedTextsObject.packingListForm}
              />
              <Divider />
              <LaceyActFormSection
                laceyActForm={laceyActForm}
                onSave={(data) => handleSave('laceyActForm', data)}
                title={translatedTextsObject.laceyActForm}
              />
            </Card>
          </DialogContent>
        }
        dialogTitle='Manage Additional Form Information'
        fullWidth
        maxWidth='lg'
        onClose={() => setDialog(false)}
        open={dialog}
        shouldShowCloseButton
      />
    </>
  )
}

export default AdditionalFormInfoSection
