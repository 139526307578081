import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './TscaDetailsFormProperties'
import TscaDetailFieldsPlaceholder from './TscaDetailFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'
import TscaDetailsFormProperties from './TscaDetailsFormProperties'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function TscaDetailsSection({showNotification}) {

  const { getValues, setValue, watch } = useFormContext()

  const tscaDetails = watch('tscaDetails')

  function handleAddTscaDetail(data) {
    const tscaDetails = [...getValues('tscaDetails'), data].map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('tscaDetails', tscaDetails, {shouldDirty:true})
  }

  function handleDeleteTscaDetail(data) {
    const clonedTscaDetails = [...getValues('tscaDetails')].filter((material) =>
      material.id ? material.id !== data.id : material._id !== data._id
    )
    const tscaDetails = clonedTscaDetails.map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('tscaDetails', tscaDetails, {shouldDirty:true})
  }

  return (
    <Grid container spacing={1}>
      {tscaDetails.map((goods, index) => {
        const isLast = tscaDetails.length === 1
        return (
          <React.Fragment key={goods._id || goods.id || index}>
            <Grid item xs={12}>
              <TscaDetailEntry
                index={index}
                isLast={isLast}
                onAddTscaDetail={handleAddTscaDetail}
                onDeleteTscaDetail={handleDeleteTscaDetail}
                tscaDetails={goods}
                showNotification={showNotification}
              />
            </Grid>
            {tscaDetails.length !== index + 1 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </React.Fragment>
        )
      })}
    </Grid>
  )
}

function TscaDetailEntry(props) {
  const { index, isLast, onAddTscaDetail, onDeleteTscaDetail, tscaDetails, showNotification } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md>
        <TscaDetailsFormProperties.Fields index={index} showNotification={showNotification} />
      </Grid>
      <Grid item xs={12} md='auto'>
        <Grid container spacing={1}>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Add new'>
              <span>
                <CngIconButton
                  icon={['fal', 'plus']}
                  onClick={() => onAddTscaDetail(initialValues)}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Clone'>
              <span>
                <CngIconButton
                  icon={['fal', 'copy']}
                  onClick={() => onAddTscaDetail({ ...tscaDetails, id: undefined })}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          {!isLast && (
            <Grid item xs='auto'>
              <Tooltip placement='bottom' title='Delete'>
                <span>
                  <CngIconButton
                    icon={['fal', 'trash']}
                    onClick={() => onDeleteTscaDetail(tscaDetails)}
                    size='small'
                    type='outlined'
                  />
                </span>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TscaDetailsSection
