import makeValidationSchema from './Hts9802RepairMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: {
      CngCodeMasterAutocompleteField,
      CngDateField,
      CngTextField,
      CngUomAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  invoiceNo: "",
  htsCode: "",
  unitValue: "",
  hts9802RepairQty: "",
  hts9802RepairQtyUom: "",
  uom1Factor2: "",
  uom2Factor2: "",
  specialHtsCode: "",
  categoryNo: "",
  certOfRegNo: "",
  repairQty: "",
  repairQtyUom: "",
  reworkDate: "",
  noOfContainers1: "",
  containerType1: "",
  noOfContainers2: "",
  containerType2: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { getValues, setValue,trigger } = useFormContext()

  function makeTranslatedTextsObject() {
    let invoiceNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.INVOICE_NO
    )
    let htsCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.HTS_CODE
    )
    let unitValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.UNIT_VALUE
    )
    let hts9802RepairQty = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.HTS9802_REPAIR_QTY
    )
    let hts9802RepairQtyUom = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.HTS9802_REPAIR_QTY_UOM
    )
    let uom1Factor2 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.UOM1_FACTOR2
    )
    let uom2Factor2 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.UOM2_FACTOR2
    )
    let specialHtsCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.SPECIAL_HTS_CODE
    )
    let categoryNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.CATEGORY_NO
    )
    let certOfRegNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.CERT_OF_REG_NO
    )
    let repairQty = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.REPAIR_QTY
    )
    let repairQtyUom = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.REPAIR_QTY_UOM
    )
    let reworkDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.REWORK_DATE
    )
    let noOfContainers1 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.NO_OF_CONTAINERS1
    )
    let containerType1 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.CONTAINER_TYPE1
    )
    let noOfContainers2 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.NO_OF_CONTAINERS2
    )
    let containerType2 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.CONTAINER_TYPE2
    )
    let titleRepairPackingContainerInfo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.TITLE_REPAIR_PACKING_CONTAINER_INFO
    )
    let htsHeader9802Info = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Hts9802Repair.TITLE_HTS_HEADER9802_INFO
    )
    
    return {
      htsHeader9802Info,
      htsCode,
      specialHtsCode,
      unitValue,
      uom1Factor2,
      uom2Factor2,
      categoryNo,
      hts9802RepairQty,
      hts9802RepairQtyUom,
      titleRepairPackingContainerInfo,
      invoiceNo,
      certOfRegNo,
      reworkDate,
      repairQty,
      repairQtyUom,
      noOfContainers1,
      containerType1,
      noOfContainers2,
      containerType2,
    }
  }

	return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display='flex' alignItems='center' mb={2} style={{ gap: 16 }}>
          <Typography variant='body2' style={{ fontWeight: 600 }}>
            {translatedTextsObject.htsHeader9802Info}
          </Typography>
          <Divider style={{ flexGrow: 1 }} />
        </Box>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.htsCode}>
            <CngTextField
              name='htsCode'
              label={translatedTextsObject.htsCode}
              disabled={disabled}
              onChange={(event) => setValue('htsCode', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.specialHtsCode}>
            <CngTextField
              name='specialHtsCode'
              label={translatedTextsObject.specialHtsCode}
              disabled={disabled}
              onChange={(event) => setValue('specialHtsCode', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.unitValue}>
            <CngTextField
              name='unitValue'
              label={translatedTextsObject.unitValue}
              disabled={disabled}
              onChange={(event) => setValue('unitValue', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.uom1Factor2}>
            <CngTextField
              name='uom1Factor2'
              label={translatedTextsObject.uom1Factor2}
              disabled={disabled}
              onChange={(event) => setValue('uom1Factor2', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.uom2Factor2}>
            <CngTextField
              name='uom2Factor2'
              label={translatedTextsObject.uom2Factor2}
              disabled={disabled}
              onChange={(event) => setValue('uom2Factor2', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.categoryNo}>
            <CngTextField
              name='categoryNo'
              label={translatedTextsObject.categoryNo}
              disabled={disabled}
              onChange={(event) => setValue('categoryNo', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.hts9802RepairQty}>
            <CngTextField
              name='hts9802RepairQty'
              label={translatedTextsObject.hts9802RepairQty}
              disabled={disabled}
              size='small'
              onChange={(event) => trigger('hts9802RepairQty')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.hts9802RepairQtyUom}>
            <CngUomAutocompleteField
              name='hts9802RepairQtyUom'
              label={translatedTextsObject.hts9802RepairQtyUom}
              disabled={disabled}
              lookupProps={{
                label: (record) => {
                  return `${record.code};${record.descriptionEn}`
                },
                filters: [
                  {
                    field: 'uomType',
                    operator: 'equal',
                    value: 'usb'
                  }
                ]
              }}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box display='flex' alignItems='center' mb={2} style={{ gap: 16 }}>
          <Typography variant='body2' style={{ fontWeight: 600 }}>
            {translatedTextsObject.titleRepairPackingContainerInfo}
          </Typography>
          <Divider style={{ flexGrow: 1 }} />
        </Box>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} shouldHideMap={shouldHideMap?.invoiceNo}>
            <CngTextField
              name='invoiceNo'
              label={translatedTextsObject.invoiceNo}
              disabled={disabled}
              onChange={(event) => setValue('invoiceNo', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHideMap={shouldHideMap?.certOfRegNo}>
            <CngTextField
              name='certOfRegNo'
              label={translatedTextsObject.certOfRegNo}
              disabled={disabled}
              onChange={(event) => setValue('certOfRegNo', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHideMap={shouldHideMap?.reworkDate}>
            <CngDateField
              name='reworkDate'
              label={translatedTextsObject.reworkDate}
              disabled={disabled}
              size='small'
              onBlur={(event) => trigger('reworkDate')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHideMap={shouldHideMap?.repairQty}>
            <CngTextField
              name='repairQty'
              label={translatedTextsObject.repairQty}
              disabled={disabled}
              onChange={(event) => setValue('repairQty', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHideMap={shouldHideMap?.repairQtyUom}>
            <CngUomAutocompleteField
              name='repairQtyUom'
              label={translatedTextsObject.repairQtyUom}
              disabled={disabled}
              lookupProps={{
                label: (record) => {
                  return `${record.code};${record.descriptionEn}`
                },
                filters: [
                  {
                    field: 'uomType',
                    operator: 'equal',
                    value: 'usb'
                  }
                ]
              }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.noOfContainers1}>
            <CngTextField
              name='noOfContainers1'
              label={translatedTextsObject.noOfContainers1}
              disabled={disabled}
              size='small'
              onChange={(event) => trigger('noOfContainers1')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.containerType1}>
            <CngCodeMasterAutocompleteField
              name='containerType1'
              label={translatedTextsObject.containerType1}
              disabled={disabled}
              codeType='SBCI_CONTAINER_TYPE'
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.noOfContainers2}>
            <CngTextField
              name='noOfContainers2'
              label={translatedTextsObject.noOfContainers2}
              disabled={disabled}
              size='small'
              onChange={(event) => trigger('noOfContainers2')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.containerType2}>
            <CngCodeMasterAutocompleteField
              name='containerType2'
              label={translatedTextsObject.containerType2}
              disabled={disabled}
              codeType='SBCI_CONTAINER_TYPE'
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
    </Grid>
	)
}

const Hts9802RepairFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default Hts9802RepairFormProperties
