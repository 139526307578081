import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import NaCodeMaintenanceApiUrls from '../../../apiUrls/NaCodeMaintenanceApiUrls'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL },
  CodeMaintenanceType
} = constants

const InvoiceLookupsContext = React.createContext()

const InvoiceLookupsConsumer = InvoiceLookupsContext.Consumer

const InvoiceLookupsProvider = ({ children }) => {
  const [lookups, setLookups] = useState(null)
  const { fetchRecords } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'SBCI_INVOICE_TYPE' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: EQUAL,
          value: 'SBCI_ENTRY_TYPE'
        }
      ]),
      fetchRecords.execute(
        NaCodeMaintenanceApiUrls.GET,
        {
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'set_indicator', operator: EQUAL, value: 'IFX_US' }
          ],
          customData: { codeMType: 'DocPortCode' }
        },
        (res) =>
          res.content.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.code]: curr.descriptionEn
            }),
            {}
          )
      ),
      fetchRecords.execute(
        NaCodeMaintenanceApiUrls.GET,
        {
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'set_indicator', operator: EQUAL, value: 'IFX_US_FRN' }
          ],
          customData: { codeMType: 'DocPortCode' }
        },
        (res) =>
          res.content.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.code]: curr.descriptionEn
            }),
            {}
          )
      ),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'SBCI_PARTY_TYPE' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.COUNTRY),
      fetchRecords.execute(
        NaCodeMaintenanceApiUrls.GET,
        {
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'indicator', operator: EQUAL, value: 'ACI' }
          ],
          customData: { codeMType: 'CountryStateCode' }
        },
        (res) => res.content
      ),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'SBCI_PARTY_BILLED' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: EQUAL,
          value: 'SBCI_TRANSPORT_TERM_CODE'
        }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'SBCI_REFERENCE_TYPE' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'SBCI_PART_TYPE' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: EQUAL,
          value: 'SBCI_SPECIAL_PROGRAM_IND'
        }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: EQUAL,
          value: 'SBCI_BIND_RULING_ID_TYPE'
        }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: EQUAL,
          value: 'SBCI_OGA_CODE'
        }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: EQUAL,
          value: 'SBCI_ADDITIONAL_INFO_TYPE'
        }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: EQUAL,
          value: 'SBCI_AFFIRMATION_CODE'
        }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.UOM)
    ]).then(
      ([
        invoiceType,
        entryType,
        portLadingCode,
        portOfDestination,
        partyType,
        country,
        countryStates,
        partyBilled,
        transportTermCode,
        referenceType,
        partType,
        spclProgInd,
        bindRulingIdType,
        ogaCode,
        additionalInfoType,
        affirmationCode,
        uom
      ]) => {
        const states = Object.keys(country).reduce((acc, countryCode) => {
          acc[countryCode] = countryStates.filter(
            (state) => state.code === countryCode
          )

          return acc
        }, {})

        setLookups({
          invoiceType,
          entryType,
          portLadingCode,
          portOfDestination,
          partyType,
          country,
          states,
          partyBilled,
          transportTermCode,
          referenceType,
          partType,
          spclProgInd,
          bindRulingIdType,
          ogaCode,
          additionalInfoType,
          affirmationCode,
          uom
        })
      }
    )
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    if (Object.keys(lookups.country).includes(country)) {
      const result = lookups.states[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  return (
    <InvoiceLookupsContext.Provider
      value={{ lookups, getLookupValue, getCountryStateLabel }}
    >
      {children}
    </InvoiceLookupsContext.Provider>
  )
}

export default InvoiceLookupsProvider

export { InvoiceLookupsContext, InvoiceLookupsConsumer }
