import { Yup } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'

function makeValidationSchema(translate) {
  const errMsgMaxLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  const errMsgAlphaNumeric = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC
  )
  const errMsgDecimal1 = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_DECIMAL1
  )
  const errMsgDecimal2 = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_DECIMAL2
  )
  const errMsgDecimal3 = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_DECIMAL3
  )

  let regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object()
    .nullable()
    .shape({
      htsCode: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(10, errMsgMaxLength + ' 10'),
      dutiableUnitValue: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(
                /^\d{0,6}(\.\d{0,5})?$/,
                errMsgDecimal1 + ' 6 ' + errMsgDecimal2 + ' 5 ' + errMsgDecimal3
              )
              .max(12, errMsgMaxLength + ' 12')
      ),
      nonDutiableUnitValue: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .matches(
                /^\d{0,6}(\.\d{0,5})?$/,
                errMsgDecimal1 + ' 6 ' + errMsgDecimal2 + ' 5 ' + errMsgDecimal3
              )
              .max(12, errMsgMaxLength + ' 12')
      ),
      packingUnitValue: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(
                /^\d{0,6}(\.\d{0,5})?$/,
                errMsgDecimal1 + ' 6 ' + errMsgDecimal2 + ' 5 ' + errMsgDecimal3
              )
              .max(12, errMsgMaxLength + ' 12')
      ),
      goodsreturnedUnitValue: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(
                /^\d{0,6}(\.\d{0,5})?$/,
                errMsgDecimal1 + ' 6 ' + errMsgDecimal2 + ' 5 ' + errMsgDecimal3
              )
              .max(12, errMsgMaxLength + ' 12')
      ),
      goodsreturnedLineValue: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(
                /^\d{0,8}(\.\d{0,2})?$/,
                errMsgDecimal1 + ' 8 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
              )
              .max(11, errMsgMaxLength + ' 11')
      )
    })
}

export default makeValidationSchema
