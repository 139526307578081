import makeValidationSchema from './PackingListFormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import { Grid } from '@material-ui/core'
import NaUSPortAutocompleteField from '../../../components/na/autocomplete/codemaintenance/NaUSPortAutocompleteField'
import { useFormContext } from 'react-hook-form'
import Switch from '../../../components/Switch'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  otherRefNo: "",
  responsibleForCustoms: "",
  instructionsToBroker: "",
  containerized: "N",
  flightDetails: "",
  specialInstructions: "",
  placeOfDelivery: "",
  portOfLoading: "",
  billChargesTo: "",
  dateShipped: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const { setValue, watch, trigger } = useFormContext()
  const containerized = watch('containerized')

  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let otherRefNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PackingListForm.OTHER_REF_NO
    )
    let responsibleForCustoms = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PackingListForm.RESPONSIBLE_FOR_CUSTOMS
    )
    let instructionsToBroker = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PackingListForm.INSTRUCTIONS_TO_BROKER
    )
    let containerized = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PackingListForm.CONTAINERIZED
    )
    let flightDetails = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PackingListForm.FLIGHT_DETAILS
    )
    let specialInstructions = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PackingListForm.SPECIAL_INSTRUCTIONS
    )
    let placeOfDelivery = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PackingListForm.PLACE_OF_DELIVERY
    )
    let portOfLoading = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PackingListForm.PORT_OF_LOADING
    )
    let billChargesTo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PackingListForm.BILL_CHARGES_TO
    )
    let dateShipped = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PackingListForm.DATE_SHIPPED
    )

    return {
      otherRefNo,
      responsibleForCustoms,
      instructionsToBroker,
      containerized,
      flightDetails,
      specialInstructions,
      placeOfDelivery,
      portOfLoading,
      billChargesTo,
      dateShipped
    }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.otherRefNo}>
        <CngTextField
          name='otherRefNo'
          label={translatedTextsObject.otherRefNo}
          disabled={disabled}
          onChange={(event) => setValue('otherRefNo', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.portOfLoading}>
        <NaUSPortAutocompleteField
          name="portOfLoading"
          label={translatedTextsObject.portOfLoading}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.responsibleForCustoms}>
        <CngTextField
          name='responsibleForCustoms'
          label={translatedTextsObject.responsibleForCustoms}
          disabled={disabled}
          onChange={(event) => setValue('responsibleForCustoms', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.flightDetails}>
        <CngTextField
          name='flightDetails'
          label={translatedTextsObject.flightDetails}
          disabled={disabled}
          onChange={(event) => setValue('flightDetails', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.instructionsToBroker}>
        <CngCodeMasterAutocompleteField
          name='instructionsToBroker'
          label={translatedTextsObject.instructionsToBroker}
          disabled={disabled}
          codeType='SBCI_INSTRUCTIONS_TO_BROKER'
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.placeOfDelivery}>
        <CngTextField
          name='placeOfDelivery'
          label={translatedTextsObject.placeOfDelivery}
          disabled={disabled}
          onChange={(event) => setValue('placeOfDelivery', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.billChargesTo}>
        <CngCodeMasterAutocompleteField
          name='billChargesTo'
          label={translatedTextsObject.billChargesTo}
          disabled={disabled}
          codeType='SBCI_BILL_CHARGES_TO'
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.dateShipped}>
        <CngDateField
          name='dateShipped'
          label={translatedTextsObject.dateShipped}
          disabled={disabled}
          size='small'
          onBlur={(event) => trigger('dateShipped')}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.containerized}>
        <Switch
          fullWidth
          name='containerized'
          label={translatedTextsObject.containerized}
          disabled={disabled}
          checked={containerized === 'Y'}
          onChange={(event) => setValue('containerized', event.target.checked ? 'Y' : 'N')}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.specialInstructions}>
        <CngTextField
          name='specialInstructions'
          label={translatedTextsObject.specialInstructions}
          disabled={disabled}
          onChange={(event) => setValue('specialInstructions', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
    </Grid>
	)
}

const PackingListFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default PackingListFormFormProperties
