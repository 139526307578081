import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { components, useServices, useTranslation, constants } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import SbciInvoiceApiUrls from '../../../apiUrls/SbciInvoiceApiUrls'
import LineItemApiUrls from '../../../apiUrls/LineItemApiUrls'
import pathMap from '../../../paths/pathMap'
import InvoiceLookupsProvider from '../lookups/InvoiceLookupsContext'
import InvoiceViewContent from './InvoiceViewContent'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider
} from '@material-ui/core'
import {FileForUserGetUserDetails} from 'src/common/FileForUserCommon'
import { format } from 'date-fns'

const {
  button: { CngButton }
} = components

const {
  filter: { EQUAL }
} = constants

function ViewPage(props) {
  const { history, showNotification } = props
  const { id } = useParams()

  const [data, setData] = useState(null)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const { createRecord, deleteRecord, fetchRecord, fetchRecords,securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    fetchRecord.execute(SbciInvoiceApiUrls.GET, id, (res) => {
      const data = res.content[0]

      if (data === undefined) {
        showNotification('error', 'Data not found.')
        history.push(pathMap.SBCI_INVOICE_LIST_VIEW)
      } else {
        let fileForUserDetails = FileForUserGetUserDetails();
        if(fileForUserDetails!=null && fileForUserDetails!=undefined){
            data.fileForUserId = fileForUserDetails.fileForUserId
            data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId
            data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId
        }
        
        Promise.all([
          fetchRecords.execute(
            LineItemApiUrls.GET,
            {
              filters: [
                {
                  field: 'invoiceId',
                  operator: EQUAL,
                  value: data.id
                }
              ]
            },
            (res) => res.content
          )
        ]).then(([lineItems]) => {
          if (lineItems !== undefined) {
            data.lineItem = lineItems
          }
          setData(data)
        })
      }
    })
  }, [])

  function makeTranslatedTextsObject() {
    let editButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.EDIT_BUTTON
    )
    let cloneButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CLONE_BUTTON
    )
    let deleteButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.DELETE_BUTTON
    )
    let printAllButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PRINT_ALL_BUTTON
    )
    

    return { editButton, cloneButton, deleteButton,printAllButton }
  }

  if (!data) {
    return <CircularProgress />
  }

  function handlePrintAll() {
    function onSuccess(response) {
      let blob = new Blob([response.data]);

      let curDate = format(new Date(), 'yyyyMMddHHmmss');

      let filename = "SBCIInvoice_" + curDate + ".zip";
      

      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
    }

    function onError(error) {
      console.error(error);
    }

    function onComplete() {
    }

    const config = {
      responseType: 'blob'
    }

    securedSendRequest.execute('POST', SbciInvoiceApiUrls.PRINT_ALL, data, onSuccess, onError, onComplete, config, null);

  }

  function handleCloneInvoice() {
    if (history.location.state?.cloneData) {
      createRecord.execute(
        SbciInvoiceApiUrls.CLONE,
        history.location.state.cloneData,
        (data) => {
          data.tradeParty.forEach((datum, index) => {
            datum['_id'] = index
          });
          history.push(pathMap.SBCI_INVOICE_ADD_VIEW,{ cloneData: data })
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  function handleDeleteInvoice() {
    deleteRecord.execute(
      SbciInvoiceApiUrls.DELETE,
      data,
      () => {
        showNotification('success', 'Delete succeeded')
        history.push(pathMap.SBCI_INVOICE_LIST_VIEW)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  return (
    <>
      <Card>
        <CardContent>
          <InvoiceLookupsProvider>
            <InvoiceViewContent
              data={data}
              showNotification={showNotification}
            />
          </InvoiceLookupsProvider>
        </CardContent>
        <Divider />
        <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
          <CngButton
            color='secondary'
            onClick={() => history.push(pathMap.SBCI_INVOICE_LIST_VIEW)}
          >
            Back to Invoice List
          </CngButton>
          <Box display='flex' style={{ gap: 16 }}>
            {(data.status === 'DR' || data.status === 'TP') && (
              <CngButton
                onClick={() => setConfirmDialog(true)}
                startIcon={
                  <FontAwesomeIcon icon={['fal', 'trash']} size='sm' />
                }
                variant='outlined'
              >
                {translatedTextsObject.deleteButton}
              </CngButton>
            )}
             <CngButton
              onClick={handlePrintAll}
              startIcon={<FontAwesomeIcon icon={['fal', 'print']} size='sm' />}
              variant='outlined'
            >
              {translatedTextsObject.printAllButton}
            </CngButton>
            <CngButton
              onClick={handleCloneInvoice}
              startIcon={<FontAwesomeIcon icon={['fal', 'copy']} size='sm' />}
              variant='outlined'
            >
              {translatedTextsObject.cloneButton}
            </CngButton>
            {data.status !== 'ST' && (
              <CngButton
                color='primary'
                startIcon={<FontAwesomeIcon icon={['fal', 'pen']} size='sm' />}
                onClick={() =>
                  history.push(`${pathMap.SBCI_INVOICE}/edit/${id}`)
                }
              >
                {translatedTextsObject.editButton}
              </CngButton>
            )}
          </Box>
        </CardActions>
      </Card>
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={handleDeleteInvoice}
        title={translatedTextsObject.deleteButton}
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default ViewPage
