import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import FormProperties from './FdaAffirmationFormProperties'
import FdaAffirmationFieldsPlaceholder from './FdaAffirmationFieldsPlaceholder'
import useCustomYupValidationResolver from '../../../../components/aciacehighway/hooks/useCustomYupValidationResolver'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'
import FdaAffirmationFormProperties from './FdaAffirmationFormProperties'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function FdaAffirmationSection({showNotification}) {

  const { getValues, setValue, watch } = useFormContext()

  const fdaAffirmation = watch("fdaAffirmation")
  
  function handleAddFdaAffirmation(data) {
    const fdaAffirmation = [...getValues('fdaAffirmation'), data].map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('fdaAffirmation', fdaAffirmation)
  }

  function handleDeleteFdaAffirmation(data) {
    const clonedFdaAffirmation = [...getValues('fdaAffirmation')].filter((material) =>
      material.id ? material.id !== data.id : material._id !== data._id
    )
    const fdaAffirmation = clonedFdaAffirmation.map(
      (material, index) => ({ ...material, _id: index + 1 })
    )
    setValue('fdaAffirmation', fdaAffirmation)
  }

  return (
    <Grid container spacing={1}>
      {fdaAffirmation && fdaAffirmation.map((goods, index) => {
        const isLast = fdaAffirmation.length === 1
        return (
          <React.Fragment key={goods._id || goods.id || index}>
            <Grid item xs={12}>
              <FdaAffirmationEntry
                index={index}
                isLast={isLast}
                onAddFdaAffirmation={handleAddFdaAffirmation}
                onDeleteFdaAffirmation={handleDeleteFdaAffirmation}
                fdaAffirmation={goods}
                showNotification={showNotification}
              />
            </Grid>
            {fdaAffirmation.length !== index + 1 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </React.Fragment>
        )
      })}
    </Grid>
  )
}

function FdaAffirmationEntry(props) {
  const { index, isLast, onAddFdaAffirmation, onDeleteFdaAffirmation,  fdaAffirmation, showNotification } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md>
        <FdaAffirmationFormProperties.Fields index={index} showNotification={showNotification} />
      </Grid>
      <Grid item xs={12} md='auto'>
        <Grid container spacing={1}>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Add new'>
              <span>
                <CngIconButton
                  icon={['fal', 'plus']}
                  onClick={() => onAddFdaAffirmation(initialValues)}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Clone'>
              <span>
                <CngIconButton
                  icon={['fal', 'copy']}
                  onClick={() => onAddFdaAffirmation({ ...fdaAffirmation, id: undefined })}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          {!isLast && (
            <Grid item xs='auto'>
              <Tooltip placement='bottom' title='Delete'>
                <span>
                  <CngIconButton
                    icon={['fal', 'trash']}
                    onClick={() => onDeleteFdaAffirmation(fdaAffirmation)}
                    size='small'
                    type='outlined'
                  />
                </span>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FdaAffirmationSection
