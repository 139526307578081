import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  const errMsgDateToLaterThanDateFrom = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.DATE_TO_LATER_THAN_DATE_FROM
  )
  const errMsgMaxLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  const errMsgAlphaNumericNoSpecialChar = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_NO_SPECIAL_CHAR
  )
  const errMsgEmail = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_EMAIL
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object()
    .nullable()
    .shape({
      dateFrom: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.date().validFormat().typeError(dateTypeErrorMessage)
      ),
      dateTo: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.date()
              .validFormat()
              .min(Yup.ref('dateFrom'), errMsgDateToLaterThanDateFrom)
              .typeError(dateTypeErrorMessage)
      ),
      naftaDate: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.date().validFormat().typeError(dateTypeErrorMessage)
      ),
      company: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(50, errMsgMaxLength + ' 50'),
      naftaTitle: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(50, errMsgMaxLength + ' 50'),
      partyName: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(30, errMsgMaxLength + ' 30'),
      teleNo: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(30, errMsgMaxLength + ' 30'),
      contactFax: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(30, errMsgMaxLength + ' 30'),
      email: Yup.string()
        .nullable()
        .email(errMsgEmail)
        .max(500, errMsgMaxLength + ' 500'),
      certifierType: Yup.string().nullable()
    })
}

export default makeValidationSchema
