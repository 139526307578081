import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import FormProperties from './LaceyActFormProperties'
import LaceyActFieldsPlaceholder from './LaceyActFieldsPlaceholder'
import CollapsibleSection from '../../../../components/ui/CollapsibleSection'
import useCustomYupValidationResolver from '../../../../components/aciacehighway/hooks/useCustomYupValidationResolver'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function LaceyActSection(props) {
  const { laceyAct, title } = props

  const generatedIndex = useRef(0)
  const { getValues, setValue } = useFormContext()

  function handleAddLaceyAct(data) {
    const laceyAct = [...getValues('laceyAct'), data]

    setValue('laceyAct', laceyAct, { shouldDirty: true })
  }

  function handleDeleteLaceyAct(index) {
    const laceyAct = [...getValues('laceyAct')]

    laceyAct.splice(index, 1)

    setValue('laceyAct', laceyAct, { shouldDirty: true })
  }

  function handleEditLaceyAct(index, data) {
    const laceyAct = [...getValues('laceyAct')]

    laceyAct[index] = data

    setValue('laceyAct', laceyAct, { shouldDirty: true })
  }

  return (
    <CollapsibleSection defaultExpanded={!_.isEmpty(laceyAct)} title={title}>
      <Grid container spacing={1}>
        {_.isEmpty(laceyAct) ? (
          <Grid item xs={12}>
            <LaceyActFieldsPlaceholder onAdd={handleAddLaceyAct} />
          </Grid>
        ) : (
          laceyAct.map((act, index) => (
            <React.Fragment key={++generatedIndex.current}>
              <Grid item xs={12}>
                <LaceyActEntry
                  laceyAct={act}
                  onAdd={handleAddLaceyAct}
                  onDelete={() => handleDeleteLaceyAct(index)}
                  onEdit={(data) => handleEditLaceyAct(index, data)}
                />
              </Grid>
              {index + 1 !== laceyAct.length && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CollapsibleSection>
  )
}

function LaceyActEntry(props) {
  const { laceyAct, onAdd, onDelete, onEdit } = props

  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const methods = useForm({
    defaultValues: laceyAct,
    resolver: useCustomYupValidationResolver(makeValidationSchema(translate))
  })
  const {
    formState: { isDirty }
  } = methods

  function handleEditLaceyAct(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container justify='flex-end' spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12}>
          {isDirty ? (
            <Grid container justify='flex-end' spacing={1}>
              <Grid item xs='auto'>
                <Grid container spacing={2}>
                  <Grid item xs='auto'>
                    <CngButton
                      color='secondary'
                      onClick={() => methods.reset()}
                      size='medium'
                    >
                      Reset
                    </CngButton>
                  </Grid>
                  <Grid item xs='auto'>
                    <CngButton
                      color='primary'
                      onClick={methods.handleSubmit(handleEditLaceyAct)}
                      size='medium'
                    >
                      Save changes
                    </CngButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container justify='flex-end' spacing={1}>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Add'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'plus']}
                      onClick={() => onAdd(initialValues)}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Clone'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'copy']}
                      onClick={() => onAdd({ ...laceyAct, id: undefined })}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Delete'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'trash']}
                      onClick={() => onDelete(laceyAct)}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default LaceyActSection
