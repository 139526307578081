import makeValidationSchema from './TscaFormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import RadioButtonGroup from '../../../components/aciacehighway/RadioButtonGroup'
import TscaDetailsSection from './TscaDetailsSection'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CollapsibleSection from 'src/components/ui/CollapsibleSection'
import TscaDetailsFormProperties from './TscaDetailsFormProperties'

const {
  form: {
    field: { CngTextField, CngDateField },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  name: "",
  tscaDate: "",
  tscaTitle: "",
  phone: "",
  companyName: "",
  address1: "",
  address2: "",
  citystatezip: "",
  tscaCertChoice: "",
  researchCert: "N",
  tscaDetails: []
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue, trigger,watch } = useFormContext()

  const [tscaCertChoice, researchCert, tscaDetails] = useWatch({
    name: [
    'tscaCertChoice',
    'researchCert',
    'tscaDetails'
    ]
  })

  function tscaCertChoiceClickEvent(event) {
    if (event.target.value === tscaCertChoice) {
      setValue('tscaCertChoice', '')
    }
  }

  const forceDirty = watch('forceDirty')

  function makeTranslatedTextsObject() {
    let name = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TscaForm.NAME
    )
    let tscaDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TscaForm.TSCA_DATE
    )
    let tscaTitle = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TscaForm.TSCA_TITLE
    )
    let phone = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TscaForm.PHONE
    )
    let companyName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TscaForm.COMPANY_NAME
    )
    let address1 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TscaForm.ADDRESS1
    )
    let address2 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TscaForm.ADDRESS2
    )
    let citystatezip = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TscaForm.CITYSTATEZIP
    )
    let researchCert = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TscaForm.RESEARCH_CERT
    )
    let tscaDetails = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TscaDetails.TITLE
    )

    return {
      name,
      tscaDate,
      tscaTitle,
      phone,
      companyName,
      address1,
      address2,
      citystatezip,
      researchCert,
      tscaDetails
    }
  }

	return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <CngGridItem xs={12} shouldHide={shouldHideMap?.tscaCertChoice}>
            <RadioButtonGroup
              onChange={(event) => setValue('tscaCertChoice', event.target.value, { shouldDirty: true })}
              onClick={(event) => tscaCertChoiceClickEvent(event)}
              options={[
                {
                  label: (
                    <Typography variant='body2'>
                      <Typography color='textSecondary' component='span' style={{ fontWeight: 600 }}>
                        Positive certification:&nbsp;
                      </Typography>
                      <Typography component='span' style={{ fontWeight: 600 }}>
                        I certify that all chemical substances in this shipment
                        comply with all applicable rules or orders under TSCA and
                        that I am not offering a chemical substance for entry in
                        violation of TSCA or any applicable rule or order under TSCA
                      </Typography>
                    </Typography>
                  ),
                  value: 'CH1'
                },
                {
                  label: (
                    <Typography variant='body2'>
                      <Typography color='textSecondary' component='span' style={{ fontWeight: 600 }}>
                        Negative certification:&nbsp;
                      </Typography>
                      <Typography component='span' style={{ fontWeight: 600 }}>
                        I certify that all chemicals in this shipment are not subject to TSCA.(And, if applicable)
                      </Typography>
                    </Typography>
                  ),
                  value: 'CH2'
                }
              ]}
              value={tscaCertChoice}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.name}>
            <CngTextField
              name='name'
              label={translatedTextsObject.name}
              disabled={disabled}
              onChange={(event) => setValue('name', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.tscaDate}>
            <CngDateField
              name='tscaDate'
              label={translatedTextsObject.tscaDate}
              disabled={disabled}
              size='small'
              onBlur={(event) => trigger('tscaDate')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.tscaTitle}>
            <CngTextField
              name='tscaTitle'
              label={translatedTextsObject.tscaTitle}
              disabled={disabled}
              onChange={(event) => setValue('tscaTitle', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.phone}>
            <CngTextField
              disabled={disabled}
              label={translatedTextsObject.phone}
              name='phone'
              onChange={(event) => setValue('phone', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.companyName}>
            <CngTextField
              disabled={disabled}
              label={translatedTextsObject.companyName}
              name='companyName'
              onChange={(event) => setValue('companyName', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.address1}>
            <CngTextField
              disabled={disabled}
              label={translatedTextsObject.address1}
              name='address1'
              onChange={(event) => setValue('address1', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.address2}>
            <CngTextField
              disabled={disabled}
              label={translatedTextsObject.address2}
              name='address2'
              onChange={(event) => setValue('address2', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.citystatezip}>
            <CngTextField
              disabled={disabled}
              label={translatedTextsObject.citystatezip}
              name='citystatezip'
              onChange={(event) => setValue('citystatezip', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardHeader
            action={
              <FormControlLabel
                control={
                  <Checkbox
                    checked={researchCert === 'Y'}
                    checkedIcon={<FontAwesomeIcon icon={['fad', 'square-check']} />}
                    icon={<FontAwesomeIcon icon={['fad', 'square']} />}
                  />
                }
                label={
                  <Typography color='textSecondary' variant='body2' style={{ fontWeight: 600 }}>
                    {translatedTextsObject.researchCert}
                  </Typography>
                }
                onChange={(event) => setValue('researchCert', event.target.checked ? 'Y' : 'N', { shouldDirty: true })}
              />
            }
            title={translatedTextsObject.tscaDetails}
            titleTypographyProps={{ style: { fontWeight: 600 }}}
          />
          <CardContent>
          <CollapsibleSection
              defaultExpanded={tscaDetails.length > 0}
              onExpandedChange={(expanded) => {
                const { initialValues } = TscaDetailsFormProperties.formikProps
                setValue('tscaDetails', expanded ? [{ ...initialValues, _id: 1 }] : [], {shouldDirty:true})

                setValue('forceDirty', !expanded ? "" : "true", {shouldDirty:true})
              }}
              title='TSCA Details List'
            >
                <TscaDetailsSection tscaDetails={tscaDetails} />
            </CollapsibleSection>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
	)
}

const TscaFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TscaFormFormProperties
