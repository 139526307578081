import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import FormProperties from './TscaFormFormProperties'
import useCustomYupValidationResolver from '../../../components/aciacehighway/hooks/useCustomYupValidationResolver'
import { FormProvider, useForm } from 'react-hook-form'
import _ from 'lodash'
import { CardContent, CardHeader, Grid } from '@material-ui/core'

const {
  button: { CngButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function TscaFormSection(props) {
  const { tscaForm, onSave, title } = props

  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const methods = useForm({
    defaultValues: tscaForm || initialValues,
    resolver: useCustomYupValidationResolver(makeValidationSchema(translate))
  })
  const {
    formState: { isDirty, isSubmitting },
    handleSubmit
  } = methods

  async function onSubmit(data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        onSave(data)
        methods.reset(data)
        resolve()
      }, 500)
    })
  }

  return (
    <FormProvider {...methods}>
      <CardHeader
        action={
          isDirty ? (
            <Grid container spacing={1}>
              <Grid item xs='auto'>
                <CngButton
                  color='secondary'
                  disabled={isSubmitting}
                  onClick={() => methods.reset(tscaForm || initialValues)}
                  size='small'
                >
                  Reset
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton
                  disabled={isSubmitting}
                  onClick={handleSubmit(onSubmit)}
                  shouldShowProgress={isSubmitting}
                  size='small'
                >
                  Save
                </CngButton>
              </Grid>
            </Grid>
          ) : (
            <CngButton
              color='secondary'
              disabled={!isDirty}
              onClick={() => methods.reset(initialValues)}
              size='small'
            >
              Clear
            </CngButton>
          )
        }
        title={title}
        titleTypographyProps={{ style: { fontWeight: 600 } }}
      />
      <CardContent>
        <FormProperties.Fields />
      </CardContent>
    </FormProvider>
  )
}

export default TscaFormSection
