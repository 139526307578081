import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  const errMsgNumeric = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_NUMERIC
  )
  const errMsgMaxLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  const errMsgAlphaNumeric = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC
  )
  const errMsgDecimal1 = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_DECIMAL1
  )
  const errMsgDecimal2 = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_DECIMAL2
  )
  const errMsgDecimal3 = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_DECIMAL3
  )

  const regexNumeric = '^[0-9]*$'
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object()
    .nullable()
    .shape({
      invoiceNo: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(15, errMsgMaxLength + ' 15'),
      htsCode: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(10, errMsgMaxLength + ' 10'),
      unitValue: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(
                /^\d{0,8}(\.\d{0,2})?$/,
                errMsgDecimal1 + ' 8 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
              )
              .max(11, errMsgMaxLength + ' 11')
      ),
      hts9802RepairQty: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(
                /^\d{0,7}(\.\d{0,2})?$/,
                errMsgDecimal1 + ' 7 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
              )
              .max(11, errMsgMaxLength + ' 11')
      ),
      hts9802RepairQtyUom: Yup.string().nullable(),
      uom1Factor2: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(
                /^\d{0,6}(\.\d{0,5})?$/,
                errMsgDecimal1 + ' 6 ' + errMsgDecimal2 + ' 5 ' + errMsgDecimal3
              )
              .max(12, errMsgMaxLength + ' 12')
      ),
      uom2Factor2: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(
                /^\d{0,6}(\.\d{0,5})?$/,
                errMsgDecimal1 + ' 6 ' + errMsgDecimal2 + ' 5 ' + errMsgDecimal3
              )
              .max(12, errMsgMaxLength + ' 12')
      ),
      specialHtsCode: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(10, errMsgMaxLength + ' 10'),
      categoryNo: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(3, errMsgMaxLength + ' 3'),
      certOfRegNo: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(10, errMsgMaxLength + ' 10'),
      repairQty: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(
                /^\d{0,7}(\.\d{0,2})?$/,
                errMsgDecimal1 + ' 7 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
              )
              .max(10, errMsgMaxLength + ' 10')
      ),
      repairQtyUom: Yup.string().nullable(),
      reworkDate: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.date().nullable().typeError(dateTypeErrorMessage)
      ),
      noOfContainers1: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(regexNumeric, errMsgNumeric)
              .nullable()
              .max(8, errMsgMaxLength + ' 6')
      ),
      containerType1: Yup.string().nullable(),
      noOfContainers2: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(regexNumeric, errMsgNumeric)
              .nullable()
              .max(8, errMsgMaxLength + ' 6')
      ),
      containerType2: Yup.string().nullable()
    })
}

export default makeValidationSchema
