import makeValidationSchema from './ProduceMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'
import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  scientificName: '',
  description: '',
  produceSize: '',
  productCode: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue } = useFormContext()

  function makeTranslatedTextsObject() {
    let scientificName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Produce.SCIENTIFIC_NAME
    )
    let description = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Produce.DESCRIPTION
    )
    let produceSize = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Produce.PRODUCE_SIZE
    )
    let productCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Produce.PRODUCT_CODE
    )

    return {
      scientificName,
      description,
      produceSize,
      productCode
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.scientificName}>
        <CngTextField
          name='scientificName'
          label={translatedTextsObject.scientificName}
          disabled={disabled}
          onChange={(event) => setValue('scientificName', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.produceSize}>
        <CngTextField
          name='produceSize'
          label={translatedTextsObject.produceSize}
          disabled={disabled}
          onChange={(event) => setValue('produceSize', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.productCode}>
        <CngTextField
          name='productCode'
          label={translatedTextsObject.productCode}
          disabled={disabled}
          onChange={(event) => setValue('productCode', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} shouldHide={shouldHideMap?.description}>
        <CngTextField
          name='description'
          label={translatedTextsObject.description}
          disabled={disabled}
          onChange={(event) => setValue('description', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const ProduceFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ProduceFormProperties
