import { Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'

function makeValidationSchema(translate) {
  const errMsgMaxLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  const errMsgNumeric = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_NUMERIC
  )
  const errMsgAlphaNumericNoSpecialChar = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_NO_SPECIAL_CHAR
  )

  const regexNumericDecimal = '^[0-9.]*$'
  const regexNumeric = '^[0-9]*$'
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object()
    .shape({
      containerNo: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(30, errMsgMaxLength + ' 30'),
      freightCharges: Yup.string().nullable(),
      placeOfLading: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(50, errMsgMaxLength + ' 50'),
      deliveryInstructions: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(100, errMsgMaxLength + ' 100'),
      codAmount: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(regexNumericDecimal, errMsgNumeric)
              .max(10, errMsgMaxLength + ' 10')
      ),
      shipmentDeclareValue: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(regexNumericDecimal, errMsgNumeric)
              .max(10, errMsgMaxLength + ' 10')
      ),
      releaseValue: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(regexNumericDecimal, errMsgNumeric)
              .max(10, errMsgMaxLength + ' 10')
      ),
      chargeTo: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(50, errMsgMaxLength + ' 50'),
      totShpmntCubicFt: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(20, errMsgMaxLength + ' 20'),
      freightClass: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(50, errMsgMaxLength + ' 50')
    })
    .nullable()
}

export default makeValidationSchema
