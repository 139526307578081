import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import { NaStateCodeAutoCompleteField } from '../../../components/na'
import CheckboxButton from '../../../components/button/CheckboxButton'
import makeValidationSchema from './CiFormMakeValidationSchema'
import { useFormContext, useWatch } from 'react-hook-form'
import { Box, Card, Grid, Typography } from '@material-ui/core'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngSelectField,
      CngCountryAutocompleteField,
      CngCodeMasterAutocompleteField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyBilled: "",
  shipmentDate: "",
  arrivalDatetime: "",
  countryOfExport: "",
  exportStateCode: "",
  destinationCountry: "",
  destinationStateCode: "",
  transportTermCode: "",
  shipperRegNo: "",
  transactionParties: "",
  ffdPurchasePrice: "",
  fbiPurchasePrice: "",
  bfiPurchasePrice: "",
  dfiPurchasePrice: "",
  goodsSold: "",
  markPackLoad: "",
  termsOfPayment: "",
  reasonForExport: "",
  naftaDeclaration: "",
  tscaDeclaration: "",
  usProforma: "",
  usGoods: "",
  usShipExp: "",
  usGoods10219: "",
  fdaSub: "",
  declNewStmt: "",
  cargoSummary: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue, watch, trigger } = useFormContext()

  const {
    transactionParties,
    ffdPurchasePrice,
    fbiPurchasePrice,
    bfiPurchasePrice,
    dfiPurchasePrice,
    goodsSold,
    partyBilled,
    transportTermCode,
    countryOfExport,
    destinationCountry,
    naftaDeclaration,
    tscaDeclaration,
    usProforma,
    usGoods,
    usShipExp,
    usGoods10219,
    fdaSub
  } = useWatch({ name: 'ciForm' })

  function makeTranslatedTextsObject() {
    const ciForm = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.TITLE
    )
    const partyBilled = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.PARTY_BILLED
    )
    const partyBilledOther = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.PARTY_BILLED_OTHER
    )
    const shipmentDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.SHIPMENT_DATE
    )
    const arrivalDatetime = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.ARRIVAL_DATETIME
    )
    const countryOfExport = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.COUNTRY_OF_EXPORT
    )
    const exportStateCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.EXPORT_STATE_CODE
    )
    const destinationCountry = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.DESTINATION_COUNTRY
    )
    const destinationStateCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.DESTINATION_STATE_CODE
    )
    const transportTermCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.TRANSPORT_TERM_CODE
    )
    const shipperRegNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.SHIPPER_REG_NO
    )
    const transactionParties = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.TRANSACTION_PARTIES
    )
    const ffdPurchasePrice = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.FFD_PURCHASE_PRICE
    )
    const fbiPurchasePrice = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.FBI_PURCHASE_PRICE
    )
    const bfiPurchasePrice = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.BFI_PURCHASE_PRICE
    )
    const dfiPurchasePrice = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.DFI_PURCHASE_PRICE
    )
    const goodsSold = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.GOODS_SOLD
    )
    const markPackLoad = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.MARK_PACK_LOAD
    )
    const termsOfPayment = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.TERMS_OF_PAYMENT
    )
    const reasonForExport = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.REASON_FOR_EXPORT
    )
    const naftaDeclaration = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.NAFTA_DECLARATION
    )
    const tscaDeclaration = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.TSCA_DECLARATION
    )
    const usProforma = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.US_PROFORMA
    )
    const usGoods = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.US_GOODS
    )
    const usShipExp = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.US_SHIP_EXP
    )
    const usGoods10219 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.US_GOODS10219
    )
    const fdaSub = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.FDA_SUB
    )
    const declNewStmt = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.DECL_NEW_STMT
    )
    const titleFOBRelatedInstructions = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.TITLE_FOB_RELATED_INSTRUCTIONS
    )
    const titleYesNoQuestionnaire = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.TITLE_YES_NO_QUESTIONNAIRE
    )
    const titleDeclarations = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.TITLE_DECLARATIONS
    )

    return {
      ciForm,
      partyBilled,
      partyBilledOther,
      shipmentDate,
      arrivalDatetime,
      markPackLoad,
      termsOfPayment,
      reasonForExport,
      titleFOBRelatedInstructions,
      countryOfExport,
      exportStateCode,
      destinationCountry,
      destinationStateCode,
      transportTermCode,
      shipperRegNo,
      titleYesNoQuestionnaire,
      transactionParties,
      ffdPurchasePrice,
      fbiPurchasePrice,
      bfiPurchasePrice,
      dfiPurchasePrice,
      goodsSold,
      titleDeclarations,
      naftaDeclaration,
      tscaDeclaration,
      usProforma,
      usGoods,
      usShipExp,
      usGoods10219,
      fdaSub,
      declNewStmt,
    }
  }

  const isPartyBilledOther = partyBilled === 'O'

	return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box mb={2}>
          <Typography color='textSecondary'>
            {translatedTextsObject.ciForm}
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <CngGridItem
            xs={12}
            sm={6}
            md={isPartyBilledOther ? 6 : 4}
            lg={isPartyBilledOther ? 6 : 3}
            shouldHide={shouldHideMap?.partyBilled}
          >
            <CngCodeMasterAutocompleteField
              key={partyBilled}
              name='ciForm.partyBilled'
              label={translatedTextsObject.partyBilled}
              disabled={disabled}
              codeType='SBCI_PARTY_BILLED'
              size='small'
            />
          </CngGridItem>
          <CngGridItem
            xs={12}
            sm={6}
            md={isPartyBilledOther ? 6 : 4}
            lg={isPartyBilledOther ? 6 : 3}
            shouldHide={shouldHideMap?.partyBilledOther || !isPartyBilledOther}
          >
            <CngTextField
              name='ciForm.partyBilledOther'
              label={translatedTextsObject.partyBilledOther}
              onChange={(event) =>
                setValue('ciForm.partyBilledOther', event.target.value.toUpperCase(), {shouldValidate:true})
              }
              size='small'
            />
          </CngGridItem>
          <CngGridItem
            xs={12}
            sm={6}
            md={isPartyBilledOther ? 6 : 4}
            lg={isPartyBilledOther ? 6 : 3}
            shouldHide={shouldHideMap?.shipmentDate}
          >
            <CngDateField
              name='ciForm.shipmentDate'
              label={translatedTextsObject.shipmentDate}
              disabled={disabled}
              size='small'
              onBlur={(event) => trigger('ciForm.shipmentDate')}
            />
          </CngGridItem>
          <CngGridItem
            xs={12}
            sm={6}
            md={isPartyBilledOther ? 6 : 4}
            lg={isPartyBilledOther ? 6 : 3}
            shouldHide={shouldHideMap?.arrivalDatetime}
          >
            <CngDateField
              name='ciForm.arrivalDatetime'
              label={translatedTextsObject.arrivalDatetime}
              disabled={disabled}
              size='small'
              onBlur={(event) => trigger('ciForm.arrivalDatetime')}
            />
          </CngGridItem>
          <CngGridItem
            xs={12}
            sm={6}
            md={isPartyBilledOther ? 6 : 4}
            lg={isPartyBilledOther ? 6 : 3}
            shouldHide={shouldHideMap?.markPackLoad}
          >
            <CngTextField
              name='ciForm.markPackLoad'
              label={translatedTextsObject.markPackLoad}
              onChange={(event) =>
                setValue('ciForm.markPackLoad', event.target.value.toUpperCase(), {shouldValidate:true})
              }
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.termsOfPayment}>
            <CngTextField
              name='ciForm.termsOfPayment'
              label={translatedTextsObject.termsOfPayment}
              onChange={(event) => setValue('ciForm.termsOfPayment', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={isPartyBilledOther ? 12 : 6} shouldHide={shouldHideMap?.reasonForExport}>
            <CngTextField
              name='ciForm.reasonForExport'
              label={translatedTextsObject.reasonForExport}
              //helperText='If goods are not being sold'
              onChange={(event) => setValue('ciForm.reasonForExport', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box mb={2}>
          <Typography color='textSecondary'>
            {translatedTextsObject.titleFOBRelatedInstructions}
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap?.countryOfExport}>
            <CngCountryAutocompleteField
              name="ciForm.countryOfExport"
              label={translatedTextsObject.countryOfExport}
              disabled={disabled}
              size='small'
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap?.exportStateCode}>
            <NaStateCodeAutoCompleteField
              name="ciForm.exportStateCode"
              label={translatedTextsObject.exportStateCode}
              disabled={disabled}
              countryCode={countryOfExport}
              key={countryOfExport}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap?.destinationCountry}>
            <CngCountryAutocompleteField
              name="ciForm.destinationCountry"
              label={translatedTextsObject.destinationCountry}
              disabled={disabled}
              size='small'
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap?.destinationStateCode}>
            <NaStateCodeAutoCompleteField
              name="ciForm.destinationStateCode"
              label={translatedTextsObject.destinationStateCode}
              disabled={disabled}
              countryCode={destinationCountry}
              key={destinationCountry}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.transportTermCode}>
            <CngCodeMasterAutocompleteField
              key={transportTermCode}
              name='ciForm.transportTermCode'
              label={translatedTextsObject.transportTermCode}
              disabled={disabled}
              codeType='SBCI_TRANSPORT_TERM_CODE'
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.shipperRegNo}>
            <CngTextField
              name='ciForm.shipperRegNo'
              label={translatedTextsObject.shipperRegNo}
              onChange={(event) => setValue('ciForm.shipperRegNo', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box mb={2}>
          <Typography color='textSecondary'>
            {translatedTextsObject.titleYesNoQuestionnaire}
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.transactionParties}>
            <Card variant='outlined'>
              <Box bgcolor='background.sectionOddBg' p={2}>
                <Box mb={1}>
                  <Typography>
                    {translatedTextsObject.transactionParties}
                  </Typography>
                </Box>
                <CngSelectField
                  label='Answer'
                  disabled={disabled}
                  items={[
                    { text: <em>Answer</em>, value: '' },
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' }
                  ]}
                  size='small'
                  onChange={(event) => setValue('ciForm.transactionParties', event.target.value)}
                  value={transactionParties ? transactionParties : ''}
                />
              </Box>
            </Card>
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.ffdPurchasePrice}>
            <Card variant='outlined'>
              <Box bgcolor='background.sectionOddBg' p={2}>
                <Box mb={1}>
                  <Typography>
                    {translatedTextsObject.ffdPurchasePrice}
                  </Typography>
                </Box>
                <CngSelectField
                  label='Answer'
                  disabled={disabled}
                  items={[
                    { text: <em>Answer</em>, value: '' },
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' }
                  ]}
                  size='small'
                  onChange={(event) => setValue('ciForm.ffdPurchasePrice', event.target.value)}
                  value={ffdPurchasePrice ? ffdPurchasePrice : ''}
                />
              </Box>
            </Card>
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.fbiPurchasePrice}>
            <Card variant='outlined'>
              <Box bgcolor='background.sectionOddBg' p={2}>
                <Box mb={1}>
                  <Typography>
                    {translatedTextsObject.fbiPurchasePrice}
                  </Typography>
                </Box>
                <CngSelectField
                  label='Answer'
                  disabled={disabled}
                  items={[
                    { text: <em>Answer</em>, value: '' },
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' }
                  ]}
                  size='small'
                  onChange={(event) => setValue('ciForm.fbiPurchasePrice', event.target.value)}
                  value={fbiPurchasePrice ? fbiPurchasePrice : ''}
                />
              </Box>
            </Card>
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.bfiPurchasePrice}>
            <Card variant='outlined'>
              <Box bgcolor='background.sectionOddBg' p={2}>
                <Box mb={1}>
                  <Typography>
                    {translatedTextsObject.bfiPurchasePrice}
                  </Typography>
                </Box>
                <CngSelectField
                  label='Answer'
                  disabled={disabled}
                  items={[
                    { text: <em>Answer</em>, value: '' },
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' }
                  ]}
                  size='small'
                  onChange={(event) => setValue('ciForm.bfiPurchasePrice', event.target.value)}
                  value={bfiPurchasePrice ? bfiPurchasePrice : ''}
                />
              </Box>
            </Card>
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.dfiPurchasePrice}>
            <Card variant='outlined'>
              <Box bgcolor='background.sectionOddBg' p={2}>
                <Box mb={1}>
                  <Typography>
                    {translatedTextsObject.dfiPurchasePrice}
                  </Typography>
                </Box>
                <CngSelectField
                  label='Answer'
                  disabled={disabled}
                  items={[
                    { text: <em>Answer</em>, value: '' },
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' }
                  ]}
                  size='small'
                  onChange={(event) => setValue('ciForm.dfiPurchasePrice', event.target.value)}
                  value={dfiPurchasePrice ? dfiPurchasePrice : ''}
                />
              </Box>
            </Card>
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.goodsSold}>
            <Card variant='outlined'>
              <Box bgcolor='background.sectionOddBg' p={2}>
                <Box mb={1}>
                  <Typography>
                    {translatedTextsObject.goodsSold}
                  </Typography>
                </Box>
                <CngSelectField
                  label='Answer'
                  disabled={disabled}
                  items={[
                    { text: <em>Answer</em>, value: '' },
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' }
                  ]}
                  size='small'
                  onChange={(event) => setValue('ciForm.goodsSold', event.target.value)}
                  value={goodsSold ? goodsSold : ''}
                />
              </Box>
            </Card>
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box mb={2}>
          <Typography color='textSecondary'>
            {translatedTextsObject.titleDeclarations}
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.naftaDeclaration}>
            <CheckboxButton
              name='ciForm.naftaDeclaration'
              label={translatedTextsObject.naftaDeclaration}
              checked={naftaDeclaration === 'Y'}
              disabled={disabled}
              onChange={(event) => setValue('ciForm.naftaDeclaration', event.target.checked ? 'Y' : 'N')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.tscaDeclaration}>
            <CheckboxButton
              name='ciForm.tscaDeclaration'
              label={translatedTextsObject.tscaDeclaration}
              checked={tscaDeclaration === 'Y'}
              disabled={disabled}
              onChange={(event) => setValue('ciForm.tscaDeclaration', event.target.checked ? 'Y' : 'N')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.usProforma}>
            <CheckboxButton
              name='ciForm.usProforma'
              label={translatedTextsObject.usProforma}
              checked={usProforma === 'Y'}
              disabled={disabled}
              onChange={(event) => setValue('ciForm.usProforma', event.target.checked ? 'Y' : 'N')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.usGoods}>
            <CheckboxButton
              name='ciForm.usGoods'
              label={translatedTextsObject.usGoods}
              checked={usGoods === 'Y'}
              disabled={disabled}
              onChange={(event) => setValue('ciForm.usGoods', event.target.checked ? 'Y' : 'N')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.usShipExp}>
            <CheckboxButton
              name='ciForm.usShipExp'
              label={translatedTextsObject.usShipExp}
              checked={usShipExp === 'Y'}
              disabled={disabled}
              onChange={(event) => setValue('ciForm.usShipExp', event.target.checked ? 'Y' : 'N')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.usGoods10219}>
            <CheckboxButton
              name='ciForm.usGoods10219'
              label={translatedTextsObject.usGoods10219}
              checked={usGoods10219 === 'Y'}
              disabled={disabled}
              onChange={(event) => setValue('ciForm.usGoods10219', event.target.checked ? 'Y' : 'N')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.fdaSub}>
            <CheckboxButton
              name='ciForm.fdaSub'
              label={translatedTextsObject.fdaSub}
              checked={fdaSub === 'Y'}
              disabled={disabled}
              onChange={(event) => setValue('ciForm.fdaSub', event.target.checked ? 'Y' : 'N')}
            />
          </CngGridItem>
          <CngGridItem xs={12} shouldHide={shouldHideMap?.declNewStmt}>
            <CngTextField
              name='ciForm.declNewStmt'
              label={translatedTextsObject.declNewStmt}
              onChange={(event) => setValue('ciForm.declNewStmt', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
    </Grid>
	)
}

const CiFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default CiFormFormProperties
