import React, { useEffect, useState } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import TradePartyFormProperties from './TradePartyFormProperties'
import LocalTable from '../../../components/aciacehighway/LocalTable'
import Alert from '../../../components/Alert'
import DialogForm from './DialogForm'
import { Grid, Card, Typography, Box } from '@material-ui/core'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import { useFormContext } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL },
  CodeMaintenanceType
} = constants

function TradePartySection(props) {
  const { tradeParty } = props
  const [lookups, setLookups] = useState(null)
  const [dialog, setDialog] = useState({ open: false, tradeParty: null })
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { getValues, setValue } = useFormContext()

  function makeTranslatedTextsObject() {
    const tradeParty = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.TITLE
    )
    const partyIdn = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.PARTY_IDN
    )
    const partyName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.PARTY_NAME
    )
    const partyType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.PARTY_TYPE
    )
    const address = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.ADDRESS
    )
    const country = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActDetails.COUNTRY
    )

    return {
      partyIdn,
      partyType,
      partyName,
      address,
      country,
      tradeParty,
    }
  }

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'SBCI_PARTY_TYPE' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.COUNTRY)
    ]).then(([partyType, country]) => {
      setLookups({ partyType, country })
    })
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const columns = [
    {
      title: translatedTextsObject.partyIdn,
      field: 'partyIdn',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.partyType,
      field: 'partyType',
      filterConfig: {
        type: 'select',
        options: lookups?.partyType
          ? Object.entries(lookups.partyType).map(([code, label]) => ({
              label: label,
              value: code
            }))
          : []
      },
      render: (data) => getLookupValue('partyType', data.partyType)
    },
    {
      title: translatedTextsObject.partyName,
      field: 'partyName',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.address,
      field: 'address',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.country,
      field: 'countryCode',
      filterConfig: { type: 'textfield' },
      render: (data) => getLookupValue('country', data.countryCode)
    }
  ]

  function handleAddTradeParty(data) {
    let tradeParty = null;
    if (getValues('tradeParty')) {
      tradeParty = [...getValues('tradeParty'), data].map(
        (party, index) => ({ ...party, _id: index + 1 })
      )
    } else {
      tradeParty = [{ ...data, _id: 0 }]
    }

    setValue('tradeParty', tradeParty)
    setDialog({ open: false, tradeParty: null })
  }

  function handleDeleteTradeParty(data) {
    const tradeParty = [...getValues('tradeParty')].filter((party) =>
      party.id ? party.id !== data.id : party._id !== data._id
    )

    setValue('tradeParty', tradeParty)
  }

  function handleEditTradeParty(data) {
    const tradeParty = [...getValues('tradeParty')]
    const index = tradeParty.findIndex((party) =>
      party.id ? party.id === data.id : party._id === data._id
    )

    tradeParty[index] = data

    setValue('tradeParty', tradeParty)
    setDialog({ open: false, tradeParty: null })
  }

  return (
    <Card variant='outlined'>
      <LocalTable
        actions={[
          {
            buttonProps: { size: 'medium', color: 'secondary' },
            icon: ['fal', 'plus-circle'],
            label: 'Add party',
            onClick: () => setDialog({ open: true, tradeParty: null })
          }
        ]}
        columns={columns}
        data={tradeParty}
        header={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography style={{ fontWeight: 600 }}>
                {translatedTextsObject.tradeParty}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='flex-end'>
                <Alert
                  severity='info'
                  icon={<FontAwesomeIcon icon={['fal', 'info-circle']} size='xs' />}
                >
                  <Typography style={{ lineHeight: 1.2 }} variant='caption'>
                    <Typography style={{ fontWeight: 600 }} variant='inherit'>
                      NOTE:&nbsp;
                    </Typography>
                    Please include the Shipper, Ship to, and Consignee
                    information on this form.
                  </Typography>
                </Alert>
              </Box>
            </Grid>
          </Grid>
        }
        rowActions={[
          {
            icon: ['fal', 'pen'],
            onClick: (rowData) => setDialog({ open: true, tradeParty: rowData }),
            tooltip: () => 'Edit'
          },
          {
            icon: ['fal', 'trash'],
            onClick: handleDeleteTradeParty,
            tooltip: () => 'Delete'
          }
        ]}
      />
      <DialogForm
        formProperties={TradePartyFormProperties}
        fullWidth
        maxWidth='md'
        open={dialog.open}
        onClose={() => setDialog({ open: false, tradeParty: null })}
        onSubmit={
          dialog.tradeParty ? handleEditTradeParty : handleAddTradeParty
        }
        title={`${dialog.tradeParty ? 'Edit' : 'Add'} trade party`}
        value={dialog.tradeParty}
      />
    </Card>
  )
}

export default TradePartySection
