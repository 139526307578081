import React, { useEffect, useState } from 'react'
import { components, constants } from 'cng-web-lib'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL },
  CodeMaintenanceType
} = constants

const ProductMasterLookupsContext = React.createContext()

const ProductMasterLookupsConsumer = ProductMasterLookupsContext.Consumer

const ProductMasterLookupsProvider = ({ children }) => {
  const [lookups, setLookups] = useState(null)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'SBCI_PART_TYPE' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: EQUAL,
          value: 'SBCI_SPECIAL_PROGRAM_IND'
        }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: EQUAL,
          value: 'SBCI_BIND_RULING_ID_TYPE'
        }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: EQUAL,
          value: 'SBCI_OGA_CODE'
        }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: EQUAL,
          value: 'SBCI_ADDITIONAL_INFO_TYPE'
        }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: EQUAL,
          value: 'SBCI_AFFIRMATION_CODE'
        }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: EQUAL,
          value: 'SBCI_STORAGE_STATUS'
        }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: EQUAL,
          value: 'SBCI_MARKER'
        }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: EQUAL,
          value: 'SBCI_IMPORT_CONDITION_NO'
        }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.COUNTRY),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.UOM)
    ]).then(
      ([
        partType,
        spclPrgInd,
        bindRulingIdType,
        ogaCode,
        additionalInfoType,
        affirmationCode,
        storageStatus,
        marker,
        importConditionNo,
        country,
        uom
      ]) => {
        setLookups({
          partType,
          spclPrgInd,
          bindRulingIdType,
          ogaCode,
          additionalInfoType,
          affirmationCode,
          storageStatus,
          marker,
          importConditionNo,
          country,
          uom
        })
      }
    )
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  return (
    <ProductMasterLookupsContext.Provider
      value={{ lookups, getLookupValue }}
    >
      {children}
    </ProductMasterLookupsContext.Provider>
  )
}

export default ProductMasterLookupsProvider

export { ProductMasterLookupsContext, ProductMasterLookupsConsumer }
