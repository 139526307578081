import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './CargoSummaryFormProperties'
import CargoSummaryFieldsPlaceholder from './CargoSummaryFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'
import CargoSummaryFormProperties from './CargoSummaryFormProperties'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function CargoSummarySection({showNotification}) {

  const { getValues, setValue, watch } = useFormContext()

  const cargoSummary = watch('ciForm.cargoSummary')

  function handleAddCargoSummary(data) {
    const cargoSummary = [...getValues('ciForm.cargoSummary'), data].map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('ciForm.cargoSummary', cargoSummary)
  }

  function handleDeleteCargoSummary(data) {
    const clonedCargoSummary = [...getValues('ciForm.cargoSummary')].filter((material) =>
      material.id ? material.id !== data.id : material._id !== data._id
    )
    const cargoSummary = clonedCargoSummary.map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('ciForm.cargoSummary', cargoSummary)
  }

  return (
    <Grid container spacing={1}>
      {cargoSummary && cargoSummary.map((goods, index) => {
        const isLast = cargoSummary.length === 1
        return (
          <React.Fragment key={goods._id || goods.id || index}>
            <Grid item xs={12}>
              <CargoSummaryEntry
                index={index}
                isLast={isLast}
                onAddCargoSummary={handleAddCargoSummary}
                onDeleteCargoSummary={handleDeleteCargoSummary}
                cargoSummary={goods}
                showNotification={showNotification}
              />
            </Grid>
            {cargoSummary.length !== index + 1 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </React.Fragment>
        )
      })}
    </Grid>
  )
}

function CargoSummaryEntry(props) {
  const { index, isLast, onAddCargoSummary, onDeleteCargoSummary,  cargoSummary, showNotification} = props

  return (
    <Grid container spacing={2}>
    <Grid item xs={12} md>
      <CargoSummaryFormProperties.Fields index={index} showNotification={showNotification} />
    </Grid>
    <Grid item xs={12} md='auto'>
      <Grid container spacing={1}>
        <Grid item xs='auto'>
          <Tooltip placement='bottom' title='Add new'>
            <span>
              <CngIconButton
                icon={['fal', 'plus']}
                onClick={() => onAddCargoSummary(initialValues)}
                size='small'
                type='outlined'
              />
            </span>
          </Tooltip>
        </Grid>
        <Grid item xs='auto'>
          <Tooltip placement='bottom' title='Clone'>
            <span>
              <CngIconButton
                icon={['fal', 'copy']}
                onClick={() => onAddCargoSummary({ ...cargoSummary, id: undefined })}
                size='small'
                type='outlined'
              />
            </span>
          </Tooltip>
        </Grid>
        {!isLast && (
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Delete'>
              <span>
                <CngIconButton
                  icon={['fal', 'trash']}
                  onClick={() => onDeleteCargoSummary(cargoSummary)}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  </Grid>
  )
}

export default CargoSummarySection
