import React, { useRef, useState } from 'react'
import { constants, useTranslation, useServices } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import pathMap from '../../../paths/pathMap'
import SbciInvoiceApiUrls from '../../../apiUrls/SbciInvoiceApiUrls'
import { getStatusMetadata } from '../../../common/NACommon'
import { FileForUserGetPartyId } from '../../../common/FileForUserCommon'
import Table from '../../../components/aciacehighway/Table'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Chip, useTheme } from '@material-ui/core'

const {
  filter: { BETWEEN, EQUAL, IN, LIKE }
} = constants

function TablePage(props) {
  const { history, location, showNotification } = props

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    invoice: null
  })
  const tableRef = useRef()
  const theme = useTheme()
  const { createRecord, deleteRecord, securedSendRequest } = useServices()

  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const id = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.ID)
    const manifestId = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.MANIFEST_ID
    )
    const invoiceNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.INVOICE_NO
    )
    const invoiceDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.INVOICE_DATE
    )
    const entryNo = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.ENTRY_NO)
    const status = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.STATUS)
    const submittedDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.SUBMITTED_DATE
    )
    const createdDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CREATED_DATE
    )
    const updatedDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UPDATED_DATE
    )
    const cloneButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CLONE_BUTTON
    )
    const exportCSV = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.EXPORT_CSV
    )
    const templateName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TEMPLATE_NAME
    )
    const editSentRecords = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.EDIT_SENT_RECORDS
    )
    const editButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.EDIT_BUTTON
    )
    const deleteSentRecords = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.DELETE_SENT_RECORDS
    )
    const deleteButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.DELETE_BUTTON
    )

    return {
      id,
      invoiceNo,
      invoiceDate,
      entryNo,
      manifestId,
      createdDate,
      updatedDate,
      submittedDate,
      templateName,
      status,
      editSentRecords,
      editButton,
      cloneButton,
      exportCSV,
      deleteButton,
      deleteSentRecords
    }
  }

  const columns = [
    {
      field: 'id',
      sortKey: 'id',
      title: translatedTextsObject.id
    },
    {
      field: 'invoiceNo',
      sortKey: 'invoiceNo',
      title: translatedTextsObject.invoiceNo
    },
    {
      field: 'invoiceDate',
      sortKey: 'invoiceDate',
      title: translatedTextsObject.invoiceDate,
      render: (data) =>
        data.invoiceDate && moment(data.invoiceDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'entryNo',
      sortKey: 'entryNo',
      title: translatedTextsObject.entryNo
    },
    {
      field: 'manifestId',
      sortKey: 'manifestId',
      title: translatedTextsObject.manifestId
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (data) =>
        data.createdDate && moment(data.createdDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'updatedDate',
      sortKey: 'updatedDate',
      title: translatedTextsObject.updatedDate,
      render: (data) =>
        data.updatedDate && moment(data.updatedDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'submittedDate',
      sortKey: 'submittedDate',
      title: translatedTextsObject.submittedDate,
      render: (data) =>
        data.submittedDate &&
        moment(data.submittedDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'templateName',
      sortKey: 'templateName',
      title: translatedTextsObject.templateName
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <Chip
            label={status.label}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor
            }}
          />
        )
      }
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.id,
      type: 'textfield',
      name: 'id',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.invoiceNo,
      type: 'textfield',
      name: 'invoiceNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.invoiceDate,
      type: 'daterange',
      name: 'invoiceDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.entryNo,
      type: 'textfield',
      name: 'entryNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.manifestId,
      type: 'textfield',
      name: 'manifestId',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.updatedDate,
      type: 'daterange',
      name: 'updatedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.submittedDate,
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.templateName,
      type: 'textfield',
      name: 'templateName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          fullWidth: true,
          label: 'Draft',
          value: 'draft',
          filterValue: {
            value: 'DR'
          }
        },
        {
          fullWidth: true,
          label: 'Accepted',
          value: 'accepted',
          filterValue: {
            value: 'AC'
          }
        },
        {
          fullWidth: true,
          label: 'Sent',
          value: 'sent',
          filterValue: {
            value: 'ST'
          }
        },
        {
          fullWidth: true,
          label: 'Rejected',
          value: 'rejected',
          filterValue: {
            value: 'RJ'
          }
        },
        {
          fullWidth: true,
          label: 'Incomplete',
          value: 'incomplete',
          filterValue: {
            value: 'IN'
          }
        },
        {
          fullWidth: true,
          label: 'Template',
          value: 'template',
          filterValue: {
            value: 'TP'
          }
        }
      ]
    }
  ]

  function handleCloneInvoice(data) {
    createRecord.execute(
      SbciInvoiceApiUrls.CLONE, data,
      (data) => {
        data.invoiceDate = moment(data.invoiceDate).format('YYYY-MM-DD');

        const partyData = data.tradeParty.map(
          (tradeParty, index) => ({ ...tradeParty, _id: index + 1 })
        )
        data.tradeParty = partyData

        const additionalChargesData = data.additionalCharges.map(
          (additionalCharges, index) => ({ ...additionalCharges, _id: index + 1 })
        )
        data.additionalCharges = additionalChargesData

        const identificationInfoData = data.identificationInfo.map(
          (identificationInfo, index) => ({ ...identificationInfo, _id: index + 1 })
        )
        data.identificationInfo = identificationInfoData

        let ciFormData = data.ciForm
        if (ciFormData !== undefined || ciFormData !== null) {
          const cargoSummaryData = ciFormData.cargoSummary.map(
            (cargoSummary, index) => ({ ...cargoSummary, _id: index + 1 })
          )
          ciFormData.cargoSummary = cargoSummaryData
          data.ciForm = ciFormData
        }

        history.push(pathMap.SBCI_INVOICE_ADD_VIEW, { cloneData: data })
      },
      (error) => console.log(error)
    )
  }

  function handleDeleteInvoice() {
    if (confirmDialog.invoice) {
      deleteRecord.execute(
        SbciInvoiceApiUrls.DELETE,
        confirmDialog.invoice,
        () => {
          showNotification('success', 'Item information deleted permanently.')
          setConfirmDialog({ open: false, invoice: null })

          if (tableRef.current.performRefresh) {
            tableRef.current.performRefresh()
          }
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  function handleExportInvoice(data) {
    securedSendRequest.execute(
      'POST',
      SbciInvoiceApiUrls.DOWNLOAD,
      {
        responseType: 'blob',
        data: JSON.stringify(data)
      },
      (response) => {
        let blob = new Blob([response.data]),
          downloadUrl = window.URL.createObjectURL(blob),
          filename = 'SB-Invoice.csv',
          disposition = response.headers['content-disposition']

        if (disposition && disposition.indexOf('attachment') !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
            matches = filenameRegex.exec(disposition)

          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }

        let a = document.createElement('a')
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl
        } else {
          a.href = downloadUrl
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
      },
      () => showNotification('error', 'File not found.')
    )
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.SBCI_INVOICE_ADD_VIEW)
            },
            label: 'Create invoice'
          }
        ]}
        checkboxSelection
        columns={columns}
        compact
        customRowActions={[
          {
            disabled: (data) => data.status === 'ST',
            iconButtonProps: {
              icon: ['fal', 'pen'],
              style: { color: theme.palette.primary.main }
            },
            onClick: (rowData) =>
              history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (data) =>
              data.status === 'ST'
                ? translatedTextsObject.editSentRecords
                : translatedTextsObject.editButton
          }
        ]}
        exportData={{ url: SbciInvoiceApiUrls.EXPORT }}
        fetch={{ url: SbciInvoiceApiUrls.SEARCH }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) =>
          history.push(`${location.pathname}/view/${rowData.id}`, { cloneData: rowData })
        }
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneInvoice
          },
          {
            label: translatedTextsObject.exportCSV,
            icon: <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />,
            onClick: (data) => handleExportInvoice([data])
          },
          {
            disabled: (data) => data.status !== 'DR' && data.status !== 'TP',
            label: translatedTextsObject.deleteButton,
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            onClick: (data) => setConfirmDialog({ open: true, invoice: data }),
            tooltip: (data) =>
              data.status !== 'DR' && data.status !== 'TP'
                ? translatedTextsObject.deleteSentRecords
                : translatedTextsObject.deleteButton
          }
        ]}
        selectActions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              variant: 'outlined',
              style: { minWidth: 'auto' }
            },
            icon: <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />,
            label: translatedTextsObject.exportCSV,
            onClick: handleExportInvoice
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, invoice: null })}
        onCancel={() => setConfirmDialog({ open: false, invoice: null })}
        onConfirm={handleDeleteInvoice}
        title='Discard'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default TablePage
