import makeValidationSchema from './LaceyActDetailsMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SbciInvoiceKeys from 'src/constants/locale/key/SbciInvoice'
import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  htsNo: "",
  enteredValue: "",
  componentOfArticle: "",
  genusName: "",
  speciesName: "",
  country: "",
  quantity: "",
  uom: "",
  percentOfRecycle: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  index,
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue } = useFormContext()

  function makeTranslatedTextsObject() {
    let htsNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActDetails.HTS_NO
    )
    let enteredValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActDetails.ENTERED_VALUE
    )
    let genusName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActDetails.GENUS_NAME
    )
    let speciesName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActDetails.SPECIES_NAME
    )
    let country = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActDetails.COUNTRY
    )
    let quantity = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActDetails.QUANTITY
    )
    let uom = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActDetails.UOM
    )
    let percentOfRecycle = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActDetails.PERCENT_OF_RECYCLE
    )
    let componentOfArticle = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActDetails.COMPONENT_OF_ARTICLE
    )

    return {
      htsNo,
      enteredValue,
      genusName,
      speciesName,
      country,
      quantity,
      uom,
      percentOfRecycle,
      componentOfArticle,
      country
    }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.htsNo}>
        <CngTextField
          name={typeof index === 'number' ? `laceyActDetails.${index}.htsNo` : 'htsNo'}
          label={translatedTextsObject.htsNo}
          disabled={disabled}
          onChange={(event) => setValue(typeof index === 'number' ? `laceyActDetails.${index}.htsNo` : 'htsNo', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.enteredValue}>
        <CngTextField
          name={typeof index === 'number' ? `laceyActDetails.${index}.enteredValue` : 'enteredValue'}
          label={translatedTextsObject.enteredValue}
          disabled={disabled}
          size='small'
          onChange={(event) => setValue(typeof index === 'number' ? `laceyActDetails.${index}.enteredValue` : 'enteredValue', event.target.value, {shouldValidate:true})}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.genusName}>
        <CngTextField
          name={typeof index === 'number' ? `laceyActDetails.${index}.genusName` : 'genusName'}
          label={translatedTextsObject.genusName}
          disabled={disabled}
          onChange={(event) => setValue(typeof index === 'number' ? `laceyActDetails.${index}.genusName` : 'genusName', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.speciesName}>
        <CngTextField
          name={typeof index === 'number' ? `laceyActDetails.${index}.speciesName` : 'speciesName'}
          label={translatedTextsObject.speciesName}
          disabled={disabled}
          onChange={(event) => setValue(typeof index === 'number' ? `laceyActDetails.${index}.speciesName` : 'speciesName', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.country}>
        <CngTextField
          name={typeof index === 'number' ? `laceyActDetails.${index}.country` : 'country'}
          label={translatedTextsObject.country}
          disabled={disabled}
          onChange={(event) => setValue(typeof index === 'number' ? `laceyActDetails.${index}.country` : 'country', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.quantity}>
        <CngTextField
          name={typeof index === 'number' ? `laceyActDetails.${index}.quantity` : 'quantity'}
          label={translatedTextsObject.quantity}
          disabled={disabled}
          size='small'
          onChange={(event) => setValue(typeof index === 'number' ? `laceyActDetails.${index}.quantity` : 'quantity', event.target.value, {shouldValidate:true})}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.uom}>
        <CngTextField
          name={typeof index === 'number' ? `laceyActDetails.${index}.uom` : 'uom'}
          label={translatedTextsObject.uom}
          disabled={disabled}
          onChange={(event) => setValue(typeof index === 'number' ? `laceyActDetails.${index}.uom` : 'uom', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.percentOfRecycle}>
        <CngTextField
          name={typeof index === 'number' ? `laceyActDetails.${index}.percentOfRecycle` : 'percentOfRecycle'}
          label={translatedTextsObject.percentOfRecycle}
          disabled={disabled}
          onChange={(event) => setValue(typeof index === 'number' ? `laceyActDetails.${index}.percentOfRecycle` : 'percentOfRecycle', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} shouldHide={shouldHideMap?.componentOfArticle}>
        <CngTextField
          name={typeof index === 'number' ? `laceyActDetails.${index}.componentOfArticle` : 'componentOfArticle'}
          label={translatedTextsObject.componentOfArticle}
          disabled={disabled}
          onChange={(event) => setValue(typeof index === 'number' ? `laceyActDetails.${index}.componentOfArticle` : 'componentOfArticle', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
    </Grid>
	)
}

const LaceyActDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default LaceyActDetailsFormProperties
