import makeValidationSchema from './FdaQtyUomMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SbciInvoiceKeys from 'src/constants/locale/key/SbciInvoice'
import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  quantity: '',
  uom: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  index,
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue, trigger } = useFormContext()

  function makeTranslatedTextsObject() {
    let quantity = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaQtyUom.QUANTITY
    )
    let uom = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaQtyUom.UOM
    )

    return { quantity, uom }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.quantity}>
        <CngTextField
          name={typeof index === 'number' ? `fdaQtyUom.${index}.quantity` : 'quantity'}
          label={translatedTextsObject.quantity}
          disabled={disabled}
          size='small'
          onChange={(event) => trigger(typeof index === 'number' ? `fdaQtyUom.${index}.quantity` : 'quantity')}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.uom}>
        <CngTextField
          name={typeof index === 'number' ? `fdaQtyUom.${index}.uom` : 'uom'}
          label={translatedTextsObject.uom}
          disabled={disabled}
          onChange={(event) =>
            setValue(typeof index === 'number' ? `fdaQtyUom.${index}.uom` : 'uom', event.target.value.toUpperCase(), {shouldValidate:true})
          }
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const FdaQtyUomFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default FdaQtyUomFormProperties
