import React from 'react'
import { components, constants } from 'cng-web-lib'
import FormProperties from './lineItem/FormProperties'
import LineItemViewContent from './LineItemViewContent'
import Box from '@material-ui/core/Box'
import { CircularProgress, Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  button: { CngButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function LineItemDialog(props) {
  const {
    getLookupValue,
    isView,
    invoiceId,
    onClose,
    onCreateLineItem,
    onEditLineItem,
    open,
    lineItem,
    showNotification
  } = props
  const { initialValues, makeValidationSchema } = FormProperties.formikProps

  async function handleSubmit(data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (lineItem) {
          onEditLineItem(data)
        } else {
          onCreateLineItem(data)
        }

        resolve()
      }, 500)
    })
  }

  let viewContent = (
    <Box padding={2}>
      <LineItemViewContent data={lineItem} getLookupValue={getLookupValue} />
    </Box>
  )

  return (
    <CngDialog
      customDialogContent={
        lineItem && isView ? (
          viewContent
        ) : (
          <Box padding={2}>
            <CngForm
              fieldLevel='form'
              formikProps={{
                initialValues: {
                  ...(lineItem
                    ? FormProperties.toClientDataFormat(lineItem)
                    : initialValues),
                  invoiceId: invoiceId
                },
                makeValidationSchema: makeValidationSchema,
                onSubmit: handleSubmit
              }}
              formState={FormState.COMPLETED}
              renderBodySection={(labelMap, shouldHideMap) => (
                <FormProperties.Fields
                  showNotification={showNotification}
                  shouldHideMap={shouldHideMap}
                />
              )}
              innerForm
              renderButtonSection={() => (
                <ButtonSection onClose={onClose} onSubmit={handleSubmit} />
              )}
            />
          </Box>
        )
      }
      dialogAction={
        isView ? <CngButton onClick={onClose}>OK</CngButton> : null
      }
      dialogTitle='Line item'
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

function ButtonSection(props) {
  const { onClose, onSubmit } = props

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting }
  } = useFormContext()

  return (
    <Grid container justify='space-between' spacing={2}>
      <Grid item xs='auto'>
        <CngButton color='secondary' disabled={isSubmitting} onClick={reset}>
          Clear all
        </CngButton>
      </Grid>
      <Grid item xs='auto'>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <CngButton disabled={isSubmitting} onClick={onClose}>
              Discard
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton
              disabled={isSubmitting}
              startIcon={isSubmitting && <CircularProgress size={16} />}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </CngButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LineItemDialog
