import makeValidationSchema from './TscaDetailsMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SbciInvoiceKeys from 'src/constants/locale/key/SbciInvoice'
import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  chemicalName: "",
  services: "",
  regNumber: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  index,
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue } = useFormContext()

  function makeTranslatedTextsObject() {
    let chemicalName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TscaDetails.CHEMICAL_NAME
    )
    let services = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TscaDetails.SERVICES
    )
    let regNumber = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TscaDetails.REG_NUMBER
    )

    return {
      chemicalName,
      services,
      regNumber
    }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.chemicalName}>
        <CngTextField
          disabled={disabled}
          label={translatedTextsObject.chemicalName}
          name={typeof index === 'number' ? `tscaDetails.${index}.chemicalName` : 'chemicalName'}
          onChange={(event) => setValue(typeof index === 'number' ? `tscaDetails.${index}.chemicalName` : 'chemicalName', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.services}>
        <CngTextField
          disabled={disabled}
          label={translatedTextsObject.services}
          name={typeof index === 'number' ? `tscaDetails.${index}.services` : 'services'}
          onChange={(event) => setValue(typeof index === 'number' ? `tscaDetails.${index}.services` : 'services', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.regNumber}>
        <CngTextField
          disabled={disabled}
          label={translatedTextsObject.regNumber}
          name={typeof index === 'number' ? `tscaDetails.${index}.regNumber` : 'regNumber'}
          onChange={(event) => setValue(typeof index === 'number' ? `tscaDetails.${index}.regNumber` : 'regNumber', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
    </Grid>
	)
}

const TscaDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TscaDetailsFormProperties
