import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './AddiInfoFormProperties'
import AddiInfoFieldsPlaceholder from './AddiInfoFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'
import LineitemAddiInfoFormProperties from './AddiInfoFormProperties'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function AddiInfoSection({showNotification}) {
  const { getValues, setValue, watch } = useFormContext()

  const lineitemAddiInfo = watch("lineitemAddiInfo")

  function handleAddLineItemAddiInfo(data) {
    const lineitemAddiInfo = [...getValues('lineitemAddiInfo'), data].map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('lineitemAddiInfo', lineitemAddiInfo)
  }

  function handleDeleteLineItemAddiInfo(data) {
    const clonedLineitemAddiInfo = [...getValues('lineitemAddiInfo')].filter((material) =>
      material.id ? material.id !== data.id : material._id !== data._id
    )
    const lineitemAddiInfo = clonedLineitemAddiInfo.map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('lineitemAddiInfo', lineitemAddiInfo, { shouldDirty: true })
  }

  return (
    <Grid container spacing={1}>
    {lineitemAddiInfo.map((goods, index) => {
      const isLast = lineitemAddiInfo.length === 1
      return (
        <React.Fragment key={goods._id || goods.id || index}>
          <Grid item xs={12}>
            <LineItemAddiInfoEntry
              index={index}
              isLast={isLast}
              onAddLineItemAddiInfo={handleAddLineItemAddiInfo}
              onDeleteLineItemAddiInfo={handleDeleteLineItemAddiInfo}
              lineitemAddiInfo={goods}
              showNotification={showNotification}
            />
          </Grid>
          {lineitemAddiInfo.length !== index + 1 && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
        </React.Fragment>
      )
    })}
  </Grid>
  )
}

function LineItemAddiInfoEntry(props) {
  const { index, isLast, onAddLineItemAddiInfo, onDeleteLineItemAddiInfo, lineitemAddiInfo, showNotification } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md>
        <LineitemAddiInfoFormProperties.Fields index={index} showNotification={showNotification} />
      </Grid>
      <Grid item xs={12} md='auto'>
        <Grid container spacing={1}>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Add new'>
              <span>
                <CngIconButton
                  icon={['fal', 'plus']}
                  onClick={() => onAddLineItemAddiInfo(initialValues)}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Clone'>
              <span>
                <CngIconButton
                  icon={['fal', 'copy']}
                  onClick={() => onAddLineItemAddiInfo({ ...lineitemAddiInfo, id: undefined })}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          {!isLast && (
            <Grid item xs='auto'>
              <Tooltip placement='bottom' title='Delete'>
                <span>
                  <CngIconButton
                    icon={['fal', 'trash']}
                    onClick={() => onDeleteLineItemAddiInfo(lineitemAddiInfo)}
                    size='small'
                    type='outlined'
                  />
                </span>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AddiInfoSection
