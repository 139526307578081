import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import CargoSummaryMakeValidationSchema from '../invoice/CargoSummaryMakeValidationSchema'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  const errMsgMaxLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  const errMsgAlphaNumericNoSpecialChar = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_NO_SPECIAL_CHAR
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object()
    .shape({
      partyBilled: Yup.string().nullable(),
      shipmentDate: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.date().validFormat().typeError(dateTypeErrorMessage)
      ),
      arrivalDatetime: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.date().validFormat().typeError(dateTypeErrorMessage)
      ),
      countryOfExport: Yup.string().nullable(),
      exportStateCode: Yup.string().nullable(),
      destinationCountry: Yup.string().nullable(),
      destinationStateCode: Yup.string().nullable(),
      transportTermCode: Yup.string().nullable(),
      shipperRegNo: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(50, errMsgMaxLength + ' 50'),
      transactionParties: Yup.string().nullable(),
      ffdPurchasePrice: Yup.string().nullable(),
      fbiPurchasePrice: Yup.string().nullable(),
      bfiPurchasePrice: Yup.string().nullable(),
      dfiPurchasePrice: Yup.string().nullable(),
      goodsSold: Yup.string().nullable(),
      markPackLoad: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(80, errMsgMaxLength + ' 80'),
      termsOfPayment: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(80, errMsgMaxLength + ' 80'),
      reasonForExport: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(70, errMsgMaxLength + ' 70'),
      naftaDeclaration: Yup.string().nullable(),
      tscaDeclaration: Yup.string().nullable(),
      usProforma: Yup.string().nullable(),
      usGoods: Yup.string().nullable(),
      usShipExp: Yup.string().nullable(),
      usGoods10219: Yup.string().nullable(),
      fdaSub: Yup.string().nullable(),
      declNewStmt: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(250, errMsgMaxLength + ' 250'),
      cargoSummary: Yup.array(
        CargoSummaryMakeValidationSchema(translate)
      ).nullable()
    })
    .nullable()
}

export default makeValidationSchema
