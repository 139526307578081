import React, { useContext, useState } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'
import LocalTable from '../../../../components/aciacehighway/LocalTable'
import LineItemDialog from '../LineItemDialog'
import { InvoiceLookupsContext } from '../../lookups/InvoiceLookupsContext'
import { Card, CardHeader } from '@material-ui/core'

function LineItemTable(props) {
  const { data } = props

  const [dialog, setDialog] = useState({ open: false, lineItem: null })
  const { getLookupValue } = useContext(InvoiceLookupsContext)
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const lineItem = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.TITLE
    )
    const partNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.PART_NO
    )
    const partDesc = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.PART_DESC
    )
    const htsCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.HTS_CODE
    )
    const qty = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.LineItem.QTY)
    const unitValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.UNIT_VALUE
    )
    const grossWeight = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.GROSS_WT
    )
    const netWeight = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.NET_WT
    )

    return {
      lineItem,
      partNo,
      partDesc,
      htsCode,
      qty,
      unitValue,
      grossWeight,
      netWeight
    }
  }

  const columns = [
    {
      field: 'partNo',
      sortKey: 'partNo',
      title: translatedTextsObject.partNo,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'partDesc',
      sortKey: 'partDesc',
      title: translatedTextsObject.partDesc,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'htsCode',
      sortKey: 'htsCode',
      title: translatedTextsObject.htsCode,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'qty',
      sortKey: 'qty',
      title: translatedTextsObject.qty,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'unitValue',
      sortKey: 'unitValue',
      title: translatedTextsObject.unitValue,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'grossWt',
      sortKey: 'grossWt',
      title: translatedTextsObject.grossWeight,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'netWt',
      sortKey: 'netWt',
      title: translatedTextsObject.netWeight,
      filterConfig: { type: 'textfield' }
    }
  ]

  return (
    <>
      <Card variant='outlined'>
        <CardHeader
          title={translatedTextsObject.lineItem}
          titleTypographyProps={{ style: { fontWeight: 600 } }}
        />
        <LocalTable
          columns={columns}
          data={data}
          onRowClick={(data) => setDialog({ open: true, lineItem: data })}
        />
      </Card>
      <LineItemDialog
        getLookupValue={getLookupValue}
        isView
        onClose={() => setDialog({ open: false, lineItem: null })}
        open={dialog.open}
        lineItem={dialog.lineItem}
      />
    </>
  )
}

export default LineItemTable
