import { Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'

function makeValidationSchema(translate) {
  let errMsgMaxLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  let errMsgNumeric = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_NUMERIC
  )

  let regexAlphaNumeric= "^[a-zA-Z0-9 ,+\\-$#@%&()!'`^_:;.?\\[\\]\\{\\}\\/=\"\\|\\\\ ]+$"
  let errMsgAlphaNumeric= "Allowed special characters are  ,+-$#@%&()!'`^_:;.?[]{}/=\"|\\"

  return Yup.object()
    .nullable()
    .shape({
      htsNo: Yup.string()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(15, errMsgMaxLength + ' 15'),
      enteredValue: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(/^\d{0,15}(\.\d{0,15})?$/, errMsgNumeric)
              .nullable()
              .max(15, errMsgMaxLength + ' 15')
      ),
      componentOfArticle: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(40, errMsgMaxLength + ' 40'),
      genusName: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(40, errMsgMaxLength + ' 40'),
      speciesName: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(40, errMsgMaxLength + ' 40'),
      country: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(30, errMsgMaxLength + ' 30'),
      quantity: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(/^\d{0,15}(\.\d{0,15})?$/, errMsgNumeric)
              .nullable()
              .max(15, errMsgMaxLength + ' 15')
      ),
      uom: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(10, errMsgMaxLength + ' 10'),
      percentOfRecycle: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.string()
              .nullable()
              .matches(/^\d{0,5}(\.\d{0,5})?$/, errMsgNumeric)
              .nullable()
              .max(5, errMsgMaxLength + ' 5')
      )
    })
}

export default makeValidationSchema
