import React, { useRef } from 'react'
import { components,useTranslation } from 'cng-web-lib'
import FormProperties from './IdentificationInfoFormProperties'
import IdentificationInfoFieldsPlaceholder from './IdentificationInfoFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'
import Namespace from '../../../constants/locale/Namespace'
import useCustomYupValidationResolver from '../../../components/aciacehighway/hooks/useCustomYupValidationResolver'
import IdentificationInfoFormProperties from './IdentificationInfoFormProperties'

const {
  button: { CngButton, CngIconButton }
} = components

function IdentificationInfoSection(props) {
  const {showNotification } = props
  const { getValues, setValue, watch } = useFormContext()

  const identificationInfo = watch('identificationInfo')

  function handleAddIdentificationInfo(data) {
    const identificationInfo = [...getValues('identificationInfo'), data].map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('identificationInfo', identificationInfo)
  }

  function handleDeleteIdentificationInfo(data) {

    const clonedIdentificationInfo = [...getValues('identificationInfo')].filter((material) =>
      material.id ? material.id !== data.id : material._id !== data._id
    )
    const identificationInfo = clonedIdentificationInfo.map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('identificationInfo', identificationInfo, { shouldDirty: true })
  }

  return (
   
    <Grid container spacing={1}>
                {identificationInfo.map((goods, index) => {
                  const isLast = identificationInfo.length === 1
                  return (
                    <React.Fragment key={goods._id || goods.id || index}>
                      <Grid item xs={12}>
                        <IdentificationInfoEntry
                          index={index}
                          isLast={isLast}
                          onAddIdentificationInfo={handleAddIdentificationInfo}
                          onDeleteIdentificationInfo={handleDeleteIdentificationInfo}
                          identificationInfo={goods}
                          showNotification={showNotification}
                        />
                      </Grid>
                      {identificationInfo.length !== index + 1 && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                    </React.Fragment>
                  )
                })}
              </Grid>
  )
}

function IdentificationInfoEntry(props) {
  const { index, isLast, onAddIdentificationInfo, onDeleteIdentificationInfo, identificationInfo, showNotification } = props

  const { initialValues } = IdentificationInfoFormProperties.formikProps

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md>
        <IdentificationInfoFormProperties.Fields index={index} showNotification={showNotification} />
      </Grid>
      <Grid item xs={12} md='auto'>
        <Grid container spacing={1}>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Add new'>
              <span>
                <CngIconButton
                  icon={['fal', 'plus']}
                  onClick={() => onAddIdentificationInfo(initialValues)}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Clone'>
              <span>
                <CngIconButton
                  icon={['fal', 'copy']}
                  onClick={() => onAddIdentificationInfo({ ...identificationInfo, id: undefined })}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          {!isLast && (
            <Grid item xs='auto'>
              <Tooltip placement='bottom' title='Delete'>
                <span>
                  <CngIconButton
                    icon={['fal', 'trash']}
                    onClick={() => onDeleteIdentificationInfo(identificationInfo)}
                    size='small'
                    type='outlined'
                  />
                </span>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default IdentificationInfoSection
