import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import NaCodeMaintenanceApiUrls from '../../../apiUrls/NaCodeMaintenanceApiUrls'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL },
  CodeMaintenanceType
} = constants

const ManifestLookupsContext = React.createContext()

const ManifestLookupsConsumer = ManifestLookupsContext.Consumer

const ManifestLookupsProvider = ({ children }) => {
  const [lookups, setLookups] = useState(null)
  const { fetchRecords } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    fetchRecords.enableSessionCache()

    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'SBCI_TRANSPORT_MODE' }
      ]),
      fetchRecords.execute(
        NaCodeMaintenanceApiUrls.GET,
        {
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'set_indicator', operator: EQUAL, value: 'AMS' }
          ],
          customData: { codeMType: 'FirmCode' }
        },
        (res) => res.content
      ),
      fetchRecords.execute(
        NaCodeMaintenanceApiUrls.GET,
        {
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'set_indicator', operator: EQUAL, value: 'IFX_US' }
          ],
          customData: { codeMType: 'DocPortCode' }
        },
        (res) => res.content
      )
    ]).then(([transportMode, firmsCodes, ports]) => {
      const firmsCode = {}
      const port = {}

      firmsCodes.forEach((item) => {
        firmsCode[item.code] = item.descriptionEn
      })

      ports.forEach((item) => {
        port[item.code] = item.descriptionEn
      })

      setLookups({ transportMode, firmsCode, port })
    })
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  return (
    <ManifestLookupsContext.Provider value={{ lookups, getLookupValue }}>
      {children}
    </ManifestLookupsContext.Provider>
  )
}

export default ManifestLookupsProvider

export { ManifestLookupsContext, ManifestLookupsConsumer }
