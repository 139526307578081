import makeValidationSchema from './FdaAffirmationMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'
import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngCodeMasterAutocompleteField, CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  affirmationCode: '',
  affirmationQualifier: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  index,
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue } = useFormContext()

  function makeTranslatedTextsObject() {
    let affirmationCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaAffirmation.AFFIRMATION_CODE
    )
    let affirmationQualifier = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaAffirmation.AFFIRMATION_QUALIFIER
    )

    return {
      affirmationCode,
      affirmationQualifier
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.affirmationCode}>
        <CngCodeMasterAutocompleteField
          codeType='SBCI_AFFIRMATION_CODE'
          name={typeof index === 'number' ? `fdaAffirmation.${index}.affirmationCode` : 'affirmationCode'}
          label={translatedTextsObject.affirmationCode}
          disabled={disabled}
          size='small'
          onChange={(_, option) =>
            setValue(typeof index === 'number' ? `fdaAffirmation.${index}.affirmationCode` : 'affirmationCode', option ? option.value : '', {shouldValidate:true, shouldDirty:true})
          }
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.affirmationQualifier}>
        <CngTextField
          name={typeof index === 'number' ? `fdaAffirmation.${index}.affirmationQualifier` : 'affirmationQualifier'}
          label={translatedTextsObject.affirmationQualifier}
          disabled={disabled}
          onChange={(event) => setValue(typeof index === 'number' ? `fdaAffirmation.${index}.affirmationQualifier` : 'affirmationQualifier', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const FdaAffirmationFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default FdaAffirmationFormProperties
