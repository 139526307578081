import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import CiFormMakeValidationSchema from '../invoice/CiFormMakeValidationSchema'
import IdentificationInfoMakeValidationSchema from '../invoice/IdentificationInfoMakeValidationSchema'
import AdditionalChargesMakeValidationSchema from '../invoice/AdditionalChargesMakeValidationSchema'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  const errMsgNumeric = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_NUMERIC
  )
  const errMsgMinLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MIN_LENGTH
  )
  const errMsgMaxLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  const errMsgAlphaNumericNoSpecialChar = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_NO_SPECIAL_CHAR
  )
  const errMsgDecimal1 = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_DECIMAL1
  )
  const errMsgDecimal2 = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_DECIMAL2
  )
  const errMsgDecimal3 = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_DECIMAL3
  )

  const regexNumeric = '^[0-9]*$'
  const regexAlphaNumericNoSpecialChar = '^[a-zA-Z0-9 ]*$'
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object({
    id: Yup.string().nullable(),
    manifestId: Yup.string().nullable(),
    corpid: Yup.string().nullable(),
    invoiceNo: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
      .max(15, errMsgMaxLength + ' 15'),
    invoiceDate: Yup.lazy((value) =>
      value === '' || value === null || value === undefined
        ? Yup.string().nullable().required(requiredMessage)
        : Yup.date().validFormat().typeError(dateTypeErrorMessage)
    ),
    invoiceType: Yup.string().nullable().required(requiredMessage),
    entryType: Yup.string().nullable().required(requiredMessage),
    entryNo: Yup.string()
      .nullable()
      .when('entryType', (entryType) => {
        if (entryType == '01' || entryType == '06' || entryType == '11') {
          return Yup.string()
            .nullable()
            .required(requiredMessage)
            .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
            .min(11, errMsgMinLength + ' 11')
            .max(11, errMsgMaxLength + ' 11')
        } else {
          if (entryType == '78') {
            return Yup.string()
              .nullable()
              .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
              .min(11, errMsgMinLength + ' 11')
              .max(11, errMsgMaxLength + ' 11')
          } else {
            return Yup.string()
              .nullable()
              .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
              .min(9, errMsgMinLength + ' 9')
          }
        }
      }),
    clientCode: Yup.string().nullable(),
    portLadingCode: Yup.string().nullable().required(requiredMessage),
    portOfDestination: Yup.string().nullable().required(requiredMessage),
    header: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
      .max(70, errMsgMaxLength + ' 70'),
    footer: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
      .max(70, errMsgMaxLength + ' 70'),
    freightCharge: Yup.string()
      .required(requiredMessage)
      .matches(regexNumeric, errMsgNumeric)
      .nullable()
      .max(8, errMsgMaxLength + ' 8'),
    carOrTrailerNo: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
      .max(15, errMsgMaxLength + ' 15'),
    carrierCode: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
      .max(4, errMsgMaxLength + ' 4'),
    totalValue: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable().required(requiredMessage)
        : Yup.string()
            .nullable()
            .required(requiredMessage)
            .matches(
              /^\d{0,10}(\.\d{0,2})?$/,
              errMsgDecimal1 + ' 10 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
            )
            .max(13, errMsgMaxLength + ' 13')
    ),
    grossWeight: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable().required(requiredMessage)
        : Yup.string()
            .nullable()
            .required(requiredMessage)
            .matches(
              /^\d{0,8}(\.\d{0,2})?$/,
              errMsgDecimal1 + ' 8 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
            )
            .max(11, errMsgMaxLength + ' 11')
    ),
    netWeight: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable().required(requiredMessage)
        : Yup.string()
            .nullable()
            .required(requiredMessage)
            .matches(
              /^\d{0,8}(\.\d{0,2})?$/,
              errMsgDecimal1 + ' 8 ' + errMsgDecimal2 + ' 2 ' + errMsgDecimal3
            )
            .max(11, errMsgMaxLength + ' 11')
    ),
    description: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
      .max(30, errMsgMaxLength + ' 30'),
    ctpInitiative: Yup.string().nullable(),
    status: Yup.string().nullable(),
    submissionType: Yup.string().nullable(),
    shipmentQty: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable()
        : Yup.string()
            .matches(regexNumeric, errMsgNumeric)
            .nullable()
            .max(8, errMsgMaxLength + ' 8')
    ),
    templateFlag: Yup.boolean().nullable(),
    templateName: Yup.string()
      .nullable()
      .when('status', (status) => {
        if (status == 'TP') {
          return Yup.string()
            .max(50, errMsgMaxLength + ' 50')
            .required(requiredMessage)
            .matches(
              regexAlphaNumericNoSpecialChar,
              errMsgAlphaNumericNoSpecialChar
            )
        } else {
          return Yup.string()
            .when('templateFlag', {
              is: true,
              then: Yup.string()
                .max(1, errMsgMaxLength + ' 1')
                .max(50, errMsgMaxLength + ' 50')
                .required(requiredMessage)
                .matches(
                  regexAlphaNumericNoSpecialChar,
                  errMsgAlphaNumericNoSpecialChar
                )
            })
            .max(50, errMsgMaxLength + ' 50')
            .matches(
              regexAlphaNumericNoSpecialChar,
              errMsgAlphaNumericNoSpecialChar
            )
            .nullable()
        }
      }),
    cloneLineItemKey: Yup.string().nullable(),
    tradeParty: Yup.array().nullable(),
    identificationInfo: Yup.array(
      IdentificationInfoMakeValidationSchema(translate)
    ).nullable(),
    additionalCharges: Yup.array(
      AdditionalChargesMakeValidationSchema(translate)
    ).nullable(),
    ciForm: CiFormMakeValidationSchema(translate),
    bolForm: Yup.object().nullable(),
    tscaForm: Yup.object().nullable(),
    naftaForm: Yup.object().nullable(),
    packingListForm: Yup.object().nullable(),
    laceyActForm: Yup.object().nullable()
  })
}

export default makeValidationSchema
