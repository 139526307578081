import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  const errMsgMaxLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  const errMsgMinLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MIN_LENGTH
  )
  const errMsgEmail = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_EMAIL
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Allowed special characters are  ,+-$#@%&()!\'`^_:;.?[]{}/="|\\'

  return Yup.object({
    saveFlag: Yup.boolean().nullable(),
    userid: Yup.string().nullable(),
    partyIdn: Yup.string().when('saveFlag', (saveFlag) =>
      saveFlag
        ? Yup.string()
            .min(1, errMsgMinLength + ' 1')
            .max(35, errMsgMaxLength + ' 35')
            .matches(regexAlphaNumeric, errMsgAlphaNumeric)
            .required(requiredMessage)
        : Yup.string().nullable()
    ),
    partyName: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(39, errMsgMaxLength + ' 39'),
    partyType: Yup.string().nullable().required(requiredMessage),
    upsClientId: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(6, errMsgMaxLength + ' 6'),
    mid: Yup.string()
      .nullable()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(15, errMsgMaxLength + ' 15'),
    irs: Yup.string().when('partyType', (partyType) => partyType == 'SH' ? 
      Yup.string().nullable() :
      Yup.string().nullable().required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(15, errMsgMaxLength + ' 15')
    ),
    division: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(30, errMsgMaxLength + ' 30'),
    locPortCode: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(5, errMsgMaxLength + ' 5'),
    poBoxNo: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(30, errMsgMaxLength + ' 30'),
    address: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(35, errMsgMaxLength + ' 35'),
    city: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(20, errMsgMaxLength + ' 20'),
    stateCode: Yup.string().nullable(),
    countryCode: Yup.string().nullable().required(requiredMessage),
    postalCode: Yup.string()
      .nullable()
      .when('countryCode', (countryCode) => {
        if (countryCode == 'CA' || countryCode == 'US') {
          return Yup.string()
            .nullable()
            .required(requiredMessage)
            .matches(regexAlphaNumeric, errMsgAlphaNumeric)
            .max(9, errMsgMaxLength + ' 9')
        } else {
          return Yup.string()
            .nullable()
            .matches(regexAlphaNumeric, errMsgAlphaNumeric)
            .max(9, errMsgMaxLength + ' 9')
        }
      }),
    telphNum: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(30, errMsgMaxLength + ' 30'),
    contactFax: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(35, errMsgMaxLength + ' 35'),
    contactEmail: Yup.string()
      .nullable()
      .max(35, errMsgMaxLength + ' 35')
      .email(errMsgEmail),
    taxIdNumber: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(80, errMsgMaxLength + ' 80'),
    contactName: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(35, errMsgMaxLength + ' 35')
  })
}

export default makeValidationSchema
