import { Yup } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'

function makeValidationSchema(translate) {
  const errMsgMaxLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  const errMsgAlphaNumeric = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object()
    .nullable()
    .shape({
      affirmationCode: Yup.string().nullable(),
      affirmationQualifier: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(25, errMsgMaxLength + ' 25')
    })
}

export default makeValidationSchema
