import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  const errMsgMaxLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  const errMsgAlphaNumeric = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object().shape({
    additionalInfoType: Yup.string().nullable().required(requiredMessage),
    additionalInfoNo: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(35, errMsgMaxLength + ' 35')
  })
}

export default makeValidationSchema
