import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  const errMsgMaxLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  const errMsgAlphaNumericNoSpecialChar = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_NO_SPECIAL_CHAR
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object()
    .nullable()
    .shape({
      otherRefNo: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(50, errMsgMaxLength + ' 50'),
      responsibleForCustoms: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(50, errMsgMaxLength + ' 50'),
      instructionsToBroker: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(3, errMsgMaxLength + ' 3'),
      containerized: Yup.string().nullable(),
      flightDetails: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(50, errMsgMaxLength + ' 50'),
      specialInstructions: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(200, errMsgMaxLength + ' 200'),
      placeOfDelivery: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(100, errMsgMaxLength + ' 100'),
      portOfLoading: Yup.string().nullable(),
      billChargesTo: Yup.string().nullable(),
      dateShipped: Yup.lazy((value) =>
        value === '' || value === null
          ? Yup.string().nullable()
          : Yup.date().validFormat().typeError(dateTypeErrorMessage)
      )
    })
}

export default makeValidationSchema
