import makeValidationSchema from './BolFormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SbciInvoiceKeys from 'src/constants/locale/key/SbciInvoice'
import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  containerNo: "",
  freightCharges: "",
  placeOfLading: "",
  deliveryInstructions: "",
  codAmount: "",
  shipmentDeclareValue: "",
  releaseValue: "",
  chargeTo: "",
  totShpmntCubicFt: "",
  freightClass: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue, trigger } = useFormContext()

  function makeTranslatedTextsObject() {
    let containerNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.BolForm.CONTAINER_NO
    )
    let freightCharges = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.BolForm.FREIGHT_CHARGES
    )
    let placeOfLading = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.BolForm.PLACE_OF_LADING
    )
    let deliveryInstructions = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.BolForm.DELIVERY_INSTRUCTIONS
    )
    let codAmount = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.BolForm.COD_AMOUNT
    )
    let shipmentDeclareValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.BolForm.SHIPMENT_DECLARE_VALUE
    )
    let releaseValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.BolForm.RELEASE_VALUE
    )
    let chargeTo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.BolForm.CHARGE_TO
    )
    let totShpmntCubicFt = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.BolForm.TOT_SHPMNT_CUBIC_FT
    )
    let freightClass = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.BolForm.FREIGHT_CLASS
    )

    return {
      containerNo,
      freightCharges,
      placeOfLading,
      deliveryInstructions,
      codAmount,
      shipmentDeclareValue,
      releaseValue,
      chargeTo,
      totShpmntCubicFt,
      freightClass
    }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.containerNo}>
        <CngTextField
          disabled={disabled}
          name='containerNo'
          label={translatedTextsObject.containerNo}
          onChange={(event) =>
            setValue('containerNo', event.target.value.toUpperCase(), {shouldValidate:true})
          }
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.codAmount}>
        <CngTextField
          disabled={disabled}
          name='codAmount'
          label={translatedTextsObject.codAmount}
          size='small'
          onChange={(event) => trigger('codAmount')}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.freightCharges}>
        <CngCodeMasterAutocompleteField
          name='freightCharges'
          label={translatedTextsObject.freightCharges}
          disabled={disabled}
          codeType='SBCI_FREIGHT_CHARGES'
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.shipmentDeclareValue}>
        <CngTextField
          disabled={disabled}
          name='shipmentDeclareValue'
          label={translatedTextsObject.shipmentDeclareValue}
          size='small'
          onChange={(event) => trigger('shipmentDeclareValue')}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.placeOfLading}>
        <CngTextField
          disabled={disabled}
          name='placeOfLading'
          label={translatedTextsObject.placeOfLading}
          onChange={(event) => setValue('placeOfLading', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.releaseValue}>
        <CngTextField
          disabled={disabled}
          name='releaseValue'
          label={translatedTextsObject.releaseValue}
          size='small'
          onChange={(event) => trigger('releaseValue')}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.deliveryInstructions}>
        <CngTextField
          disabled={disabled}
          name='deliveryInstructions'
          label={translatedTextsObject.deliveryInstructions}
          onChange={(event) => setValue('deliveryInstructions', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.chargeTo}>
        <CngTextField
          disabled={disabled}
          name='chargeTo'
          label={translatedTextsObject.chargeTo}
          onChange={(event) => setValue('chargeTo', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.totShpmntCubicFt}>
        <CngTextField
          disabled={disabled}
          name='totShpmntCubicFt'
          label={translatedTextsObject.totShpmntCubicFt}
          onChange={(event) => setValue('totShpmntCubicFt', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.freightClass}>
        <CngTextField
          disabled={disabled}
          helperText='If other than Shipper or Consignee'
          name='freightClass'
          label={translatedTextsObject.freightClass}
          onChange={(event) => setValue('freightClass', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
    </Grid>
	)
}

const BolFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default BolFormFormProperties
