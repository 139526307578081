import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { components, useServices } from 'cng-web-lib'
import SbciInvoiceApiUrls from '../../../apiUrls/SbciInvoiceApiUrls'
import pathMap from '../../../paths/pathMap'
import InvoiceLookupsProvider from '../lookups/InvoiceLookupsContext'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import Stepper from '../../../components/aciacehighway/Stepper'
import FormProperties from './FormProperties'
import LineItemTable from './LineItemTable'
import _ from 'lodash'
import { Grid, Box, Card, useTheme } from '@material-ui/core'
import { FileForUserGetUserDetails} from 'src/common/FileForUserCommon'
import { format } from 'date-fns'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function EditPage(props) {

  const {
    securedSendRequest
  } = useServices()

  const { history, loading, onSetLoading, showNotification } = props

  const { state } = history.location
  const [currentStep, setCurrentStep] = useState(state?.step ?? 0)
  const onSuccessCallback = useRef(() => {})
  const { id } = useParams()
  const theme = useTheme()

  function handleDiscard(){

    if (state?.manifestId) {
      history.push(`${pathMap.SBCI_MANIFEST}/edit/` + state.manifestId)
    }else{
      history.push(pathMap.SBCI_INVOICE_LIST_VIEW)
    }
  }

  function handlePrintAll() {
    function onSuccess(response) {
      let blob = new Blob([response.data]);

      let curDate = format(new Date(), 'yyyyMMddHHmmss');

      let filename = "SBCIInvoice_" + curDate + ".zip";
      

      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
    }

    function onError(error) {
      console.error(error);
    }

    function onComplete() {
    }

    const config = {
      responseType: 'blob'
    }

    let data = {"id":id}

    securedSendRequest.execute('POST', SbciInvoiceApiUrls.PRINT_ALL, data, onSuccess, onError, onComplete, config, null);

  }

  function renderContent(step) {
    switch (step) {
      case 0:
        return (
          <CngEditForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
              <FormProperties.Fields
                disabled={false}
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
                onSetLoading={onSetLoading}
              />
            )}
            formikProps={FormProperties.formikProps}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            fetch={{ url: SbciInvoiceApiUrls.GET }}
            update={{
              url: SbciInvoiceApiUrls.PUT,
              onPostSubmitSuccess: (e) => {
                if (!_.isEmpty(e.errorMessages)) {
                  e.errorMessages.forEach((error) =>
                    showNotification('error', error)
                  )
                } else {
                  onSuccessCallback.current()
                }
                let fileForUserDetails = FileForUserGetUserDetails();
                if (fileForUserDetails != null && fileForUserDetails != undefined) {
                  e.fileForUserId = fileForUserDetails.fileForUserId;
                  e.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
                  e.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
                }
              }
            }}
            id={id}
            renderButtonSection={(_, loading) => (
              <InvoiceEditPageButtonSection
                currentStep={currentStep}
                history={history}
                loading={loading}
                onSaveAndContinue={() => {
                  onSuccessCallback.current = () => {
                    setCurrentStep((prev) => prev + 1)
                    onSetLoading(false)
                  }
                }}
                onSetLoading={onSetLoading}
                onDiscard={handleDiscard}
                state={state}
                onPrintAll={handlePrintAll}
              />
            )}
          />
        )
      case 1:
        return (
          <>
            <LineItemTable invoiceId={id} showNotification={showNotification} />
            <Box mt={3}>
              <InvoiceEditPageButtonSection
                currentStep={currentStep}
                history={history}
                loading={loading}
                onBack={() => setCurrentStep((prev) => prev - 1)}
                onSetLoading={onSetLoading}
                onDiscard={handleDiscard}
                state={state}
                onPrintAll={handlePrintAll}
              />
            </Box>
          </>
        )
    }
  }

  return (
    <>
      <Card>
        <Stepper
          activeStep={currentStep}
          onSetActiveStep={(step) => setCurrentStep(step)}
          steps={['Provide details', 'Add invoice lines']}
        />
        <Box p={2}>
          <InvoiceLookupsProvider>
            {renderContent(currentStep)}
          </InvoiceLookupsProvider>
        </Box>
      </Card>
    </>
  )
}

function InvoiceEditPageButtonSection(props) {
  const {
    currentStep,
    history,
    loading,
    onBack,
    onSaveAndContinue,
    onSetLoading,
    onDiscard,
    state,
    onPrintAll
  } = props

  const [confirmDialog, setConfirmDialog] = useState(false)

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            {state?.manifestId ? "Back to Manifest" : "Back to Invoice List"}
          </CngButton>
          
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
         
            {currentStep === 0 ? (
              <>
               <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    disabled={loading}
                    onClick={onPrintAll}
                    size='medium'
                  >
                    Print All Forms
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    disabled={loading}
                    type='submit'
                    size='medium'
                  >
                    Save as draft
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    disabled={loading}
                    type='submit'
                    onClick={onSaveAndContinue}
                    size='medium'
                  >
                    Save &amp; continue
                  </CngButton>
                </Grid>
              </>
            ) : (
                <>
                  <Grid item xs='auto'>
                    <CngButton
                      color='primary'
                      disabled={loading}
                      onClick={onPrintAll}
                      size='medium'
                    >
                      Print All Forms
                  </CngButton>
                  </Grid>
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  disabled={loading}
                  onClick={onBack}
                  size='medium'
                >
                  Back to Invoice Details
                </CngButton>
              </Grid>
                </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={onDiscard}
        title='Discard'
      >
        All progress in this session will be lost and cant't be restored. Are
        you sure about this?
      </AlertDialog>
    </>
  )
}

export default withFormState(EditPage)
