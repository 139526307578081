import makeValidationSchema from './IdentificationInfoMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SbciInvoiceKeys from 'src/constants/locale/key/SbciInvoice'
import Grid from '@material-ui/core/Grid'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  identificationType: "",
  identificationNumber: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap, index }) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue, getValues } = useFormContext()

  function makeTranslatedTextsObject() {
    const identificationType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.IdentificationInfo.IDENTIFICATION_TYPE
    )
    const identificationNumber = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.IdentificationInfo.IDENTIFICATION_NUMBER
    )

    return { identificationType, identificationNumber }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.identificationType}>
        <CngCodeMasterAutocompleteField
          name={typeof index === 'number' ? `identificationInfo.${index}.identificationType` : 'identificationType'}
          label={translatedTextsObject.identificationType}
          disabled={disabled}
          codeType='SBCI_IDENTIFICATION_TYPE'
          required
          size='small'
          key={getValues(`identificationInfo.${index}.identificationType`)}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.identificationNumber}>
        <CngTextField
          name={typeof index === 'number' ? `identificationInfo.${index}.identificationNumber` : 'identificationNumber'}
          label={translatedTextsObject.identificationNumber}
          disabled={disabled}
          onChange={(event) => 
            setValue(typeof index === 'number' ? `identificationInfo.${index}.identificationNumber` : 'identificationNumber', event.target.value.toUpperCase(), {
            shouldValidate: true
          })}
          required
          size='small'
        />
      </CngGridItem>
    </Grid>
	)
}

const IdentificationInfoFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default IdentificationInfoFormProperties
