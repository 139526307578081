import { Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'

function makeValidationSchema(translate) {
  const errMsgMaxLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  const errMsgAlphaNumericNoSpecialChar = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_NO_SPECIAL_CHAR
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object()
    .nullable()
    .shape({
      chemicalName: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(50, errMsgMaxLength + ' 50'),
      services: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(50, errMsgMaxLength + ' 50'),
      regNumber: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
        .max(50, errMsgMaxLength + ' 50')
    })
}

export default makeValidationSchema
