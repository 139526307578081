import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import LaceyActDetailsMakeValidationSchema from './LaceyActDetailsMakeValidationSchema'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  const errMsgMaxLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  const errMsgAlphaNumericNoSpecialChar = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_NO_SPECIAL_CHAR
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object({
    containerNo: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
      .max(50, errMsgMaxLength + ' 50'),
    laceyDate: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable()
        : Yup.date().validFormat().typeError(dateTypeErrorMessage)
    ),
    printName: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
      .max(50, errMsgMaxLength + ' 50'),
    estimatedArrivalDate: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable()
        : Yup.date().validFormat().typeError(dateTypeErrorMessage)
    ),
    descOfMerchandise: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumericNoSpecialChar)
      .max(100, errMsgMaxLength + ' 100'),
    laceyActDetails: Yup.array(
      LaceyActDetailsMakeValidationSchema(translate)
    ).nullable()
  })
}

export default makeValidationSchema
