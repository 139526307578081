import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './LaceyActDetailsFormProperties'
import LaceyActDetailFieldsPlaceholder from './LaceyActDetailFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function LaceyActDetailsSection(props) {
  const { laceyActDetails = [] } = props

  const generatedIndex = useRef(0)
  const { getValues, setValue } = useFormContext()

  function handleAddLaceyActDetail(data) {
    const laceyActDetails = [...getValues('laceyActDetails'), data]

    setValue('laceyActDetails', laceyActDetails, { shouldDirty: true })
  }

  function handleDeleteLaceyActDetail(index) {
    const laceyActDetails = [...getValues('laceyActDetails')]

    laceyActDetails.splice(index, 1)

    setValue('laceyActDetails', laceyActDetails, { shouldDirty: true })
  }

  function handleEditLaceyActDetail(index, data) {
    const laceyActDetails = [...getValues('laceyActDetails')]

    laceyActDetails[index] = data

    setValue('laceyActDetails', laceyActDetails, { shouldDirty: true })
  }

  return (
    <Grid container spacing={2}>
      {

        laceyActDetails.map((detail, index) => {
          const isLast = laceyActDetails.length === 1

          return (
            <React.Fragment key={detail._id || detail.id || detail}>
              <Grid item xs={12}>
                <LaceyActDetailEntry
                  index={index}
                  isLast={isLast}
                  laceyActDetails={detail}
                  onAdd={handleAddLaceyActDetail}
                  onDelete={() => handleDeleteLaceyActDetail(index)}
                  onEdit={(data) => handleEditLaceyActDetail(index, data)}
                />
              </Grid>
              {index + 1 !== laceyActDetails.length && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          )
        })
      }
    </Grid>
  )
}

function LaceyActDetailEntry(props) {
  const { index, isLast, laceyActDetails, onAdd, onDelete, showNotification } = props

  return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields index={index} />
        </Grid>
        <Grid item xs={12}>
            <Grid justify='flex-end' container spacing={1}>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Add'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'plus']}
                      onClick={() => onAdd(initialValues)}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Clone'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'copy']}
                      onClick={() => onAdd({ ...laceyActDetails, id: undefined })}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
              {!isLast && (
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Delete'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'trash']}
                        onClick={() => onDelete(laceyActDetails)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
        </Grid>
      </Grid>
  )
}

export default LaceyActDetailsSection
