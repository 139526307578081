import { Yup } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'
import FdaAffirmationMakeValidationSchema from './FdaAffirmationMakeValidationSchema'
import FdaQtyUomMakeValidationSchema from './FdaQtyUomMakeValidationSchema'

function makeValidationSchema(translate) {
  const errMsgMaxLength = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  const errMsgAlphaNumeric = translate(
    Namespace.SBCI_INVOICE,
    SbciInvoiceKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object()
    .nullable()
    .shape({
      productCode: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(7, errMsgMaxLength + ' 7'),
      storageStatus: Yup.string().nullable(),
      country: Yup.string().nullable(),
      manufacturerId: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(15, errMsgMaxLength + ' 15'),
      shipperId: Yup.string()
        .nullable()
        .matches(regexAlphaNumeric, errMsgAlphaNumeric)
        .max(15, errMsgMaxLength + ' 15'),
      marker: Yup.string().nullable(),
      fdaAffirmation: Yup.array(
        FdaAffirmationMakeValidationSchema(translate)
      ).nullable(),
      fdaQtyUom: Yup.array(
        FdaQtyUomMakeValidationSchema(translate)
      ).nullable()
    })
}

export default makeValidationSchema
