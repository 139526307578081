import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './AdditionalChargesFormProperties'
import AdditionalChargeFieldsPlaceholder from './AdditionalChargeFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'
import AdditionalChargesFormProperties from './AdditionalChargesFormProperties'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function AdditionalChargesSection({showNotification}) {
  const { getValues, setValue, watch } = useFormContext()

  const additionalCharges = watch("additionalCharges")

  function handleAddAdditionalCharges(data) {
    const additionalCharges = [...getValues('additionalCharges'), data].map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('additionalCharges', additionalCharges)
  }

  function handleDeleteAdditionalCharges(data) {
    const clonedAdditionalCharges = [...getValues('additionalCharges')].filter((material) =>
      material.id ? material.id !== data.id : material._id !== data._id
    )
    const additionalCharges = clonedAdditionalCharges.map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('additionalCharges', additionalCharges)
  }


  return (
    <Grid container spacing={1}>
      {additionalCharges.map((goods, index) => {
        const isLast = additionalCharges.length === 1
        return (
          <React.Fragment key={goods._id || goods.id || index}>
            <Grid item xs={12}>
              <AdditionalChargeEntry
                index={index}
                isLast={isLast}
                onAddAdditionalCharges={handleAddAdditionalCharges}
                onDeleteAdditionalCharges={handleDeleteAdditionalCharges}
                additionalCharge={goods}
                showNotification={showNotification}
              />
            </Grid>
            {additionalCharges.length !== index + 1 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </React.Fragment>
        )
      })}
    </Grid>
  )
}

function AdditionalChargeEntry(props) {
  const { index, isLast, onAddAdditionalCharges, onDeleteAdditionalCharges, additionalCharge, showNotification } = props

  return (
    <Grid container spacing={2}>
    <Grid item xs={12} md>
      <AdditionalChargesFormProperties.Fields index={index} showNotification={showNotification} />
    </Grid>
    <Grid item xs={12} md='auto'>
      <Grid container spacing={1}>
        <Grid item xs='auto'>
          <Tooltip placement='bottom' title='Add new'>
            <span>
              <CngIconButton
                icon={['fal', 'plus']}
                onClick={() => onAddAdditionalCharges(initialValues)}
                size='small'
                type='outlined'
              />
            </span>
          </Tooltip>
        </Grid>
        <Grid item xs='auto'>
          <Tooltip placement='bottom' title='Clone'>
            <span>
              <CngIconButton
                icon={['fal', 'copy']}
                onClick={() => onAddAdditionalCharges({ ...additionalCharge, id: undefined })}
                size='small'
                type='outlined'
              />
            </span>
          </Tooltip>
        </Grid>
        {!isLast && (
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Delete'>
              <span>
                <CngIconButton
                  icon={['fal', 'trash']}
                  onClick={() => onDeleteAdditionalCharges(additionalCharge)}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  </Grid>
  )
}

export default AdditionalChargesSection
