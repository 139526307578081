import makeValidationSchema from './FdaTabMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'
import { Grid } from '@material-ui/core'
import CollapsibleSection from '../../../../components/ui/CollapsibleSection'
import FdaAffirmationSection from './FdaAffirmationSection'
import FdaQtyUomSection from './FdaQtyUomSection'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'
import FdaAffirmationFormProperties from './FdaAffirmationFormProperties'
import FdaQtyUomFormProperties from './FdaQtyUomFormProperties'

const {
  form: {
    field: {
      CngCodeMasterAutocompleteField,
      CngTextField,
      CngCountryAutocompleteField
    }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  productCode: '',
  storageStatus: '',
  country: '',
  manufacturerId: '',
  shipperId: '',
  marker: '',
  fdaAffirmation: [],
  fdaQtyUom: []
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue, watch } = useFormContext()

  const country = watch('country')
  const fdaAffirmation = watch('fdaAffirmation')
  const fdaQtyUom = watch('fdaQtyUom')

  function makeTranslatedTextsObject() {
    let manufacturerId = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaTab.MANUFACTURER_ID
    )
    let storageStatus = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaTab.STORAGE_STATUS
    )
    let marker = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaTab.MARKER
    )
    let country = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaTab.COUNTRY
    )
    let shipperId = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaTab.SHIPPER_ID
    )
    let productCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaTab.PRODUCT_CODE
    )
    let fdaAffirmation = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaAffirmation.TITLE
    )
    let fdaQtyUom = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FdaQtyUom.TITLE
    )

    return {
      manufacturerId,
      storageStatus,
      marker,
      country,
      shipperId,
      productCode,
      fdaAffirmation,
      fdaQtyUom
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.manufacturerId}>
            <CngTextField
              name='manufacturerId'
              label={translatedTextsObject.manufacturerId}
              disabled={disabled}
              onChange={(event) => setValue('manufacturerId', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.storageStatus}>
            <CngCodeMasterAutocompleteField
              codeType='SBCI_STORAGE_STATUS'
              name='storageStatus'
              label={translatedTextsObject.storageStatus}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.marker}>
            <CngCodeMasterAutocompleteField
              codeType='SBCI_MARKER'
              name='marker'
              label={translatedTextsObject.marker}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.country}>
            <CngCountryAutocompleteField
              key={country}
              name='country'
              label={translatedTextsObject.country}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.shipperId}>
            <CngTextField
              name='shipperId'
              label={translatedTextsObject.shipperId}
              disabled={disabled}
              onChange={(event) => setValue('shipperId', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.productCode}>
            <CngTextField
              name='productCode'
              label={translatedTextsObject.productCode}
              disabled={disabled}
              onChange={(event) => setValue('productCode', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CollapsibleSection
          defaultExpanded={!_.isEmpty(fdaAffirmation)}
          onExpandedChange={(expanded) => {
            const { initialValues } = FdaAffirmationFormProperties.formikProps
            setValue('fdaAffirmation', expanded ? [{ ...initialValues, _id: 1 }] : [])
          }}
          title={translatedTextsObject.fdaAffirmation}
        >
          <FdaAffirmationSection fdaAffirmation={fdaAffirmation} />
        </CollapsibleSection>
      </Grid>
      <Grid item xs={12}>
        <CollapsibleSection
          defaultExpanded={!_.isEmpty(fdaQtyUom)}
          onExpandedChange={(expanded) => {
            const { initialValues } = FdaQtyUomFormProperties.formikProps
            setValue('fdaQtyUom', expanded ? [{ ...initialValues, _id: 1 }] : [])
          }}
          title={translatedTextsObject.fdaQtyUom}
        >
          <FdaQtyUomSection fdaQtyUom={fdaQtyUom} />
        </CollapsibleSection>
      </Grid>
    </Grid>
  )
}

const FdaTabFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default FdaTabFormProperties
