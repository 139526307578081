import React, { useContext, useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import { InvoiceLookupsContext } from '../lookups/InvoiceLookupsContext'
import StatusBar from '../../../components/aciacehighway/StatusBar'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import LocalTable from '../../../components/aciacehighway/LocalTable'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngSubSection from '../../../components/cngcomponents/CngSubSection'
import CngField from '../../../components/cngcomponents/CngField'
import AdditionalFormInfoSection from './view/AdditionalFormInfoSection'
import LineItemTable from './view/LineItemTable'
import ManifestResponse from './view/ManifestResponse'
import ActivityLogs from './view/ActivityLogs'
import moment from 'moment'
import _ from 'lodash'
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useTheme
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCheck, faCheckCircle } from '@fortawesome/pro-light-svg-icons'

const {
  button: { CngButton },
  CngDialog
} = components

function InvoiceViewContent(props) {
  const { data, manifestId, showNotification } = props

  const [tradePartyDialog, setTradePartyDialog] = useState({
    open: false,
    party: null
  })
  const { lookups, getLookupValue, getCountryStateLabel } = useContext(
    InvoiceLookupsContext
  )
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()

  function makeTranslatedTextsObject() {
    const invoice = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.TITLE)
    const invoiceNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.INVOICE_NO
    )
    const invoiceDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.INVOICE_DATE
    )
    const invoiceType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.INVOICE_TYPE
    )
    const clientCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CLIENT_CODE
    )
    const carrierCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CARRIER_CODE
    )
    const carOrTrailerNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CAR_OR_TRAILER_NO
    )
    const entryType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.ENTRY_TYPE
    )
    const entryNo = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.ENTRY_NO)
    const freightCharge = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FREIGHT_CHARGE
    )
    const totalValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TOTAL_VALUE
    )
    const grossWeight = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.GROSS_WEIGHT
    )
    const netWeight = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NET_WEIGHT
    )
    const portLadingCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PORT_LADING_CODE
    )
    const portOfDestination = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PORT_OF_DESTINATION
    )
    const description = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.DESCRIPTION
    )
    const ctpInitiative = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CTP_INITIATIVE
    )
    const shipmentQty = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.SHIPMENT_QTY
    )
    const header = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.HEADER)
    const footer = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.FOOTER)
    const identificationInfo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.IdentificationInfo.TITLE
    )
    const identificationType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.IdentificationInfo.IDENTIFICATION_TYPE
    )
    const identificationNumber = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.IdentificationInfo.IDENTIFICATION_NUMBER
    )
    const additionalCharges = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.AdditionalCharges.TITLE
    )
    const chargeCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.AdditionalCharges.CHARGE_CODE
    )
    const amount = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.AdditionalCharges.AMOUNT
    )
    const tradeParty = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.TITLE
    )
    const partyIdn = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.PARTY_IDN
    )
    const partyType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.PARTY_TYPE
    )
    const partyName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.PARTY_NAME
    )
    const address = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.ADDRESS
    )
    const countryCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.COUNTRY_CODE
    )
    const ciForm = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.TITLE
    )
    const partyBilled = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.PARTY_BILLED
    )
    const shipmentDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.SHIPMENT_DATE
    )
    const arrivalDatetime = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.ARRIVAL_DATETIME
    )
    const countryOfExport = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.COUNTRY_OF_EXPORT
    )
    const exportStateCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.EXPORT_STATE_CODE
    )
    const destinationCountry = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.DESTINATION_COUNTRY
    )
    const destinationStateCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.DESTINATION_STATE_CODE
    )
    const transportTermCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.TRANSPORT_TERM_CODE
    )
    const shipperRegNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.SHIPPER_REG_NO
    )
    const transactionParties = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.TRANSACTION_PARTIES
    )
    const ffdPurchasePrice = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.FFD_PURCHASE_PRICE
    )
    const fbiPurchasePrice = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.FBI_PURCHASE_PRICE
    )
    const bfiPurchasePrice = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.BFI_PURCHASE_PRICE
    )
    const dfiPurchasePrice = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.DFI_PURCHASE_PRICE
    )
    const goodsSold = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.GOODS_SOLD
    )
    const markPackLoad = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.MARK_PACK_LOAD
    )
    const termsOfPayment = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.TERMS_OF_PAYMENT
    )
    const reasonForExport = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.REASON_FOR_EXPORT
    )
    const naftaDeclaration = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.NAFTA_DECLARATION
    )
    const tscaDeclaration = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.TSCA_DECLARATION
    )
    const usProforma = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.US_PROFORMA
    )
    const usGoods = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.US_GOODS
    )
    const usShipExp = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.US_SHIP_EXP
    )
    const usGoods10219 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.US_GOODS10219
    )
    const fdaSub = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.FDA_SUB
    )
    const declNewStmt = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.DECL_NEW_STMT
    )
    const titleFOBRelatedInstructions = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.TITLE_FOB_RELATED_INSTRUCTIONS
    )
    const titleYesNoQuestionnaire = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.TITLE_YES_NO_QUESTIONNAIRE
    )
    const titleDeclarations = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CiForm.TITLE_DECLARATIONS
    )
    const cargoSummary = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CargoSummary.TITLE
    )
    const reference = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CargoSummary.REFERENCE
    )
    const referenceNumber = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CargoSummary.REFERENCE_NUMBER
    )

    return {
      invoice,
      invoiceNo,
      invoiceDate,
      invoiceType,
      clientCode,
      carrierCode,
      carOrTrailerNo,
      entryType,
      entryNo,
      freightCharge,
      totalValue,
      grossWeight,
      netWeight,
      portLadingCode,
      portOfDestination,
      description,
      ctpInitiative,
      shipmentQty,
      header,
      footer,
      identificationInfo,
      identificationType,
      identificationNumber,
      additionalCharges,
      chargeCode,
      amount,
      tradeParty,
      partyIdn,
      partyType,
      partyName,
      address,
      countryCode,
      ciForm,
      partyBilled,
      shipmentDate,
      arrivalDatetime,
      markPackLoad,
      termsOfPayment,
      reasonForExport,
      titleFOBRelatedInstructions,
      countryOfExport,
      exportStateCode,
      destinationCountry,
      destinationStateCode,
      transportTermCode,
      shipperRegNo,
      titleYesNoQuestionnaire,
      transactionParties,
      ffdPurchasePrice,
      fbiPurchasePrice,
      bfiPurchasePrice,
      dfiPurchasePrice,
      goodsSold,
      titleDeclarations,
      naftaDeclaration,
      tscaDeclaration,
      usProforma,
      usGoods,
      usShipExp,
      usGoods10219,
      fdaSub,
      declNewStmt,
      cargoSummary,
      reference,
      referenceNumber
    }
  }

  const { ciForm, lineItem } = data

  const isCtpatMember =
    data.ctpInitiative === 'Y' ? true : data.ctpInitiative === 'N' && false

  const tradePartyTableColumns = [
    {
      title: translatedTextsObject.partyIdn,
      field: 'partyIdn',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.partyType,
      field: 'partyType',
      filterConfig: {
        type: 'select',
        options: lookups?.partyType
          ? Object.entries(lookups.partyType).map(([code, label]) => ({
              label: label,
              value: code
            }))
          : []
      },
      render: (data) => getLookupValue('partyType', data.partyType)
    },
    {
      title: translatedTextsObject.partyName,
      field: 'partyName',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.address,
      field: 'address',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.countryCode,
      field: 'countryCode',
      filterConfig: { type: 'textfield' },
      render: (data) => getLookupValue('country', data.countryCode)
    }
  ]

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StatusBar status={data.status} />
        </Grid>
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.invoice}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.invoiceNo}>
                  {data.invoiceNo}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.invoiceDate}>
                  {data.invoiceDate &&
                    moment(data.invoiceDate).format('D MMM YYYY')}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.invoiceType}>
                  {getLookupValue('invoiceType', data.invoiceType)}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.clientCode}>
                  {data.clientCode}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.carrierCode}>
                  {data.carrierCode}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.carOrTrailerNo}>
                  {data.carOrTrailerNo}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.entryType}>
                  {getLookupValue('entryType', data.entryType)}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.entryNo}>
                  {data.entryNo}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.freightCharge}>
                  {parseFloat(data.freightCharge).toFixed(2)}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.totalValue}>
                  {data.totalValue.toFixed(2)}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.grossWeight}>
                  {data.grossWeight.toFixed(2)}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.netWeight}>
                  {data.netWeight.toFixed(2)}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.portLadingCode}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('portLadingCode', data.portLadingCode)}
                    <TinyChip label={data.portLadingCode} variant='outlined' />
                  </Typography>
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.portOfDestination}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('portOfDestination', data.portOfDestination)}
                    <TinyChip label={data.portOfDestination} variant='outlined' />
                  </Typography>
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CngField label={translatedTextsObject.description}>
                  {data.description}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField>
                  <Box display='flex' alignItems='center' style={{ gap: 8 }}>
                    <FontAwesomeIcon
                      color={
                        isCtpatMember
                          ? theme.palette.primary.main
                          : theme.palette.error.main
                      }
                      icon={isCtpatMember ? faCheckCircle : faBan}
                    />
                    <Typography variant='body2'>CTPAT member</Typography>
                  </Box>
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.shipmentQty}>
                  {data.shipmentQty}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.header}>
                  {data.header}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.footer}>
                  {data.footer}
                </CngField>
              </Grid>
              {!_.isEmpty(data.identificationInfo) && (
                <Grid item xs={12}>
                  <CngSubSection
                    title={translatedTextsObject.identificationInfo}
                  >
                    <Grid container spacing={2}>
                      {data.identificationInfo.map((info, index) => (
                        <React.Fragment key={index}>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <CngField
                                  label={
                                    translatedTextsObject.identificationType
                                  }
                                >
                                  {info.identificationType}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <CngField
                                  label={
                                    translatedTextsObject.identificationNumber
                                  }
                                >
                                  {info.identificationNumber}
                                </CngField>
                              </Grid>
                            </Grid>
                          </Grid>
                          {index + 1 < data.identificationInfo.length && (
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          )}
                        </React.Fragment>
                      ))}
                    </Grid>
                  </CngSubSection>
                </Grid>
              )}
              {!_.isEmpty(data.additionalCharges) && (
                <Grid item xs={12}>
                  <CngSubSection
                    title={translatedTextsObject.additionalCharges}
                  >
                    <Grid container spacing={2}>
                      {data.additionalCharges.map((charge, index) => (
                        <React.Fragment key={index}>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <CngField
                                  label={translatedTextsObject.chargeCode}
                                >
                                  {charge.chargeCode}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <CngField label={translatedTextsObject.amount}>
                                  {charge.amount}
                                </CngField>
                              </Grid>
                            </Grid>
                          </Grid>
                          {index + 1 < data.additionalCharges.length && (
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          )}
                        </React.Fragment>
                      ))}
                    </Grid>
                  </CngSubSection>
                </Grid>
              )}
            </Grid>
          </CngSection>
        </Grid>
        {!_.isEmpty(data.tradeParty) && (
          <Grid item xs={12}>
            <Card variant='outlined'>
              <LocalTable
                columns={tradePartyTableColumns}
                data={data.tradeParty}
                header={
                  <Typography
                    component='div'
                    variant='h5'
                    style={{ fontWeight: 600 }}
                  >
                    {translatedTextsObject.tradeParty}&nbsp;
                    <Typography
                      color='textSecondary'
                      component='span'
                      variant='caption'
                    >
                      {`/ ${data.tradeParty.length} Entries`}
                    </Typography>
                  </Typography>
                }
                onRowClick={(data) =>
                  setTradePartyDialog({ open: true, party: data })
                }
              />
              <TradePartyDialog
                getCountryStateLabel={getCountryStateLabel}
                getLookupValue={getLookupValue}
                open={tradePartyDialog.open}
                onClose={() =>
                  setTradePartyDialog({ open: false, party: null })
                }
                party={tradePartyDialog.party}
              />
            </Card>
          </Grid>
        )}
        {ciForm && (
          <>
            <Grid item xs={12}>
              <CiFormSection
                data={ciForm}
                getLookupValue={getLookupValue}
                getCountryStateLabel={getCountryStateLabel}
                translatedTextsObject={translatedTextsObject}
              />
            </Grid>
            {ciForm?.cargoSummary && !_.isEmpty(ciForm.cargoSummary) && (
              <Grid item xs={12}>
                <CngSection title={translatedTextsObject.cargoSummary}>
                  <Grid container spacing={1}>
                    {ciForm.cargoSummary.map((summary, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.reference}>
                                {getLookupValue(
                                  'referenceType',
                                  summary.reference
                                )}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField
                                label={translatedTextsObject.referenceNumber}
                              >
                                {summary.referenceNumber}
                              </CngField>
                            </Grid>
                          </Grid>
                        </Grid>
                        {index + 1 < ciForm.cargoSummary.length && (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                </CngSection>
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12}>
          <AdditionalFormInfoSection
            invoiceData={data}
            showNotification={showNotification}
          />
        </Grid>
        <Grid item xs={12}>
          <LineItemTable data={lineItem} showNotification={showNotification} />
        </Grid>
        {manifestId && (
          <Grid item xs={12}>
            <ManifestResponse manifestId={manifestId} />
          </Grid>
        )}
        <Grid item xs={12}>
          <ActivityLogs invoiceId={data.id} />
        </Grid>
      </Grid>
    </>
  )
}

function TradePartyDialog(props) {
  const { getCountryStateLabel, getLookupValue, onClose, open, party } = props

  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const tradeParty = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.TITLE
    )
    const partyType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.PARTY_TYPE
    )
    const partyName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.PARTY_NAME
    )
    const mid = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.MID
    )
    const upsClientId = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.UPS_CLIENT_ID
    )
    const irs = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.IRS
    )
    const division = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.DIVISION
    )
    const locPortCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.LOC_PORT_CODE
    )
    const poBoxNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.PO_BOX_NO
    )
    const address = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.ADDRESS
    )
    const city = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.CITY
    )
    const countryCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.COUNTRY_CODE
    )
    const stateCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.STATE_CODE
    )
    const postalCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.POSTAL_CODE
    )
    const telphNum = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.TELPH_NUM
    )
    const contactName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.CONTACT_NAME
    )
    const contactFax = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.CONTACT_FAX
    )
    const contactEmail = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.CONTACT_EMAIL
    )
    const taxIdNumber = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.TAX_ID_NUMBER
    )

    return {
      tradeParty,
      partyType,
      partyName,
      mid,
      upsClientId,
      irs,
      division,
      locPortCode,
      poBoxNo,
      address,
      city,
      countryCode,
      stateCode,
      postalCode,
      telphNum,
      contactName,
      contactFax,
      contactEmail,
      taxIdNumber
    }
  }

  return (
    <CngDialog
      customDialogContent={
        <Box p={2}>
          {party ? (
            <CngSubSection title='Party Information'>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.partyType}>
                    {getLookupValue('partyType', party.partyType)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.partyName}>
                    {party.partyName}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.mid}>
                    {party.mid}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.upsClientId}>
                    {party.upsClientId}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.irs}>
                    {party.irs}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.division}>
                    {party.division}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.locPortCode}>
                    {party.locPortCode}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.poBoxNo}>
                    {party.poBoxNo}
                  </CngField>
                </Grid>
                <Grid item xs={12}>
                  <CngField label={translatedTextsObject.address}>
                    {party.address}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.city}>
                    {party.city}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.countryCode}>
                    {getLookupValue('country', party.countryCode)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.stateCode}>
                    {getCountryStateLabel(party.countryCode, party.stateCode)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.postalCode}>
                    {party.postalCode}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.telphNum}>
                    {party.telphNum}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.contactName}>
                    {party.contactName}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.contactFax}>
                    {party.contactFax}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.contactEmail}>
                    {party.contactEmail}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.taxIdNumber}>
                    {party.taxIdNumber}
                  </CngField>
                </Grid>
              </Grid>
            </CngSubSection>
          ) : (
            <Box display='flex' alignItems='center' justifyContent='center'>
              <CircularProgress />
            </Box>
          )}
        </Box>
      }
      dialogAction={<CngButton onClick={onClose}>OK</CngButton>}
      dialogTitle={translatedTextsObject.tradeParty}
      maxWidth='md'
      fullWidth
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

function CiFormSection(props) {
  const { data, getLookupValue, getCountryStateLabel, translatedTextsObject } =
    props
  const theme = useTheme()

  const {
    naftaDeclaration,
    tscaDeclaration,
    usProforma,
    usGoods,
    usShipExp,
    usGoods10219,
    fdaSub,
    declNewStmt
  } = data

  const declarations = [
    {
      name: 'naftaDeclaration',
      value: naftaDeclaration
    },
    {
      name: 'tscaDeclaration',
      value: tscaDeclaration
    },
    {
      name: 'usProforma',
      value: usProforma
    },
    {
      name: 'usGoods',
      value: usGoods
    },
    {
      name: 'usShipExp',
      value: usShipExp
    },
    {
      name: 'usGoods10219',
      value: usGoods10219
    },
    {
      name: 'fdaSub',
      value: fdaSub
    }
  ].filter((declaration) => declaration.value === 'Y')

  return (
    <Card variant='outlined'>
      <Box p={2}>
        <Typography style={{ fontWeight: 600 }}>
          Additional Information
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Box bgcolor='grey.100' px={2} py={1}>
            <Typography color='textSecondary' style={{ fontWeight: 600 }}>
              {translatedTextsObject.ciForm}
            </Typography>
          </Box>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.partyBilled}>
                  {getLookupValue('partyBilled', data.partyBilled)}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.shipmentDate}>
                  {data.shipmentDate &&
                    moment(data.shipmentDate).format('D MMM YYYY')}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.arrivalDatetime}>
                  {data.arrivalDatetime &&
                    moment(data.arrivalDatetime).format('D MMM YYYY')}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.markPackLoad}>
                  {data.markPackLoad}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CngField label={translatedTextsObject.termsOfPayment}>
                  {data.termsOfPayment}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CngField label={translatedTextsObject.reasonForExport}>
                  {data.reasonForExport}
                </CngField>
              </Grid>
            </Grid>
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box bgcolor='grey.100' px={2} py={1}>
            <Typography color='textSecondary' style={{ fontWeight: 600 }}>
              {translatedTextsObject.titleFOBRelatedInstructions}
            </Typography>
          </Box>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.countryOfExport}>
                  {data.countryOfExport && (
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('country', data.countryOfExport)}
                      <TinyChip
                        label={data.countryOfExport}
                        variant='outlined'
                      />
                    </Typography>
                  )}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.exportStateCode}>
                  {data.exportStateCode && (
                    <Typography component='div' variant='inherit'>
                      {getCountryStateLabel(
                        data.countryOfExport,
                        data.exportStateCode
                      )}
                      <TinyChip
                        label={data.exportStateCode}
                        variant='outlined'
                      />
                    </Typography>
                  )}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.destinationCountry}>
                  {data.destinationCountry && (
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('country', data.destinationCountry)}
                      <TinyChip
                        label={data.destinationCountry}
                        variant='outlined'
                      />
                    </Typography>
                  )}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.destinationStateCode}>
                  {data.destinationStateCode && (
                    <Typography component='div' variant='inherit'>
                      {getCountryStateLabel(
                        data.destinationCountry,
                        data.destinationStateCode
                      )}
                      <TinyChip
                        label={data.destinationStateCode}
                        variant='outlined'
                      />
                    </Typography>
                  )}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CngField label={translatedTextsObject.transportTermCode}>
                  {getLookupValue('transportTermCode', data.transportTermCode)}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CngField label={translatedTextsObject.shipperRegNo}>
                  {data.shipperRegNo}
                </CngField>
              </Grid>
            </Grid>
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box bgcolor='grey.100' px={2} py={1}>
            <Typography color='textSecondary' style={{ fontWeight: 600 }}>
              {translatedTextsObject.titleYesNoQuestionnaire}
            </Typography>
          </Box>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <CngField label={translatedTextsObject.transactionParties}>
                  {data.transactionParties === 'Y' ? 'Yes' : 'No'}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CngField label={translatedTextsObject.ffdPurchasePrice}>
                  {data.ffdPurchasePrice === 'Y' ? 'Yes' : 'No'}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CngField label={translatedTextsObject.fbiPurchasePrice}>
                  {data.fbiPurchasePrice === 'Y' ? 'Yes' : 'No'}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CngField label={translatedTextsObject.bfiPurchasePrice}>
                  {data.bfiPurchasePrice === 'Y' ? 'Yes' : 'No'}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CngField label={translatedTextsObject.dfiPurchasePrice}>
                  {data.dfiPurchasePrice === 'Y' ? 'Yes' : 'No'}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CngField label={translatedTextsObject.goodsSold}>
                  {data.goodsSold === 'Y' ? 'Yes' : 'No'}
                </CngField>
              </Grid>
            </Grid>
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box bgcolor='grey.100' px={2} py={1}>
            <Typography color='textSecondary' style={{ fontWeight: 600 }}>
              {translatedTextsObject.titleDeclarations}
            </Typography>
          </Box>
          <Box p={2}>
            <Grid container spacing={4}>
              {declarations.map((declaration) => (
                <Grid key={declaration.name} item xs='auto'>
                  <Box display='flex' alignItems='center' style={{ gap: 16 }}>
                    <FontAwesomeIcon
                      color={theme.palette.primary.main}
                      icon={faCheck}
                    />
                    <Typography variant='body2'>
                      {translatedTextsObject[declaration.name]}
                    </Typography>
                  </Box>
                </Grid>
              ))}
              <Grid item xs={12}>
                <CngField label={translatedTextsObject.declNewStmt}>
                  {declNewStmt}
                </CngField>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}

export default InvoiceViewContent
