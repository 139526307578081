import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './OgaCodeFormProperties'
import LineItemOgaCodeFieldsPlaceholder from './OgaCodeFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'
import LineItemFormProperties from './FormProperties'
import LineitemOgaCodeFormProperties from './OgaCodeFormProperties'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function LineItemOgaCodeSection({showNotification}) {

  const { getValues, setValue, watch } = useFormContext()

  const lineitemOgaCode = watch('lineitemOgaCode')

  function handleAddLineItemOgaCode(data) {
    const lineitemOgaCode = [...getValues('lineitemOgaCode'), data].map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('lineitemOgaCode', lineitemOgaCode)
  }

  function handleDeleteLineItemOgaCode(data) {
    const clonedLineitemOgaCode = [...getValues('lineitemOgaCode')].filter((material) =>
      material.id ? material.id !== data.id : material._id !== data._id
    )
    const lineitemOgaCode = clonedLineitemOgaCode.map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('lineitemOgaCode', lineitemOgaCode)
  }

  return (
    <Grid container spacing={1}>
      {lineitemOgaCode.map((goods, index) => {
        const isLast = lineitemOgaCode.length === 1
        return (
          <React.Fragment key={goods._id || goods.id || index}>
            <Grid item xs={12}>
              <LineItemOgaCodeEntry
                index={index}
                isLast={isLast}
                onAddLineItemOgaCode={handleAddLineItemOgaCode}
                onDeleteLineItemOgaCode={handleDeleteLineItemOgaCode}
                lineitemOgaCode={goods}
                showNotification={showNotification}
              />
            </Grid>
            {lineitemOgaCode.length !== index + 1 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </React.Fragment>
        )
      })}
    </Grid>
  )
}

function LineItemOgaCodeEntry(props) {
  const { index, isLast, onAddLineItemOgaCode, onDeleteLineItemOgaCode, lineitemOgaCode, showNotification } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md>
        <LineitemOgaCodeFormProperties.Fields index={index} showNotification={showNotification} />
      </Grid>
      <Grid item xs={12} md='auto'>
        <Grid container spacing={1}>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Add new'>
              <span>
                <CngIconButton
                  icon={['fal', 'plus']}
                  onClick={() => onAddLineItemOgaCode(initialValues)}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Clone'>
              <span>
                <CngIconButton
                  icon={['fal', 'copy']}
                  onClick={() => onAddLineItemOgaCode({ ...lineitemOgaCode, id: undefined })}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          {!isLast && (
            <Grid item xs='auto'>
              <Tooltip placement='bottom' title='Delete'>
                <span>
                  <CngIconButton
                    icon={['fal', 'trash']}
                    onClick={() => onDeleteLineItemOgaCode(lineitemOgaCode)}
                    size='small'
                    type='outlined'
                  />
                </span>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LineItemOgaCodeSection
