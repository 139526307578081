import makeValidationSchema from './GoodsReturnedMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'
import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  htsCode: "",
  dutiableUnitValue: "",
  nonDutiableUnitValue: "",
  packingUnitValue: "",
  goodsreturnedUnitValue: "",
  goodsreturnedLineValue: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue, trigger } = useFormContext()

  function makeTranslatedTextsObject() {
    let htsCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Goodsreturned.HTS_CODE
    )
    let dutiableUnitValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Goodsreturned.DUTIABLE_UNIT_VALUE
    )
    let nonDutiableUnitValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Goodsreturned.NON_DUTIABLE_UNIT_VALUE
    )
    let packingUnitValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Goodsreturned.PACKING_UNIT_VALUE
    )
    let goodsreturnedUnitValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Goodsreturned.GOODSRETURNED_UNIT_VALUE
    )
    let goodsreturnedLineValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.Goodsreturned.GOODSRETURNED_LINE_VALUE
    )

    return {
      htsCode,
      dutiableUnitValue,
      nonDutiableUnitValue,
      packingUnitValue,
      goodsreturnedUnitValue,
      goodsreturnedLineValue
    }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.htsCode}>
        <CngTextField
          name='htsCode'
          label={translatedTextsObject.htsCode}
          disabled={disabled}
          onChange={(event) => setValue('htsCode', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.packingUnitValue}>
        <CngTextField
          name='packingUnitValue'
          label={translatedTextsObject.packingUnitValue}
          disabled={disabled}
          size='small'
          onChange={(event) => trigger('packingUnitValue')}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.dutiableUnitValue}>
        <CngTextField
          name='dutiableUnitValue'
          label={translatedTextsObject.dutiableUnitValue}
          disabled={disabled}
          size='small'
          onChange={(event) => trigger('dutiableUnitValue')}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.nonDutiableUnitValue}>
        <CngTextField
          name='nonDutiableUnitValue'
          label={translatedTextsObject.nonDutiableUnitValue}
          disabled={disabled}
          size='small'
          onChange={(event) => trigger('nonDutiableUnitValue')}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.goodsreturnedUnitValue}>
        <CngTextField
          name='goodsreturnedUnitValue'
          label={translatedTextsObject.goodsreturnedUnitValue}
          disabled={disabled}
          size='small'
          onChange={(event) => trigger('goodsreturnedUnitValue')}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.goodsreturnedLineValue}>
        <CngTextField
          name='goodsreturnedLineValue'
          label={translatedTextsObject.goodsreturnedLineValue}
          disabled={disabled}
          size='small'
          onChange={(event) => trigger('goodsreturnedLineValue')}
        />
      </CngGridItem>
    </Grid>
	)
}

const GoodsreturnedFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default GoodsreturnedFormProperties
