import React from 'react'
import { Box, Chip, CircularProgress, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function QuickExportButton(props) {
  const { disabled, loading, label, onClick } = props

  return (
    <Chip
      disabled={disabled}
      label={
        <Box alignItems='center' display='flex' style={{ gap: 8 }}>
          <Typography component='div' variant='body2'>
            {label}
          </Typography>
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <FontAwesomeIcon
              fixedWidth
              icon={['fal', 'arrow-to-bottom']}
              size='lg'
            />
          )}
        </Box>
      }
      onClick={onClick}
      variant='outlined'
    />
  )
}

export default QuickExportButton
