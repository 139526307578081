import React from 'react'
import { constants, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import SbciManifestKeys from '../../../../constants/locale/key/SbciManifest'
import SbciManifestResponseKeys from '../../../../constants/locale/key/SbciManifestResponse'
import SbciManifestResponseApiUrls from '../../../../apiUrls/SbciManifestResponseApiUrls'
import Table from '../../../../components/aciacehighway/Table'
import CngSection from '../../../../components/cngcomponents/CngSection'

const {
  filter: { EQUAL }
} = constants

function ManifestResponse(props) {
  const { manifestId, showNotification } = props

  const { translate } = useTranslation(Namespace.SBCI_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const manifestResponse = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.Response.TITLE
    )
    const entryNo = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestResponseKeys.ENTRY_NO
    )
    const clientCode = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestResponseKeys.CLIENT_CODE
    )
    const receiveDatetime = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestResponseKeys.RECEIVE_DATETIME
    )
    const submissionFilePath = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestResponseKeys.SUBMISSION_FILE_PATH
    )
    const responseFilePath = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestResponseKeys.RESPONSE_FILE_PATH
    )

    return {
      manifestResponse,
      entryNo,
      clientCode,
      receiveDatetime,
      submissionFilePath,
      responseFilePath
    }
  }

  const columns = [
    {
      field: 'entryNo',
      title: translatedTextsObject.entryNo
    },
    {
      field: 'clientCode',
      title: translatedTextsObject.clientCode
    },
    {
      field: 'receiveDatetime',
      title: translatedTextsObject.receiveDatetime
    },
    {
      field: 'submissionFilePath',
      title: translatedTextsObject.submissionFilePath
    },
    {
      field: 'responseFilePath',
      title: translatedTextsObject.responseFilePath
    }
  ]

  return (
    <CngSection title={translatedTextsObject.manifestResponse}>
      <Table
        columns={columns}
        compact
        exportData={{ url: SbciManifestResponseApiUrls.EXPORT }}
        fetch={{ url: SbciManifestResponseApiUrls.GET }}
        fetchFilters={[{ field: 'manifestId', operator: EQUAL, value: manifestId }]}
        showNotification={showNotification}
        variant='outlined'
      />
    </CngSection>
  )
}

export default ManifestResponse
