import makeValidationSchema from './OgaCodeMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'
import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngCodeMasterAutocompleteField, CngTextField },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  ogaCode: "",
  ogaDocNo: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  index,
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue } = useFormContext()

  function makeTranslatedTextsObject() {
    let ogaCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineitemOgaCode.OGA_CODE
    )
    let ogaDocNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineitemOgaCode.OGA_DOC_NO
    )

    return { ogaCode, ogaDocNo }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.ogaCode}>
        <CngCodeMasterAutocompleteField
          codeType='SBCI_OGA_CODE'
          name={typeof index === 'number' ? `lineitemOgaCode.${index}.ogaCode` : 'ogaCode'}
          label={translatedTextsObject.ogaCode}
          disabled={disabled}
          onChange={(_, option) => setValue(typeof index === 'number' ? `lineitemOgaCode.${index}.ogaCode` : 'ogaCode', option ? option.value : '', {shouldValidate:true})}
          required
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.ogaDocNo}>
        <CngTextField
          name={typeof index === 'number' ? `lineitemOgaCode.${index}.ogaDocNo` : 'ogaDocNo'}
          label={translatedTextsObject.ogaDocNo}
          disabled={disabled}
          onChange={(event) => setValue(typeof index === 'number' ? `lineitemOgaCode.${index}.ogaDocNo` : 'ogaDocNo', event.target.value.toUpperCase(), {shouldValidate:true})}
          required
          size='small'
        />
      </CngGridItem>
    </Grid>
	)
}

const LineitemOgaCodeFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default LineitemOgaCodeFormProperties
