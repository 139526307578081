import React from 'react'
import { components, constants } from 'cng-web-lib'
import Grid from '@material-ui/core/Grid'
import { useFormContext } from 'react-hook-form'

const {
  button: { CngButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

const DEFAULT_PROPS = {
  submitButtonLabel: 'Save',
  updateButtonLabel: 'Update'
}

function DialogForm(props) {
  const {
    formProperties,
    onClose,
    onSubmit,
    open,
    showNotification,
    submitButtonLabel = DEFAULT_PROPS.submitButtonLabel,
    title,
    value,
    updateButtonLabel = DEFAULT_PROPS.updateButtonLabel,
    ...otherProps
  } = props

  const { initialValues, makeValidationSchema } = formProperties.formikProps

  async function handleSubmit(data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        onSubmit(data)
        resolve()
      }, 500)
    })
  }

  return (
    <CngDialog
      dialogContent={
        <CngForm
          fieldLevel='form'
          formikProps={{
            initialValues: value || initialValues,
            makeValidationSchema,
            onSubmit: handleSubmit
          }}
          formState={FormState.COMPLETED}
          innerForm
          renderBodySection={(labelMap, shouldHideMap) => (
            <formProperties.Fields
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          renderButtonSection={() => (
            <ButtonSection
              initialValues={initialValues}
              label={value ? updateButtonLabel : submitButtonLabel}
              onClose={onClose}
              onSubmit={handleSubmit}
            />
          )}
        />
      }
      dialogTitle={title}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
      {...otherProps}
    />
  )
}

function ButtonSection(props) {
  const { initialValues, label, onClose, onSubmit } = props

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting }
  } = useFormContext()

  return (
    <Grid container justify='space-between' spacing={2}>
      <Grid item xs='auto'>
        <CngButton color='secondary' disabled={isSubmitting} onClick={() => reset(initialValues)}>
          Clear all
        </CngButton>
      </Grid>
      <Grid item xs='auto'>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <CngButton disabled={isSubmitting} onClick={onClose}>
              Discard
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
              {label}
            </CngButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DialogForm
