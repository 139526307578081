import makeValidationSchema from './AdditionalChargesMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import { useFormContext } from 'react-hook-form'
import SbciInvoiceKeys from 'src/constants/locale/key/SbciInvoice'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  chargeCode: "",
  amount: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ index, disabled, shouldHideMap }) {
  const { getValues } = useFormContext();
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let chargeCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.AdditionalCharges.CHARGE_CODE
    )
    let amount = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.AdditionalCharges.AMOUNT
    )

    return { chargeCode, amount }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.chargeCode}>
        <CngCodeMasterAutocompleteField
          name={typeof index === 'number' ? `additionalCharges.${index}.chargeCode` : 'chargeCode'}
          label={translatedTextsObject.chargeCode}
          disabled={disabled}
          codeType='SBCI_CHARGE_CODE'
          required
          size='small'
          key={getValues(`additionalCharges.${index}.chargeCode`)}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.amount}>
        <CngTextField
          name={typeof index === 'number' ? `additionalCharges.${index}.amount` : 'amount'}
          label={translatedTextsObject.amount}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
	)
}

const AdditionalChargesFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default AdditionalChargesFormProperties
