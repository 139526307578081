import React, { useEffect, useRef, useState } from 'react'
import { components } from 'cng-web-lib'
import SbciInvoiceApiUrls from '../../../apiUrls/SbciInvoiceApiUrls'
import pathMap from '../../../paths/pathMap'
import Stepper from '../../../components/aciacehighway/Stepper'
import FormProperties from './FormProperties'
import { Box, Card, Grid, useTheme } from '@material-ui/core'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

const { initialValues } = FormProperties.formikProps

function AddPage(props) {
  const { history, onSetLoading, showNotification } = props

  const { state } = history.location

  const onSuccessCallback = useRef((e) => {
    /*if (state?.manifestId) {
      history.push(`${pathMap.SBCI_MANIFEST}/edit/` + state.manifestId)
    } else {
      history.push(`${pathMap.SBCI_INVOICE}/edit/` + e.id)
    }*/
    history.push({
      pathname: `${pathMap.SBCI_INVOICE}/edit/` + e.id,
      state: {manifestId: state?.manifestId}
    })
  })
  const theme = useTheme()

  function handleSaveAndContinue() {
    onSuccessCallback.current = (e) => {
      history.push({
        pathname: `${pathMap.SBCI_INVOICE}/edit/` + e.id,
        state: { step: 1, invoiceData: e,  manifestId: state?.manifestId}
      })
    }
  }

  if (state?.manifestId) {
    initialValues.manifestId = state.manifestId;
  }

  function handleDiscard(){
    if (state?.manifestId) {
      history.push(`${pathMap.SBCI_MANIFEST}/edit/` + state.manifestId)
    }else{
      history.push(pathMap.SBCI_INVOICE_LIST_VIEW)
    }
  }
  return (
    <>
      <Card>
        <Stepper
          steps={['Provide details', 'Add invoice lines']}
          activeStep={0}
        />
        <Box p={2}>
          <CngAddForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            history={history}
            showNotification={showNotification}
            renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
              <FormProperties.Fields
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
                onSetLoading={onSetLoading}
              />
            )}
            formikProps={{
              ...FormProperties.formikProps,
              initialValues: state?.cloneData ? FormProperties.toClientDataFormat(state.cloneData) : initialValues 
            }}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            create={{
              url: SbciInvoiceApiUrls.POST,
              onSuccess: (e) => {
                const { errorMessages } = e

                if (!_.isEmpty(errorMessages)) {
                  errorMessages.forEach((message) =>
                    showNotification('error', message)
                  )
                } else {
                  showNotification('success', 'Invoice created successfully.')
                  onSuccessCallback.current(e)
                }
              }
            }}
            renderButtonSection={() => (
              <InvoiceAddPageButtonSection
                history={history}
                onSetLoading={onSetLoading}
                onSaveAndContinue={handleSaveAndContinue}
                onDiscard={handleDiscard}
                state={state}
              />
            )}
          />
        </Box>
      </Card>
    </>
  )
}

function InvoiceAddPageButtonSection(props) {
  const { history, onSetLoading, onSaveAndContinue, onDiscard, state } = props

  const [confirmDialog, setConfirmDialog] = useState(false)
  const {
    formState: { isSubmitting }
  } = useFormContext()

  useEffect(() => {
    onSetLoading(isSubmitting)
  }, [isSubmitting])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={isSubmitting}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            {state?.manifestId ? "Back to Manifest" : "Back to Invoice"}
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting}
                type='submit'
                size='medium'
              >
                Save as draft
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting}
                type='submit'
                onClick={onSaveAndContinue}
                size='medium'
              >
                Save &amp; continue
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={onDiscard}
        title='Discard'
      >
        All progress in this session will be lost and cant't be restored. Are
        you sure about this?
      </AlertDialog>
    </>
  )
}

export default withFormState(AddPage)
