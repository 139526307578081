import makeValidationSchema from './LaceyActMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'
import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

const {
  form: {
    field: { CngCountryAutocompleteField, CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  pgaCodes: '',
  pga1: '',
  pga2: '',
  pga3: '',
  pga4: '',
  pga5: '',
  pga6: '',
  intendedUseCode: '',
  intendedUseDesc: '',
  ingredientName: '',
  qtyOfMaterial: '',
  qtyOfMaterialUom: '',
  percentIngredient: '',
  plantScientificName: '',
  scientificSpeciesName: '',
  sourceCode: '',
  countryCode: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue, watch } = useFormContext()

  const countryCode = watch('countryCode')

  function makeTranslatedTextsObject() {
    let pga1 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.PGA_1
    )
    let pga2 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.PGA_2
    )
    let pga3 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.PGA_3
    )
    let pga4 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.PGA_4
    )
    let pga5 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.PGA_5
    )
    let pga6 = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.PGA_6
    )
    let percentIngredient = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.PERCENT_INGREDIENT
    )
    let ingredientName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.INGREDIENT_NAME
    )
    let plantScientificName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.PLANT_SCIENTIFIC_NAME
    )
    let scientificSpeciesName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.SCIENTIFIC_SPECIES_NAME
    )
    let intendedUseDesc = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.INTENDED_USE_DESC
    )
    let intendedUseCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.INTENDED_USE_CODE
    )
    let sourceCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.SOURCE_CODE
    )
    let countryCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.COUNTRY_CODE
    )
    let qtyOfMaterial = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.QTY_OF_MATERIAL
    )
    let qtyOfMaterialUom = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyAct.QTY_OF_MATERIAL_UOM
    )

    return {
      pga1,
      pga2,
      pga3,
      pga4,
      pga5,
      pga6,
      percentIngredient,
      ingredientName,
      plantScientificName,
      scientificSpeciesName,
      intendedUseDesc,
      intendedUseCode,
      sourceCode,
      countryCode,
      qtyOfMaterial,
      qtyOfMaterialUom,
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <CngGridItem xs={4} md={2} shouldHideMap={shouldHideMap?.pga1}>
            <CngTextField
              name='pga1'
              label={translatedTextsObject.pga1}
              disabled={disabled}
              onChange={(e) => setValue('pga1', e.target.value.toUpperCase(), {shouldValidate:true})}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={4} md={2} shouldHideMap={shouldHideMap?.pga2}>
            <CngTextField
              name='pga2'
              label={translatedTextsObject.pga2}
              disabled={disabled}
              onChange={(e) => setValue('pga2', e.target.value.toUpperCase(), {shouldValidate:true})}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={4} md={2} shouldHideMap={shouldHideMap?.pga3}>
            <CngTextField
              name='pga3'
              label={translatedTextsObject.pga3}
              disabled={disabled}
              onChange={(e) => setValue('pga3', e.target.value.toUpperCase(), {shouldValidate:true})}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={4} md={2} shouldHideMap={shouldHideMap?.pga4}>
            <CngTextField
              name='pga4'
              label={translatedTextsObject.pga4}
              disabled={disabled}
              onChange={(e) => setValue('pga4', e.target.value.toUpperCase(), {shouldValidate:true})}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={4} md={2} shouldHideMap={shouldHideMap?.pga5}>
            <CngTextField
              name='pga5'
              label={translatedTextsObject.pga5}
              disabled={disabled}
              onChange={(e) => setValue('pga5', e.target.value.toUpperCase(), {shouldValidate:true})}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={4} md={2} shouldHideMap={shouldHideMap?.pga6}>
            <CngTextField
              name='pga6'
              label={translatedTextsObject.pga6}
              disabled={disabled}
              onChange={(e) => setValue('pga6', e.target.value.toUpperCase(), {shouldValidate:true})}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.percentIngredient}>
            <CngTextField
              name='percentIngredient'
              label={translatedTextsObject.percentIngredient}
              disabled={disabled}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.ingredientName}>
            <CngTextField
              name='ingredientName'
              label={translatedTextsObject.ingredientName}
              disabled={disabled}
              onChange={(e) => setValue('ingredientName', e.target.value.toUpperCase(), {shouldValidate:true})}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.plantScientificName}>
            <CngTextField
              name='plantScientificName'
              label={translatedTextsObject.plantScientificName}
              disabled={disabled}
              onChange={(e) => setValue('plantScientificName', e.target.value.toUpperCase(), {shouldValidate:true})}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.scientificSpeciesName}>
            <CngTextField
              name='scientificSpeciesName'
              label={translatedTextsObject.scientificSpeciesName}
              disabled={disabled}
              onChange={(e) => setValue('scientificSpeciesName', e.target.value.toUpperCase(), {shouldValidate:true})}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.intendedUseDesc}>
            <CngTextField
              name='intendedUseDesc'
              label={translatedTextsObject.intendedUseDesc}
              disabled={disabled}
              onChange={(e) => setValue('intendedUseDesc', e.target.value.toUpperCase(), {shouldValidate:true})}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.intendedUseCode}>
            <CngTextField
              name='intendedUseCode'
              label={translatedTextsObject.intendedUseCode}
              disabled={disabled}
              onChange={(e) => setValue('intendedUseCode', e.target.value.toUpperCase(), {shouldValidate:true})}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.sourceCode}>
            <CngTextField
              name='sourceCode'
              label={translatedTextsObject.sourceCode}
              disabled={disabled}
              onChange={(e) => setValue('sourceCode', e.target.value.toUpperCase(), {shouldValidate:true})}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.countryCode}>
            <CngCountryAutocompleteField
              key={countryCode}
              name='countryCode'
              label={translatedTextsObject.countryCode}
              disabled={disabled}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.qtyOfMaterial}>
            <CngTextField
              name='qtyOfMaterial'
              label={translatedTextsObject.qtyOfMaterial}
              disabled={disabled}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.qtyOfMaterialUom}>
            <CngTextField
              name='qtyOfMaterialUom'
              label={translatedTextsObject.qtyOfMaterialUom}
              disabled={disabled}
              required
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
    </Grid>
  )
}

const LaceyActFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default LaceyActFormProperties
