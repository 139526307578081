import makeValidationSchema from './NaftaFormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SbciInvoiceKeys from 'src/constants/locale/key/SbciInvoice'
import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  dateFrom: "",
  dateTo: "",
  company: "",
  naftaTitle: "",
  naftaDate: "",
  partyName: "",
  teleNo: "",
  contactFax: "",
  email: "",
  certifierType: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue, trigger } = useFormContext()

  function makeTranslatedTextsObject() {
    let naftaForm = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NaftaForm.TITLE
    )
    let naftaFormId = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NaftaForm.NAFTA_FORM_ID
    )
    let naftaFormInvoiceId = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NaftaForm.NAFTA_FORM_INVOICE_ID
    )
    let dateFrom = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NaftaForm.DATE_FROM
    )
    let dateTo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NaftaForm.DATE_TO
    )
    let company = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NaftaForm.COMPANY
    )
    let naftaTitle = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NaftaForm.NAFTA_TITLE
    )
    let naftaDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NaftaForm.NAFTA_DATE
    )
    let partyName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NaftaForm.PARTY_NAME
    )
    let teleNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NaftaForm.TELE_NO
    )
    let contactFax = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NaftaForm.CONTACT_FAX
    )
    let email = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NaftaForm.EMAIL
    )
    let certifierType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NaftaForm.CERTIFIER_TYPE
    )

    let titleCertifierDetails = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NaftaForm.TITLE_CERTIFIER_DETAILS
    )

    return {
      naftaForm,
      naftaFormId,
      naftaFormInvoiceId,
      dateFrom,
      dateTo,
      company,
      naftaTitle,
      naftaDate,
      partyName,
      teleNo,
      contactFax,
      email,
      certifierType,
      titleCertifierDetails
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.dateFrom}>
        <CngDateField
          name='dateFrom'
          label={translatedTextsObject.dateFrom}
          disabled={disabled}
          size='small'
          onBlur={(event) => trigger('dateFrom')}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.dateTo}>
        <CngDateField
          name='dateTo'
          label={translatedTextsObject.dateTo}
          disabled={disabled}
          size='small'
          onBlur={(event) => trigger('dateTo')}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.certifierType}>
        <CngCodeMasterAutocompleteField
          name='certifierType'
          label={translatedTextsObject.certifierType}
          disabled={disabled}
          codeType='SBCI_CERTIFIER_TYPE'
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.partyName}>
        <CngTextField
          name='partyName'
          label={translatedTextsObject.partyName}
          disabled={disabled}
          onChange={(event) => setValue('partyName', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.company}>
        <CngTextField
          name='company'
          label={translatedTextsObject.company}
          disabled={disabled}
          onChange={(event) => setValue('company', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.naftaTitle}>
        <CngTextField
          name='naftaTitle'
          label={translatedTextsObject.naftaTitle}
          disabled={disabled}
          onChange={(event) => setValue('naftaTitle', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.teleNo}>
        <CngTextField
          name='teleNo'
          label={translatedTextsObject.teleNo}
          disabled={disabled}
          onChange={(event) => setValue('teleNo', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.contactFax}>
        <CngTextField
          name='contactFax'
          label={translatedTextsObject.contactFax}
          disabled={disabled}
          onChange={(event) => setValue('contactFax', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.email}>
        <CngTextField
          name='email'
          label={translatedTextsObject.email}
          disabled={disabled}
          onChange={(event) => setValue('email', event.target.value.toUpperCase(), {shouldValidate:true})}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.naftaDate}>
        <CngDateField
          name='naftaDate'
          label={translatedTextsObject.naftaDate}
          disabled={disabled}
          size='small'
          onBlur={(event) => trigger('naftaDate')}
        />
      </CngGridItem>
    </Grid>
  )
}

const NaftaFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default NaftaFormFormProperties
