import React, { useEffect, useState } from 'react'
import { components, constants } from 'cng-web-lib'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL },
  CodeMaintenanceType
} = constants

const PartyMasterLookupsContext = React.createContext()

const PartyMasterLookupsConsumer = PartyMasterLookupsContext.Consumer

const PartyMasterLookupsProvider = ({ children }) => {
  const [lookups, setLookups] = useState(null)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'PARTY_TYPE_MASTER' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.COUNTRY),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'PARTY_IDENTIFIER_CODE' }
      ])
    ]).then(([partyType, countrycode, partyIdentifierCode]) => {
      setLookups({ partyType, countrycode, partyIdentifierCode })
    })
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  return (
    <PartyMasterLookupsContext.Provider value={{ lookups, getLookupValue }}>
      {children}
    </PartyMasterLookupsContext.Provider>
  )
}

export default PartyMasterLookupsProvider

export { PartyMasterLookupsContext, PartyMasterLookupsConsumer }
